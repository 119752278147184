import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import * as RB from "react-bootstrap";
import * as utils from "../../Utils/comman";

function PasswordChange() {
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [newPssValidate, setNewPassValidate] = useState(false);
  const [showSubmit, setshowSubmit] = useState(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const toggleOldPasswordVisibility = () =>
    setShowOldPassword(!showOldPassword);
  const toggleNewPasswordVisibility = () =>
    setShowNewPassword(!showNewPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setIsValidated(true);
      return;
    } else {
      const passwordPattern =
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/;

      if (!passwordPattern.test(formData.newPassword)) {
        setNewPassValidate(true);
        setPasswordMatch(false);
        return;
      }
      if (formData.newPassword !== formData.confirmPassword) {
        setNewPassValidate(false);
        setPasswordMatch(true);
      } else {
        setPasswordMatch(false);
        setIsSubmitted(true);
      }
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      var bodyFormData = new FormData();
      bodyFormData.append("oldPassword", formData.oldPassword);
      bodyFormData.append("newPassword", formData.newPassword);
      bodyFormData.append("confirmPassword", formData.confirmPassword);

      utils
        .callAPI("post", `/authentication/change-password`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmitted(false);

            setFormData({
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
          } else {
            setIsSubmitted(false);
          }
        })
        .catch((err) => {
          setIsSubmitted(false);
        });
    }
  }, [isSubmitted]);

  const handleDisable = () => {
    if (
      formData.oldPassword &&
      formData.newPassword &&
      formData.confirmPassword
    ) {
      if (formData.newPassword === formData.confirmPassword) {
        setPasswordMatch(false);
        setshowSubmit(false);
        return;
      } else {
        setPasswordMatch(true);
        setshowSubmit(true);
        return;
      }
    }
  };

  useEffect(() => {
    handleDisable();
  }, [
    showSubmit,
    formData.confirmPassword,
    formData.newPassword,
    formData.oldPassword,
  ]);

  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <RB.Card className="">
              <RB.Card.Header>
                <h4>Change Password</h4>
              </RB.Card.Header>
              <RB.Card.Body>
                <RB.Form
                  onSubmit={handleSubmit}
                  noValidate
                  validated={isValidated}
                >
                  <RB.Row>
                    <RB.Col md={6}>
                      <div className="mb-3 position-relative">
                        <RB.FloatingLabel
                          label="Old Password"
                          className="floating-label-fancy"
                        >
                          <RB.Form.Control
                            type={showOldPassword ? "text" : "password"}
                            placeholder="Old Password"
                            name="oldPassword"
                            value={formData.oldPassword}
                            onChange={handleInputChange}
                            required
                          />
                        </RB.FloatingLabel>
                        <i
                          className="material-icons-outlined position-absolute end-0 top-50 translate-middle-y icon-password-eye"
                          style={{ cursor: "pointer" }}
                          onClick={toggleOldPasswordVisibility}
                        >
                          {showOldPassword ? "visibility_off" : "visibility"}
                        </i>
                      </div>
                    </RB.Col>
                  </RB.Row>

                  <RB.Row>
                    <RB.Col md={6}>
                      <div className="mb-3">
                        <RB.FloatingLabel
                          label="New Password"
                          className="floating-label-fancy"
                        >
                          <RB.Form.Control
                            type={showNewPassword ? "text" : "password"}
                            placeholder="New Password"
                            name="newPassword"
                            value={formData.newPassword}
                            onChange={handleInputChange}
                            required
                          />
                          <i
                            className="material-icons-outlined position-absolute end-0 top-50 translate-middle-y icon-password-eye"
                            style={{ cursor: "pointer" }}
                            onClick={toggleNewPasswordVisibility}
                          >
                            {showNewPassword ? "visibility_off" : "visibility"}
                          </i>
                        </RB.FloatingLabel>

                        <small
                          className={`${
                            newPssValidate ? "text-danger" : "text-muted"
                          }`}
                        >
                          Note: The Password must be at least eight characters
                          long and include at least one uppercase letter, one
                          lowercase letter, one digit, and one special
                          character.
                        </small>
                      </div>
                    </RB.Col>
                    <RB.Col md={6}>
                      <div className="mb-3">
                        <RB.FloatingLabel
                          label="Confirm New Password"
                          className="floating-label-fancy"
                        >
                          <RB.Form.Control
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Confirm New Password"
                            name="confirmPassword"
                            id="confirmPassword"
                            value={formData.confirmPassword}
                            isInvalid={passwordMatch}
                            onChange={handleInputChange}
                            required
                          />
                          <i
                            className="material-icons-outlined position-absolute end-0 top-50 translate-middle-y icon-password-eye"
                            style={{ cursor: "pointer" }}
                            onClick={toggleConfirmPasswordVisibility}
                          >
                            {showConfirmPassword
                              ? "visibility_off"
                              : "visibility"}
                          </i>
                          {passwordMatch ? (
                            <>
                              <RB.Form.Control.Feedback type="invalid">
                                New Password and Confirm Password should be same
                              </RB.Form.Control.Feedback>
                              {/* <span className="text-danger">
                                {" "}
                                New Password and Confirm Password should be same
                              </span> */}
                            </>
                          ) : (
                            ""
                          )}
                        </RB.FloatingLabel>
                      </div>
                    </RB.Col>
                  </RB.Row>

                  <RB.Button
                    variant="primary"
                    type="submit"
                    disabled={showSubmit}
                  >
                    Submit
                  </RB.Button>
                </RB.Form>
              </RB.Card.Body>
            </RB.Card>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default PasswordChange;
