import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import moment from "moment";
import { useSelector } from "react-redux";

function List(props) {
  const [leaveList, setleaveList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [hideAction, setHideAction] = useState(false);

  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };
  const columns = [
    {
      name: (
        <div>
          Start Date <br />
        </div>
      ),
      selector: (row) => moment(row.start_date).format("DD-MM-YYYY"),
    },
    {
      name: (
        <div>
          End Date <br />
        </div>
      ),
      selector: (row) => moment(row.end_date).format("DD-MM-YYYY"),
    },
    {
      name: (
        <div>
          Leave Day <br />
        </div>
      ),
      selector: (row) => {
        const leaveDayNames = {
          full: "Full Day",
          first_half: "First Half",
          second_half: "Second Half",
        };
        return leaveDayNames[row.leave_day];
      },
    },
    {
      name: (
        <div>
          Leave Type <br />
        </div>
      ),
      selector: (row) => row.leave_name,
    },
    {
      name: (
        <div>
          Reason <br />
        </div>
      ),
      selector: (row) => row.reason,
    },
    {
      name: (
        <div>
          Cancel Reason <br />
        </div>
      ),
      selector: (row) => row.auth_reason,
    },
    {
      name: (
        <div>
          Status <br />
        </div>
      ),
      selector: (row) => {
        switch (row.status) {
          case "0":
            return "Pending";
          case "1":
            return "Reject";
          case "2":
            return "Approved";
          case "3":
            return "Cancel";
          default:
            return "";
        }
      },
    },
    {
      name: (
        <div>
          Action <br />
        </div>
      ),
      center: true,
      cell: (row) => (
        <>
          {utils.hasPermission(
            primaryMenuRes,
            "Leave Request",
            "leave_request.edit"
          ) && (
            <>
              {" "}
              {row.status == "0" && (
                <RB.Button
                  className="btn-circle"
                  variant="outline-primary"
                  size="sm"
                  onClick={handelEdit(row)}
                >
                  <EditIcon />
                </RB.Button>
              )}
            </>
          )}
          &nbsp;
          {utils.hasPermission(
            primaryMenuRes,
            "Leave Request",
            "leave_request.delete"
          ) && (
            <>
              {" "}
              {row.status == "0" && (
                <RB.Button
                  variant="outline-danger"
                  className="btn-circle"
                  size="sm"
                  onClick={showDelete(row)}
                >
                  <Delete />
                </RB.Button>
              )}
            </>
          )}
        </>
      ),

      omit:
        !utils.hasPermission(
          primaryMenuRes,
          "Leave Request",
          "leave_request.edit"
        ) &&
        !utils.hasPermission(
          primaryMenuRes,
          "Leave Request",
          "leave_request.delete"
        ),
    },
  ];

  useEffect(() => {
    getleaveList();
  }, [page]);

  const getleaveList = () => {
    utils
      .callAPI("get", `/leave/leave-request/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setleaveList(res);
          setHideAction(res?.result?.some((item) => item.status !== "0"));
        } else {
          setleaveList([]);
        }
      })
      .catch((err) => {
        setleaveList([]);
      });
  };

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const showDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `/leave/leave-request/delete?id=${row.id}`, "")
          .then((res) => {
            if (typeof res !== "undefined") {
              getleaveList();
            }
          })
          .catch((err) => {});
      }
    });
  });

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="section-body">
      <RB.Card className="card shadow ">
        <RB.Card.Header>
          <h4></h4>
          <div className="card-header-action">
            {utils.hasPermission(
              primaryMenuRes,
              "Leave Request",
              "leave_request.create"
            ) && (
              <RB.Button
                className="btn btn-primary me-2"
                onClick={handleAddClick}
              >
                Apply Leave
              </RB.Button>
            )}
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          {utils.hasPermission(
            primaryMenuRes,
            "Leave Request",
            "leave_request.view"
          ) && (
            <DataTable
              columns={columns}
              data={leaveList.result}
              highlightOnHover
              responsive
              pagination
              paginationServer
              paginationTotalRows={leaveList.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={(page) => setPage(page)}
              persistTableHead
              customStyles={{
                head: {
                  style: {
                    fontSize: "0.875rem", // Adjust the font size as needed
                    fontWeight: "bold", // Adjust the font weight as needed
                  },
                },
              }}
              striped
              // subHeader
              // subHeaderComponent={subHeaderComponent}
            />
          )}
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default List;
