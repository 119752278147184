import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import * as utils from "../../Utils/comman";
import { useLocation, useNavigate } from "react-router-dom";

function Add(props) {
  const navigate = useNavigate()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [formData, setFormData] = useState({
    employelist: id ? id : "",
    leaveTypelist: "",
    year: new Date().getFullYear(),
    balance: "",
  });
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [userList, setUserList] = useState([]);
  const [leaveTypeList, setLeaveTypeList] = useState([]);

  useEffect(() => {
    getUserList();
    getLeaveTypeList();
  }, []);

  const getUserList = () => {
    utils
      .callAPI("get", `/users/users-list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setUserList(res.result);
        } else {
          setUserList([]);
        }
      })
      .catch((err) => {
        setUserList([]);
      });
  };

  const getLeaveTypeList = () => {
    utils
      .callAPI("get", `/leave/leave-type/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setLeaveTypeList(res);
        } else {
          setLeaveTypeList([]);
        }
      })
      .catch((err) => {
        setLeaveTypeList([]);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      if (props.rowData && Object.keys(props.rowData).length > 0) {
        bodyFormData.append("id", props.rowData.id);
      }

      bodyFormData.append("user_id", formData.employelist);
      bodyFormData.append("leave_type_id", formData.leaveTypelist);
      bodyFormData.append("year", formData.year);
      bodyFormData.append("leave_balance", formData.balance);

      utils
        .callAPI("post", `/leave/leave-balance/save`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            if (typeof res !== "undefined") {
              const propdata = {
                mode: "list",
              };
              props.onChange(propdata);
            }
            setIsSubmit(false);
            if (id) {
              navigate(".", { replace: true });
            }
          }
        })
        .catch((err) => {});
    }
  }, [isSubmit]);

  useEffect(() => {
    if (props.rowData && Object.keys(props.rowData).length > 0) {
      setisEdit(true);
      setFormData({
        employelist: props.rowData.user_id || "",
        leaveTypelist: props.rowData.leave_type_id || "",
        year: props.rowData.year || new Date().getFullYear(),
        balance: props.rowData.leave_balance || "",
      });
    } else {
      setisEdit(false);
    }
  }, [props.rowData]);

  return (
    <div className="section-body ">
      <RB.Card className="card shadow ">
        <RB.Card.Header>
          <h4>{isEdit ? "Edit" : "Add"}</h4>
          <div className="card-header-action">
            <RB.Button
              className="btn btn-primary me-2"
              onClick={handleBackClick}
            >
              Back
            </RB.Button>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
            <RB.Row>
              <RB.Col md={6} className="">
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formEmployeeList"
                    label="Employee"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="employelist"
                      value={formData.employelist}
                      onChange={handleInputChange}
                      required
                      disabled={id ? true : false}

                    >
                      <option value="">Select Employee...</option>
                      {userList.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                          {`${employee.firstName} ${employee.lastName}`}
                        </option>
                      ))}
                    </RB.Form.Control>
                    {/* <RB.Form.Control.Feedback type="invalid">
                      Please select an employee.
                    </RB.Form.Control.Feedback> */}
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6} className="">
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formLeaveTypeList"
                    label="Leave Type"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Select
                      as="select"
                      name="leaveTypelist"
                      value={formData.leaveTypelist}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Leave Type...</option>
                      {leaveTypeList.map((leaveType) => (
                        <option key={leaveType.id} value={leaveType.id}>
                          {leaveType.leave_name}
                        </option>
                      ))}
                    </RB.Form.Select>
                    {/* <RB.Form.Control.Feedback type="invalid">
                      Please select a leave type.
                    </RB.Form.Control.Feedback> */}
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6} className="">
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formYear"
                    label="Year"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="number"
                      placeholder="Enter Year"
                      name="year"
                      value={formData.year}
                      onChange={handleInputChange}
                      required
                    />
                    <RB.Form.Control.Feedback type="invalid">
                      Please provide year.
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6} className="">
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formBalance"
                    label="Leave Balance"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="number"
                      placeholder="Enter Leave Balance"
                      name="balance"
                      value={formData.balance}
                      onChange={handleInputChange}
                      required
                    />
                    {/* <RB.Form.Control.Feedback type="invalid">
                      Please provide numeric value for balance.
                    </RB.Form.Control.Feedback> */}
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>
            <RB.Row className="justify-content-start mt-3">
              <RB.Col className="col-auto">
                <RB.Button variant="primary" type="submit">
                  Submit
                </RB.Button>
              </RB.Col>
            </RB.Row>
          </RB.Form>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default Add;
