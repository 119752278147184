import React, { useCallback, useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import * as utils from "../../Utils/comman";
import moment from "moment";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Cancel, Delete, Done, Edit, MoreHoriz } from "@mui/icons-material";
import Swal from "sweetalert2";

function List(props) {
  const [resignationData, setResignationData] = useState({
    resignationDate: null,
    reason: "",
  });
  const [resignation, setresignation] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [isReject, SetIsReject] = useState(false);
  const [editID, setEditId] = useState("");
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [resignationDetail, setresignationDetail] = useState([]);
  const [id, setId] = useState("");
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    reason: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setResignationData({ ...resignationData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (
      utils.hasPermission(primaryMenuRes, "Resignation", "resignation.viewAll")
    ) {
      getresignationViewAll();
    } else {
      getresignation();
    }
  }, []);

  const getresignation = () => {
    utils
      .callAPI("get", `/resignation/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          if (Object.keys(res).length !== 0) {
            setresignationDetail(res);
          } else {
            setresignationDetail([]);
          }
        } else {
          setresignationDetail([]);
        }
      })
      .catch((err) => {
        setresignationDetail([]);
      });
  };
  const getresignationViewAll = () => {
    utils
      .callAPI("get", `resignation/list-all`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setresignation(res);
        } else {
          setresignation([]);
        }
      })
      .catch((err) => {
        setresignation([]);
      });
  };

  const hasStatusZero = resignation?.result?.some(
    (resignation) => resignation.resign_status === "0"
  );

  const columns = [
    {
      name: "Employee Name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
    },

    {
      name: "Resignation Date",
      selector: (row) => moment(row.resign_date).format("DD-MM-YYYY"),
    },

    {
      name: "Reason",
      selector: (row) => row.reason_for_resign,
    },
    {
      name: "Status",
      selector: (row) => {
        return getStatus(row);
      },
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <RB.OverlayTrigger
            trigger="click"
            rootClose
            key="bottom"
            placement="bottom"
            overlay={
              <RB.Popover id={`popover-positioned-bottom`}>
                <RB.Popover.Body className="p-0">
                  <div className="list-group">
                    <Link
                      className="list-group-item list-group-item-action"
                      onClick={(e) => {
                        e.preventDefault();
                        handleAccept(row)();
                      }}
                    >
                      <Done /> Accept
                    </Link>
                    <Link
                      className="list-group-item list-group-item-action"
                      onClick={(e) => {
                        e.preventDefault();
                        handleReject(row)();
                      }}
                    >
                      <Cancel /> Reject
                    </Link>
                  </div>
                </RB.Popover.Body>
              </RB.Popover>
            }
          >
            <RB.Button
              className="btn-circle"
              variant="outline-secondary"
              size="sm"
            >
              <MoreHoriz />
            </RB.Button>
          </RB.OverlayTrigger>
        </>
      ),
      omit: !hasStatusZero,
    },
  ];

  const getStatus = (row) => {
    switch (row.resign_status) {
      case "0":
        return "Pending";
      case "1":
        return "Approved";
      case "2":
        return "Rejected";
      default:
        return "Unknown";
    }
  };

  const handleAccept = useCallback((row) => async () => {
    const propdata = {
      mode: "add",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const handleReject = useCallback((row) => async () => {
    setId(row.id);
    handleShow();
  });

  const handleEdit = (id) => {
    setIsEdit(true);
    resignationDetail.map((data) => {
      setResignationData({
        reason: data.reason_for_resign,
        resignationDate: data.resign_date,
      });
      setEditId(data.id);
    });
    setresignationDetail([]);
  };

  const handleDelete = () => {
    resignationDetail.map((data) => {
      setEditId(data.id);
    });
    setIsDelete(true);
  };

  useEffect(() => {
    if (isDelete) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          utils
            .callAPI("get", `/resignation/delete?id=${editID}`, "")
            .then((res) => {
              if (typeof res !== "undefined") {
                getresignation();
                setIsDelete(false);
                setEditId("");
                setresignationDetail([]);
                setResignationData({
                  resignationDate: null,
                  reason: "",
                });
              } else {
                setIsDelete(false);
              }
            })
            .catch((err) => {
              setIsDelete(false);
            });
        }
      });
    }
  }, [isDelete]);

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      if (isEdit) {
        bodyFormData.append("id", editID);
      }
      bodyFormData.append(
        "resign_date",
        moment(resignationData.resignationDate).format("YYYY-MM-DD")
      );
      bodyFormData.append("reason_for_resign", resignationData.reason);
      utils
        .callAPI("post", `/resignation/save`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            if (typeof res !== "undefined") {
              const propdata = {
                mode: "list",
              };
              props.onChange(propdata);
            }
            setIsSubmit(false);
            setIsEdit(false);
            getresignation();
          } else {
            setIsSubmit(false);
          }
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);
  const isApprovedOrRejected = resignationDetail?.some(
    (data) => data.resign_status === "0"
  );

  const handleSbmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      SetIsReject(true);
      handleClose();
    }
  };

  useEffect(() => {
    if (isReject) {
      var bodyFormData = new FormData();
      bodyFormData.append("id", id);

      bodyFormData.append("last_working_date", "");
      bodyFormData.append("notice_period", "");
      bodyFormData.append("resign_accept_date", "");
      bodyFormData.append("resign_status", "2");
      bodyFormData.append("reject_reason", formData.reason);
      // bodyFormData.append("reject_reason", formData.rejectReason);

      utils
        .callAPI("post", `/resignation/approval`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            console.log(res.data);
            setFormData({ reason: "" });
            handleClose();
            SetIsReject(false);
            setId("");
            getresignationViewAll();
          } else {
            console.log("first");
            SetIsReject(false);
          }
        })
        .catch((err) => {
          SetIsReject(false);
        });
    }
  }, [isReject]);
  return (
    <>
      <div className="section-body">
        <RB.Card className="card shadow">
          {/* <RB.Card.Header>
            <h4>
              {!utils.hasPermission(
                primaryMenuRes,
                "Resignation",
                "resignation.viewAll"
              )
                ? resignationDetail.length !== 0
                  ? "Resignation Details"
                  : "Add Resignation"
                : ""}
            </h4>

            <div className="card-header-action">
              {!utils.hasPermission(
                primaryMenuRes,
                "Resignation",
                "resignation.viewAll"
              ) && (
                <>
                  {utils.hasPermission(
                    primaryMenuRes,
                    "Resignation",
                    "resignation.edit"
                  ) &&
                    resignationDetail.length !== 0 &&
                    (!isApprovedOrRejected ? (
                      ""
                    ) : (
                      <RB.Button
                        className="btn me-2"
                        variant="outline-primary"
                        onClick={handleEdit}
                      >
                        <Edit />
                        Edit
                      </RB.Button>
                    ))}
                  {utils.hasPermission(
                    primaryMenuRes,
                    "Resignation",
                    "resignation.delete"
                  ) &&
                    resignationDetail.length !== 0 &&
                    (!isApprovedOrRejected ? (
                      ""
                    ) : (
                      <RB.Button
                        className="btn"
                        variant="outline-danger"
                        onClick={handleDelete}
                      >
                        <Delete />
                        Delete
                      </RB.Button>
                    ))}{" "}
                </>
              )}
            </div>
          </RB.Card.Header> */}
          <RB.Card.Body>
            {!utils.hasPermission(
              primaryMenuRes,
              "Resignation",
              "resignation.viewAll"
            ) &&
              (resignationDetail.length !== 0 ? (
                <>
                  {resignationDetail?.map((data) => (
                    <>
                      <RB.Row>
                        {resignationDetail?.map((data) => {
                          let statusLabel = "";
                          switch (data.resign_status) {
                            case "0":
                              statusLabel = "Pending";
                              break;
                            case "1":
                              statusLabel = "Approved";
                              break;
                            case "2":
                              statusLabel = "Reject";
                              break;
                            default:
                              statusLabel = "Unknown";
                              break;
                          }
                          return (
                            <>
                              <span className="text-uppercase fw-bold small text-body-secondary">
                                Status
                              </span>
                              <p className="fw-medium text-sm fw-semibold">
                                {statusLabel}
                              </p>
                            </>
                          );
                        })}
                      </RB.Row>
                      <RB.Row>
                        <RB.Col>
                          <span className="text-uppercase fw-bold small text-body-secondary">
                            Resignation Date
                          </span>
                          <p className="fw-medium text-sm fw-semibold">
                            {data.resign_date}
                          </p>
                        </RB.Col>
                        <RB.Col>
                          <span className="text-uppercase fw-bold small text-body-secondary">
                            Resignation Reason
                          </span>
                          <p className="fw-medium text-sm fw-semibold">
                            {data.reason_for_resign}
                          </p>
                        </RB.Col>
                      </RB.Row>
                      <RB.Row>
                        {" "}
                        {data.reject_reason && (
                          <RB.Col>
                            <span className="text-uppercase fw-bold small text-body-secondary">
                              Reject Reason
                            </span>
                            <p className="fw-medium text-sm fw-semibold">
                              {data.reject_reason}
                            </p>
                          </RB.Col>
                        )}
                        <RB.Col>
                          {" "}
                          {data.last_working_date &&
                            data.last_working_date !== "0000-00-00" && (
                              <>
                                <span className="text-uppercase fw-bold small text-body-secondary">
                                  Last Working Date
                                </span>
                                <p className="fw-medium text-sm fw-semibold">
                                  {data.last_working_date}
                                </p>
                              </>
                            )}
                        </RB.Col>
                        <RB.Col>
                          {" "}
                          {data.notice_period && (
                            <>
                              <span className="text-uppercase fw-bold small text-body-secondary">
                                Notice Period
                              </span>
                              <p className="fw-medium text-sm fw-semibold">
                                {" "}
                                {data.notice_period} days
                              </p>
                            </>
                          )}
                        </RB.Col>
                      </RB.Row>{" "}
                    </>
                  ))}
                </>
              ) : (
                <>
                  <RB.Row>
                    <RB.Col md={6} className="">
                      <RB.Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <div className="mb-3">
                          <RB.FloatingLabel className="floating-label-fancy">
                            <DatePicker
                              selected={resignationData.resignationDate}
                              onChange={(date) =>
                                setResignationData({
                                  ...resignationData,
                                  resignationDate: date,
                                })
                              }
                              dateFormat="dd-MM-yyyy"
                              className="form-control"
                              placeholderText="Select Resignation Date"
                              minDate={new Date()}
                              required
                            />
                          </RB.FloatingLabel>
                        </div>
                        <div className="mb-3">
                          <RB.FloatingLabel
                            label="Resignation reason"
                            className="floating-label-fancy"
                          >
                            <RB.Form.Control
                              as="textarea"
                              type="text"
                              placeholder="Enter Leave Name"
                              name="reason"
                              value={resignationData.reason}
                              onChange={handleChange}
                              required
                            />
                            {/* <RB.Form.Control.Feedback type="invalid">
                          Please enter a leave name.
                        </RB.Form.Control.Feedback> */}
                          </RB.FloatingLabel>
                        </div>
                        <div className="mt-3">
                          <RB.Button type="submit" variant="primary">
                            Submit
                          </RB.Button>
                        </div>
                      </RB.Form>
                    </RB.Col>
                  </RB.Row>
                </>
              ))}
            {/* For hr  */}

            {utils.hasPermission(
              primaryMenuRes,
              "Resignation",
              "resignation.viewAll"
            ) && (
              <DataTable
                columns={columns}
                data={resignation.result}
                highlightOnHover
                responsive
                pagination
                paginationServer
                striped
                paginationTotalRows={resignation.total}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                onChangePage={(page) => setPage(page)}
                persistTableHead
                customStyles={{
                  head: {
                    style: {
                      fontSize: "0.875rem", // Adjust the font size as needed
                      fontWeight: "bold", // Adjust the font weight as needed
                    },
                  },
                }}
                //   subHeader
                // subHeaderComponent={subHeaderComponent}
              />
            )}
          </RB.Card.Body>
        </RB.Card>
      </div>
      <RB.Modal show={show} onHide={handleClose} backdrop="static">
        <RB.Modal.Header closeButton>
          <RB.Modal.Title>Reason</RB.Modal.Title>
        </RB.Modal.Header>
        <RB.Modal.Body className="m-0">
          <RB.Form noValidate validated={validated} onSubmit={handleSbmit}>
            <RB.Row className="m-o">
              <RB.Col md={12}>
                <RB.FloatingLabel
                  label="Reason"
                  className="floating-label-fancy"
                >
                  <RB.Form.Control
                    as="textarea"
                    placeholder="Enter Reason"
                    name="reason"
                    value={formData.reason}
                    onChange={handleInputChange}
                    required
                  />
                </RB.FloatingLabel>
              </RB.Col>
            </RB.Row>
            <RB.Row className="justify-content-end mt-3">
              <RB.Col className="col-auto">
                <RB.Button
                  variant="secondary"
                  className="me-2"
                  onClick={handleClose}
                >
                  Close
                </RB.Button>
                <RB.Button variant="primary" type="submit">
                  Submit
                </RB.Button>
              </RB.Col>
            </RB.Row>
          </RB.Form>
        </RB.Modal.Body>
        <RB.Modal.Footer className="pt-0"></RB.Modal.Footer>
      </RB.Modal>
    </>
  );
}

export default List;
