import React, { useState } from 'react'
import Layout from "../Layout";
import { useLocation } from "react-router-dom";
import List from '../Components/Regularize Attendance/LIst';

function RegularizeAttendance() {
    const [comp, setComp] = useState("list");
    const [rowData, setRowData] = useState();
    const location = useLocation();
  
    // useEffect(() => {
    //   if (comp !== "list") {
    //     setComp("list");
    //   }
    // }, [location]);
  
    function handleChange(newValue) {
      setComp(newValue.mode);
      setRowData(newValue.rowData);
    }
  
    return (
      <Layout>
        <div className="main-content">
          <section className="section">
            <div className="section-header">
              <h1>Regularization Request</h1>
            </div>
  
          
              <List comp={comp} onChange={handleChange} rowData={rowData} />
          
          </section>
        </div>
      </Layout>
    );
}

export default RegularizeAttendance