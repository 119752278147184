import {
  Circle,
  Delete,
  InsertDriveFile,
  PictureAsPdf,
  PlusOne,
  PlusOneSharp,
} from "@mui/icons-material";
import { Add, AddCircle, Image, Trash } from "iconsax-react";
import React, { useEffect, useState } from "react";
import * as utils from "../../Utils/comman";
import * as RB from "react-bootstrap";
import Swal from "sweetalert2";

function View(props) {
    const [travelDocuments, setTravelDocuments] = useState(
        props?.rowData?.travelDocuments || []
      );
  const handleBackClick = () => {
    props.onChange({ mode: "list" });
  };

  const handleDeleteDocument = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `travel/document-delete?travelDocId=${id}`, "")
          .then((res) => {
            if (typeof res !== "undefined") {
              setTravelDocuments((prevDocs) =>
                prevDocs.filter((doc) => doc.travelDocId !== id)
              );
            }
          })
          .catch((err) => {});
      }
    });
  };

  const handleDownload = async (url, filename) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Failed to download image:", error);
    }
  };
  return (
    <div className="section-body">
      <RB.Card className="card shadow">
        <RB.Card.Header>
          <h4>View Documents</h4>
          <div className="card-header-action">
            <RB.Button className="btn btn-primary" onClick={handleBackClick}>
              Back
            </RB.Button>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Row className="mt-3 g-3 justify-content-center">
            {travelDocuments?.map((doc, index) => (
              <RB.Col key={index} className="col-md-6 col-lg-3">
                {console.log(doc)}
                <div className="position-relative me-2 mb-4 rounded border p-3 text-center">
                  <div className="travel-doc-upload">
                    <a
                      href={doc.travelDocument}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none text-dark"
                    >
                      {
                      doc.travelDocument.endsWith(".pdf") ? (
                        <i className="material-icons-outlined md-64">
                          picture_as_pdf
                        </i>
                      ) : (
                        <i className="material-icons-outlined md-64">image</i>
                      )}
                      <div className="text-center">Travel Bill {index + 1}</div>
                    </a>
                  </div>
                  <div className="d-flex justify-content-between  position-absolute top-100 start-50 translate-middle">
                    <RB.Button
                      variant="danger"
                      className="btn btn-sm btn-circle me-2"
                      onClick={() => handleDeleteDocument(doc.travelDocId)}
                    >
                      <i className="material-icons-outlined">delete</i>
                    </RB.Button>
                    {!doc.travelDocument.endsWith(".pdf") && (
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() =>
                          handleDownload(
                            doc.travelDocument,
                            `TravelBill${index + 1}.jpg`
                          )
                        }
                      >
                        <i className="material-icons-outlined">file_download</i>
                      </button>
                    )}
                  </div>
                </div>
              </RB.Col>
            ))}
            {travelDocuments.length == 0 && (
              <RB.Col className="col-md-6 col-lg-3 d-flex align-items-center justify-content-center">
              <h5>No document found.</h5>
            </RB.Col>
            )}
          </RB.Row>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default View;
