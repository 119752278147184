import React, { useState, useEffect } from "react";
import * as RB from "react-bootstrap";
import * as utils from "../Utils/comman";
import { useNavigate } from "react-router-dom";
import Logo from "../Components/Logo";
import LogoDark from "../Components/LogoDark";
import loginImg from "../assets/images/login-img.png";
import { useDispatch } from "react-redux";
import { fetchUserProfile } from "../reducers/userProfileSlice";
import { fetchPrimaryMenu } from "../reducers/primaryMenuSlice";
function Login() {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState(sessionStorage.getItem("access_token"));
  const navigate = useNavigate();
  let year = new Date().getFullYear();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
    //setIsSubmit(true);
  };

  useEffect(() => {
    if (isSubmit) {
      const bodyFormData = new FormData();
      bodyFormData.append("email", values.email);
      bodyFormData.append("password", values.password);
      utils
        .callAPI("post", "/authentication/login", bodyFormData)
        .then((res) => {
          if (res !== undefined) {
            sessionStorage.setItem("access_token", res.access_token);
            dispatch(fetchUserProfile());
            dispatch(fetchPrimaryMenu());
            navigate("/dashboard");
            setIsSubmit(false);
          } else {
            setIsSubmit(false);
          }
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
    // if(isSubmit){
    //   navigate("/dashboard");
    // }
  }, [isSubmit]);

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <>
      <section className="login-wrap min-vh-100 d-flex justify-content-center align-items-center">
        <RB.Container className="mt-5">
          <RB.Row className="row justify-content-center justify-content-lg-between align-items-center">
            <RB.Col md={10} lg={6} xl={7} className="order-2 order-lg-1">
              <div className="logo-block d-none d-lg-flex align-items-center mb-4">
                <LogoDark />
              </div>
              <h2 className="text-dark mb-4">
                A Futuristic Brand Conceived And Nurtured With The Experience Of
                120 Years.{" "}
                {/* A FUTURISTIC BRAND CONCEIVED AND NURTURED WITH THE EXPERIENCE OF
                120 YEARS. */}
              </h2>
              <p className="text-md mt-3">
                Vura Bau-Chemie LLP is a global brand that manufactures
                adhesives, sealants, waterproofing, repairs, maintenance &
                building products which are formulated with world-class German
                polymers & technology. It has developed a comprehensive product
                range based on international standards to facilitate the global
                construction chemical manufacturer and building materials
                market.
                {/* The brains behind the product development have a
                combined experience of more than 120 years in the same industry.
                With this abundance of experience, the company is ready to build
                better! */}
              </p>
              <div className="login-img">
                <RB.Image
                  src={loginImg}
                  alt="Vura login"
                  className="img-fluid"
                />
              </div>
            </RB.Col>
            <RB.Col
              md={10}
              lg={6}
              xl={5}
              className="order-1 order-lg-2 mb-3 mb-md-0"
            >
              <div className="logo-block d-flex d-lg-none my-4 justify-content-center">
                <LogoDark />
              </div>
              <RB.Card className="login-card p-md-5 rounded-4 shadow-lg border-0">
                <RB.CardHeader className="border-0">
                  <h2 className="text-dark mb-3 text-2xl">
                    Welcome,
                    <br />
                    Please Login Here
                  </h2>
                </RB.CardHeader>
                <RB.CardBody>
                  <RB.Form
                    onSubmit={handleSubmit}
                    noValidate
                    validated={validated}
                  >
                    <div className="mb-3">
                      <RB.FloatingLabel
                        controlId="floatingInput"
                        label="Email"
                        className="floating-label-fancy"
                      >
                        <RB.Form.Control
                          type="email"
                          placeholder="Email"
                          name="email"
                          onChange={handleTextChange}
                          isInvalid={validated && !values.email}
                          required
                        />
                        <RB.Form.Control.Feedback type="invalid">
                          Please enter valid email address.
                        </RB.Form.Control.Feedback>
                      </RB.FloatingLabel>
                    </div>
                    <div className="mb-3">
                      <RB.FloatingLabel
                        controlId="floatingInput"
                        label="Password"
                        className="floating-label-fancy"
                      >
                        <RB.Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          name="password"
                          isInvalid={validated && !values.email}
                          onChange={handleTextChange}
                          required
                        />
                        <i
                          className="material-icons-outlined position-absolute end-0 translate-middle-y icon-password-eye"
                          style={{ cursor: "pointer" }}
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? "visibility_off" : "visibility"}
                        </i>
                        <RB.Form.Control.Feedback type="invalid">
                          Password is required.
                        </RB.Form.Control.Feedback>
                      </RB.FloatingLabel>
                    </div>

                    {/* <input type='checkbox' className='form-check-input' id="remember-me" />
                                            <label className='form-check-label' for="remember-me">Remember Me</label> */}
                    <div className="d-flex justify-content-end align-items-center mb-3  ">
                      <RB.Form.Check
                        type="checkbox"
                        id="remember-me"
                        className="d-none mb-3"
                      >
                        <RB.Form.Check.Input type="checkbox" />
                        <RB.Form.Check.Label>Remember Me</RB.Form.Check.Label>
                      </RB.Form.Check>
                      <div className="forgot-password">
                        <a
                          href="/forgot-password"
                          className="text-small text-dark"
                        >
                          Forgot Password?
                        </a>
                      </div>
                    </div>
                    <div className="d-grid gap-2">
                      <RB.Button type="submit" variant="primary">
                        Login
                      </RB.Button>
                    </div>
                  </RB.Form>
                  {/* <div className="login-card-footer pt-4">
                    <p className="text-body-secondary">
                      Don’t have an account?{" "}
                      <a href="#" className="text-dark text-underline">
                        Create Now
                      </a>
                    </p>
                  </div> */}
                </RB.CardBody>
              </RB.Card>
            </RB.Col>
          </RB.Row>
          <RB.Row className="row justify-content-between align-items-center">
            <RB.Col
              md={12}
              lg={6}
              xl={7}
              className="order-2 order-lg-1"
            ></RB.Col>
            <RB.Col md={12} lg={6} xl={5} className="order-2 order-lg-1">
              <div className="simple-footer">
                Copyright &copy; {process.env.REACT_APP_TITLE} {year}
              </div>
            </RB.Col>
          </RB.Row>
        </RB.Container>
      </section>
    </>
  );
}
// function validate(values){
//     let errors = {};
//     var email = /^\S+@\S+\.\S+$/;
//     if(!values.email.match(email)){
//         errors.email = 'Please enter valid email.';
//     }
//     return errors;
// }
export default Login;
