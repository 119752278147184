import React, { useState, useEffect } from "react";
import * as RB from "react-bootstrap";
import * as utils from "../Utils/comman";
import { Link, useNavigate } from "react-router-dom";
import brandLogo from "../assets/images/logo.png";
import Logo from "../Components/Logo";
import loginImg from "../assets/images/login-img.png";

function ForgotPassword() {
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  let year = new Date().getFullYear();
  const [values, setValues] = useState({
    email: "",
  });

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      const bodyFormData = new FormData();
      bodyFormData.append("email", values.email);
      utils
        .callAPI("post", "/forgot/forgot-password", bodyFormData)
        .then((res) => {
          if (res !== undefined) {
            setIsSubmit(false);
            setValues({
              email: "",
            });
          } else {
            setIsSubmit(false);
          }
        });
    }
  }, [isSubmit]);

  return (
    <section className="login-wrap min-vh-100 d-flex justify-content-center align-items-center">
      <RB.Container className="mt-5">
        <RB.Row className="row justify-content-center justify-content-lg-between align-items-center">
          <RB.Col md={10} lg={6} xl={7} className="order-2 order-lg-1">
            {/* <div className="logo-block d-none d-lg-flex align-items-center mb-3 mb-md-5">
              <RB.Image src={brandLogo} alt="Vura" />
            </div> */}
            <div className="logo-block d-none d-lg-flex align-items-center mb-4">
              <Logo />
            </div>
            <h2 className="text-dark mb-4">
              Lorem ipsum dolor sit amet consectetur. Porttitor id vivamus enim
              ipsum.{" "}
            </h2>
            <p className="lead mt-3">
              Lorem ipsum dolor sit amet consectetur. Luctus nulla neque eu
              imperdiet. Ut ultrices non vitae quis nibh. Egestas vitae
              imperdiet amet est. Massa justo in adipiscing a scelerisque ut
              condimentum.
            </p>
            <div className="login-img">
              <RB.Image src={loginImg} alt="Vura login" className="img-fluid" />
            </div>
          </RB.Col>
          <RB.Col
            md={10}
            lg={6}
            xl={5}
            className="order-1 order-lg-2 mb-3 mb-md-0"
          >
            <div className="login-brand">
              <RB.Image
                src=""
                className="shadow-light rounded-circle"
                width={100}
              ></RB.Image>
            </div>
            <RB.Card className="login-card p-5 rounded-4 shadow-lg">
              <RB.CardHeader className="border-0">
                <h2 className="text-dark mb-3 text-2xl">Forgot Password</h2>
              </RB.CardHeader>

              <RB.CardBody>
                <RB.Form
                  onSubmit={handleSubmit}
                  noValidate
                  validated={validated}
                >
                  <div className="mb-3">
                    <RB.FloatingLabel
                      label="Email"
                      className="floating-label-fancy"
                    >
                      <RB.Form.Control
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleTextChange}
                        isInvalid={validated && !values.email}
                        required
                      />
                      <RB.Form.Control.Feedback type="invalid">
                        Please enter valid email address.
                      </RB.Form.Control.Feedback>
                    </RB.FloatingLabel>
                  </div>
                  <div className="d-grid gap-2">
                    <RB.Button type="submit" variant="primary">
                      Submit
                    </RB.Button>
                  </div>
                </RB.Form>
              </RB.CardBody>
              <div className="mt-2 text-center">
                <Link to="/" className="text-decoration-none text-secondary">
                  Back to Login
                </Link>
              </div>
            </RB.Card>
          </RB.Col>
        </RB.Row>
        <RB.Row className="row justify-content-between align-items-center">
          <RB.Col md={12} lg={6} xl={7}></RB.Col>
          <RB.Col md={12} lg={6} xl={5}>
            <div className="simple-footer">
              Copyright &copy; {process.env.REACT_APP_TITLE} {year}
            </div>
          </RB.Col>
        </RB.Row>
      </RB.Container>
    </section>
  );
}

export default ForgotPassword;
