import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import {
  AccountBalanceWalletOutlined,
  Delete,
  EventAvailable,
  EventNote,
  ExpandMore,
  MoreHoriz,
} from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setEmpSalary } from "../../reducers/employeeSalarySlice";
import { useSelector } from "react-redux";
import useCommandRRedirect from "../../hooks/useCommandRRedirect";
import { PercentageCircle } from "iconsax-react";

function List(props) {
  const dispatch = useDispatch();
  const navigte = useNavigate();
  const [empList, setEmpList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  // useCommandRRedirect("/dashboard");
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };
  const columns = [
    {
      name: "Employee Id",
      selector: (row) => row.employeeId,
    },
    {
      name: "Name",
      selector: (row) => `${row.firstName} ${row.middleName} ${row.lastName}`,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Joinnig Date",
      selector: (row) => moment(row.join_date).format("DD-MM-yyyy"),
    },
    {
      name: "Status",
      selector: (row) => (row.isActive == "1" ? "Active" : "inActive"),
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          <RB.OverlayTrigger
            trigger="click"
            rootClose
            key="bottom"
            placement="bottom"
            overlay={
              <RB.Popover id={`popover-positioned-bottom`}>
                <RB.Popover.Body className="p-0">
                  <div className="list-group">
                    {utils.hasPermission(
                      primaryMenuRes,
                      "Employee",
                      "employee.edit"
                    ) && (
                      <Link
                        className="list-group-item list-group-item-action"
                        onClick={handelEdit(row)}
                      >
                        <EditIcon /> Edit
                      </Link>
                    )}
                    {utils.hasPermission(
                      primaryMenuRes,
                      "Salary",
                      "salary.view"
                    ) && (
                      <Link
                        className="list-group-item list-group-item-action"
                        onClick={(e) => {
                          e.preventDefault();
                          handleSalary(row)();
                        }}
                      >
                        <AccountBalanceWalletOutlined /> Employee Salary
                      </Link>
                    )}

                    {/* {utils.hasPermission(
                      primaryMenuRes,
                      "Salary",
                      "salary.view"
                    ) && (
                      <Link
                        className="list-group-item list-group-item-action"
                        onClick={(e) => {
                          e.preventDefault();
                          handleTds(row)();
                        }}
                      >
                        <AccountBalanceWalletOutlined /> TDS
                      </Link>
                    )} */}

                    {/* <Link
                      className="list-group-item list-group-item-action"
                      onClick={(e) => {
                        e.preventDefault();
                        handleEmpLeave(row)();
                      }}
                    >
                      <EventNote /> Leave Balance
                    </Link> */}

                    {utils.hasPermission(
                      primaryMenuRes,
                      "Attendance",
                      "attendance.viewAll"
                    ) && (
                      <Link
                        className="list-group-item list-group-item-action"
                        onClick={(e) => {
                          e.preventDefault();
                          handleEmpAttendance(row)();
                        }}
                      >
                        <EventAvailable /> Employee Attendance
                      </Link>
                    )}

                    {utils.hasPermission(primaryMenuRes, "TDS", "tds.view") && (
                      <Link
                        className="list-group-item list-group-item-action"
                        onClick={(e) => {
                          e.preventDefault();
                          handleEmpTds(row)();
                        }}
                      >
                        <PercentageCircle /> Employee TDS
                      </Link>
                    )}
                  </div>
                </RB.Popover.Body>
              </RB.Popover>
            }
          >
            <RB.Button
              className="btn-circle"
              variant="outline-secondary"
              size="sm"
            >
              <MoreHoriz />
            </RB.Button>
          </RB.OverlayTrigger>
          {utils.hasPermission(
            primaryMenuRes,
            "Employee",
            "employee.delete"
          ) && (
            <RB.Button
              className="btn-circle ms-2"
              variant="outline-danger"
              size="sm"
              onClick={showDelete(row)}
              title="Delete"
            >
              <Delete />
            </RB.Button>
          )}
        </>
      ),

      omit:
        !utils.hasPermission(primaryMenuRes, "Employee", "employee.edit") &&
        !utils.hasPermission(primaryMenuRes, "Employee", "employee.delete"),
    },
  ];

  useEffect(() => {
    getEmployeeList();
  }, [page]);

  const getEmployeeList = () => {
    utils
      .callAPI("get", `/employee/list?page=${page}&per_page=${countPerPage}`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setEmpList(res);
        } else {
          setEmpList([]);
        }
      })
      .catch((err) => {
        setEmpList([]);
      });
  };

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const showDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Delete Employee",
      text: "Once deleted, your data will be permanently removed and cannot be recovered. Are you sure you want to delete?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI(
            "get",
            `/employee/delete?id=${row.id}&role_id=${row.role_edit_id}`,
            ""
          )
          .then((res) => {
            if (typeof res !== "undefined") {
              getEmployeeList();
            }
          })
          .catch((err) => {});
      }
    });
  });
  const handleSalary = useCallback((row) => async () => {
    dispatch(setEmpSalary(row));

    navigte(`/employee/salary?id=${row.employeeId}`);
  });

  const handleTds = useCallback((row) => async () => {
    //dispatch(setEmpSalary(row));

    navigte(`/employee/tds?id=${row.employeeId}`);
  });

  const handleEmpLeave = useCallback((row) => async () => {
    navigte(`/leave/leave-balance?id=${row.userId}`);
  });

  const handleEmpAttendance = useCallback((row) => async () => {
    navigte(`/attendances?id=${row.id}`);
  });

  const handleEmpTds = useCallback((row) => async () => {
    navigte(`/employee/tds?id=${row.id}`);
  });

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="section-body">
      <RB.Card className="card shadow">
        <RB.Card.Header>
          <h4></h4>
          <div className="card-header-action">
            {utils.hasPermission(
              primaryMenuRes,
              "Employee",
              "employee.create"
            ) && (
              <RB.Button className="btn btn-primary" onClick={handleAddClick}>
                Add Employee
              </RB.Button>
            )}
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          {utils.hasPermission(primaryMenuRes, "Employee", "employee.view") && (
            <DataTable
              columns={columns}
              data={empList.result}
              highlightOnHover
              responsive
              pagination
              paginationServer
              paginationTotalRows={empList.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              striped
              onChangePage={(page) => setPage(page)}
              persistTableHead
              customStyles={{
                head: {
                  style: {
                    fontSize: "0.875rem", // Adjust the font size as needed
                    fontWeight: "bold", // Adjust the font weight as needed
                  },
                },
              }}
              //subHeader
              // subHeaderComponent={subHeaderComponent}
            />
          )}
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default List;
