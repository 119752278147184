import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import * as utils from "../../Utils/comman";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";

function Add(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [formData, setFormData] = useState({
    resignDate: props.rowData.resign_date,
    lastWorkingDate: null,
    reason: props.rowData.reason_for_resign,
    noticePeriod: "",
    resignAcceptDate: null,
    resignStatus: "accept",
    rejectReason: "",
  });
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      bodyFormData.append("id", props.rowData.id);

      bodyFormData.append(
        "last_working_date",
        moment(formData.lastWorkingDate).format("YYYY-MM-DD")
      );
      bodyFormData.append("notice_period", formData.noticePeriod);
      bodyFormData.append(
        "resign_accept_date",
        moment(formData.resignAcceptDate).format("YYYY-MM-DD")
      );
      bodyFormData.append("resign_status", "1");
      bodyFormData.append("reject_reason", "");
      // bodyFormData.append("reject_reason", formData.rejectReason);

      utils
        .callAPI("post", `/resignation/approval`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            if (typeof res !== "undefined") {
              const propdata = {
                mode: "list",
              };
              props.onChange(propdata);
            }
            setIsSubmit(false);
          } else {
            setIsSubmit(false);
          }
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  return (
    <div className="section-body ">
      <RB.Card className="card shadow ">
        <RB.Card.Header>
          <h4>Add</h4>
          <div className="card-header-action">
            <RB.Button
              className="btn btn-primary me-2"
              onClick={handleBackClick}
            >
              Back
            </RB.Button>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3 mt-4">
                  <RB.FloatingLabel
                    controlId="formResignDate"
                    // label="Resign Date"
                    className="floating-label-fancy"
                  >
                    <DatePicker
                      disabled
                      selected={formData.resignDate}
                      onChange={(date) =>
                        setFormData({
                          ...formData,
                          resignDate: date,
                        })
                      }
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      placeholderText="Select Resignation Date"
                      minDate={new Date()}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6} className="">
                <div className="mb-3 mt-4">
                  <RB.FloatingLabel
                    controlId="formLastWorkingDate"
                    className="floating-label-fancy"
                  >
                    <DatePicker
                      selected={formData.lastWorkingDate}
                      onChange={(date) =>
                        setFormData({
                          ...formData,
                          lastWorkingDate: date,
                        })
                      }
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      placeholderText="Select Resignation Date"
                      minDate={new Date()}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6} className="">
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formReason"
                    label="Reason"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="textarea"
                      disabled
                      name="reason"
                      value={formData.reason}
                      onChange={handleInputChange}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6} className="">
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formNoticePeriod"
                    label="Notice Period (Days)"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="number"
                      name="noticePeriod"
                      value={formData.noticePeriod}
                      onChange={handleInputChange}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6} className="">
                <div className="mb-3 mt-4">
                  <RB.FloatingLabel
                    controlId="formResignAcceptDate"
                    // label="Resign Accept Date"
                    className="floating-label-fancy"
                  >
                    <DatePicker
                      selected={formData.resignAcceptDate}
                      onChange={(date) =>
                        setFormData({
                          ...formData,
                          resignAcceptDate: date,
                        })
                      }
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      placeholderText="Select Resignation Date"
                      minDate={new Date()}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              {formData.resignStatus === "reject" && (
                <RB.Col md={6} className="">
                  <div className="mb-3">
                    <RB.FloatingLabel
                      controlId="formRejectReason"
                      label="Reject Reason"
                      className="floating-label-fancy"
                    >
                      <RB.Form.Control
                        as="textarea"
                        name="rejectReason"
                        value={formData.rejectReason}
                        onChange={handleInputChange}
                        required
                      />
                    </RB.FloatingLabel>
                  </div>
                </RB.Col>
              )}
            </RB.Row>
            {/* <RB.Row className="justify-content-start mt-3">
              <RB.Col className="col-auto">
                <RB.Button variant="primary" type="submit">
                  Submit
                </RB.Button>
              </RB.Col>
            </RB.Row> */}
          </RB.Form>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default Add;
