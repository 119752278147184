import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";

function List(props) {
  const empSalary = useSelector((state) => state.empSalary.employeeSalary);
  const isEmployeeSalaryEmpty = empSalary?.employee_salary?.length === 0;

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  const handleEditClick = (e) => {
    const propdata = {
      mode: "edit",
      rowData: empSalary.employee_salary,
    };
    props.onChange(propdata);
  };
  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const showDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `/EmpSalarys/EmpSalarys-delete?id=${row.id}`, "")
          .then((res) => {
            if (typeof res !== "undefined") {
            }
          })
          .catch((err) => {});
      }
    });
  });
  const calculateAllowanceTotal = (itemsString) => {
    if (!itemsString) return 0;
    const items = JSON.parse(itemsString);
    return items
      .reduce((total, item) => total + parseFloat(item.amt), 0)
      .toFixed(2);
  };

  const calculateDeductionTotal = (itemsString) => {
    if (!itemsString) return 0;
    const items = JSON.parse(itemsString);
    return items
      .reduce((total, item) => total + parseFloat(item.damt), 0)
      .toFixed(2);
  };

  const renderCompensationTable = (salary) => {
    const allowances = JSON.parse(salary.allowance);
    const deductions = JSON.parse(salary.deduction);
    const specialAllowanceYearly =
      parseFloat(
        allowances.find((a) => a.name == "Special Allowances")?.total
      ) * 12;
    const specialAllowanceYearlyFormatted = specialAllowanceYearly;

    const totalTakeHomeYearly =
      parseFloat(salary.basic_amt) +
      parseFloat(allowances.find((a) => a.name == "HRA")?.total) +
      specialAllowanceYearly;

    const PFEmployee = parseFloat(
      deductions.find((a) => a.name == "PF")?.total
    );
    const PFEmployeeMonth =
      parseFloat(deductions.find((a) => a.name == "PF")?.total) / 12;

    const monthlyBalnce = salary.balance / 12;
    const rows = [
      {
        label: "Basic Salary",
        name: "Basic Salary",
        monthly: salary.basic_amt / 12,
        yearly: salary.basic_amt,
      },
      {
        label: "HRA",
        name: "House Rent Allowance (HRA)",
        monthly: "",
        yearly: "",
      },
      {
        label: "",
        name: "Special Allowances",
        monthly: specialAllowanceYearlyFormatted / 12,
        yearly: specialAllowanceYearlyFormatted,
      },
      {
        label: "total",
        name: "Total (Take Home Salary)",
        monthly: totalTakeHomeYearly / 12,
        yearly: totalTakeHomeYearly,
      },
      { label: "", name: "Benefits", monthly: "", yearly: "" },
      {
        label: "PF",
        name: "Provident Fund Contribution",
        monthly: "",
        yearly: "",
      },
      { label: "Prof Tax", name: "Professional Tax", monthly: "", yearly: "" },
      { label: "Gratuity", name: "Gratuity", monthly: "", yearly: "" },
      {
        label: "Mediclaim",
        name: "Medical Reimbursement",
        monthly: "",
        yearly: "",
      },
      {
        label: "balance",
        name: "Monthly Gross",
        monthly: salary.balance / 12,
        yearly: salary.balance,
      },
      {
        label: "PF",
        name: "Provident Fund Contribution (Employer)",
        monthly: PFEmployeeMonth,
        yearly: PFEmployee,
      },
      {
        label: "",
        name: "Total Gross Salary",
        monthly: PFEmployeeMonth + monthlyBalnce,
        yearly: parseFloat(PFEmployee) + parseFloat(salary.balance),
      },
      { label: "", name: "Bonus", monthly: "", yearly: "" },
      {
        label: "vip",
        name: "Variable Incentive Plan",
        monthly: (salary.vip * 12) / 12,
        yearly: salary.vip * 12,
      },
      {
        label: "sip",
        name: "Special Incentive Plan",
        monthly: salary.sip / 12,
        yearly: salary.sip,
      },
      {
        label: "annual_package",
        name: "Cost to Company (PM)",
        monthly: salary.annual_package / 12,
        yearly: salary.annual_package,
      },
    ];

    allowances.forEach((allowance) => {
      const index = rows.findIndex((row) => row.label === allowance.name);
      if (index !== -1) {
        rows[index].monthly = allowance.total / 12;
        rows[index].yearly = allowance.total;
      }
    });

    deductions.forEach((deduction) => {
      const index = rows.findIndex((row) => row.label === deduction.name);
      if (index !== -1) {
        rows[index].monthly = deduction.total / 12;
        rows[index].yearly = deduction.total;
      }
    });
    return rows.map((row, index) => (
      <tr key={index}>
        <td>
          {row.name === "Total (Take Home Salary)" ||
          row.name === "Monthly Gross" ||
          row.name === "Total Gross Salary" ||
          row.name === "Cost to Company (PM)" ? (
            <strong>{row.name}</strong>
          ) : (
            row.name
          )}
        </td>
        <td>
          {row.name === "Total (Take Home Salary)" ||
          row.name === "Monthly Gross" ||
          row.name === "Total Gross Salary" ||
          row.name === "Cost to Company (PM)" ? (
            <strong>{Math.round(row.monthly)}</strong>
          ) : (
            Math.round(row.monthly)
          )}
        </td>
        <td>
          {row.name === "Total (Take Home Salary)" ||
          row.name === "Monthly Gross" ||
          row.name === "Total Gross Salary" ||
          row.name === "Cost to Company (PM)" ? (
            <strong>{Math.round(row.yearly)}</strong>
          ) : (
            Math.round(row.yearly)
          )}
        </td>
      </tr>
    ));
  };

  return (
    <div className="section-body">
      <RB.Card className="card">
        <RB.Card.Header>
          <h4>{`${empSalary?.firstName}  ${empSalary?.lastName}`}</h4>
          <div className="card-header-action">
            {isEmployeeSalaryEmpty ? (
              <RB.Button
                className="btn btn-primary me-2"
                onClick={handleAddClick}
              >
                Add Employee Salary
              </RB.Button>
            ) : (
              <RB.Button
                className="btn btn-primary me-2"
                onClick={handleEditClick}
              >
                Edit Employee Salary
              </RB.Button>
            )}
          </div>
        </RB.Card.Header>
        {empSalary?.employee_salary?.map((salary) => (
          <RB.Card.Body>
            <h6>Salary Information</h6>
            <hr className="hr-lg mt-1" />
            <RB.Row>
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  CTC
                </span>
                <p className="fw-medium text-sm fw-semibold">{salary.ctc}</p>
              </RB.Col>
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  variable Per
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {salary.variable_per}%
                </p>
              </RB.Col>
              {salary.variable_pay && (
                <RB.Col md={4}>
                  <span className="text-uppercase fw-bold small text-body-secondary">
                    variable Pay
                  </span>
                  <p className="fw-medium text-sm fw-semibold">
                    {salary.variable_pay}
                  </p>
                </RB.Col>
              )}
            </RB.Row>

            <h6>Payment Information</h6>
            <hr className="hr-lg mt-1" />
            <RB.Row>
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  Payment Frequency
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {salary.payment_frequency == 0 ? "Monthly" : "Bi-Monthly"}
                </p>
              </RB.Col>
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  Payment Mode
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {salary.payment_frequency == 0 ? "Direct Deposit" : "Check"}
                </p>
              </RB.Col>
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  Payment Date
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {salary.payment_date}
                </p>
              </RB.Col>
            </RB.Row>
            <RB.Row>
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  Deduction Reason
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {salary.deduction_reason}
                </p>
              </RB.Col>
            </RB.Row>
            <RB.Row>
              <RB.Table striped bordered hover variant="light">
                <thead>
                  <tr>
                    <th scope="col">Compensation Details</th>
                    <th scope="col">Monthly Salary (INR)</th>
                    <th scope="col">Yearly Salary (INR)</th>
                  </tr>
                </thead>
                <tbody>
                  {renderCompensationTable(empSalary.employee_salary[0])}
                </tbody>
              </RB.Table>
            </RB.Row>
          </RB.Card.Body>
        ))}
        {empSalary?.employee_salary?.length === 0 && (
          <RB.Card.Body>
            <RB.Row className="text-center">
              <div className="d-flex justify-content-center align-items-center text-center">
                <div className="me-2 fs-3">
                  <i className="material-icons-outlined">search_off</i>
                </div>
                <div className="fs-5">
                  <p className="mb-0">No salary details found.</p>
                </div>
              </div>
            </RB.Row>
          </RB.Card.Body>
        )}
      </RB.Card>
    </div>
  );
}

export default List;
