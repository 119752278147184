import React from "react";
import Dashboard from "../Pages/Dashboard";
import Abc from "../Pages/Abc";
import User from "../Pages/User";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Role from "./Role/Role";
import LeaveType from "./LeaveType/LeaveType";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Holiday from "../Pages/Holiday";
import LeaveBalance from "../Pages/LeaveBalance";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Department from "./Department/Department";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import Employee from "../Pages/Employee";
import Announcements from "../Pages/Announcement";
import CampaignIcon from "@mui/icons-material/Campaign";
import EmployeeSalary from "../Pages/EmployeeSalary";
import Coupon from "../Pages/Coupon";
import Attendances from "../Pages/Attendances";
import LeaveApply from "../Pages/LeaveApply";
import Leaves from "./Leaves/Leaves";
import Salary from "../Pages/Salary";
import SalaryHistoy from "../Pages/SalaryHistoy";
import SalaryView from "../Components/EmployeeSalaryView/SalaryView";
import Resignation from "../Pages/Resignation";
import Product from "../Pages/Product";
import Artisan from "../Pages/Artisan";
import CouponHistory from "../Pages/CouponHistory";
import Travel from "../Pages/Travel";
import CouponValue from "../Pages/CouponValue";

// ** FOR THE ICON
//https://www.npmjs.com/package/iconsax-react

import {
  Box,
  CalendarEdit,
  CalendarRemove,
  CalendarTick,
  Home,
  Home2,
  Money,
  Moneys,
  NotificationStatus,
  People,
  PercentageSquare,
  Profile,
  ReceiptEdit,
  Setting2,
  SmartCar,
  TicketDiscount,
  UserSquare,
  UserTag,
} from "iconsax-react";
import { Announcement } from "@mui/icons-material";
import Probation from "../Pages/Probation";
import Tds from "../Pages/Tds";
import ItSetup from "../Pages/ItSetup";
import ManagerHrApproval from "./Travel/ManagerHrApproval";
import RegularizeAttendance from "../Pages/RegularizeAttendance";
export const PrimaryMenu = [
  {
    title: "Dashboard",
    icon: <Home2 className="navicon" />,
    iconHover: <Home2 className="navicon-hover" variant="Bold" />,
    path: "/dashboard",
    cName: "nav-link",
    comp: <Dashboard />,
    showInMenu: true,
  },
  {
    title: "Employee Salary",
    icon: <Money className="navicon" />,
    iconHover: <Money className="navicon-hover" variant="Bold" />,
    path: "/employee-salary",
    cName: "nav-link",
    comp: <SalaryView />,
    showInMenu: true,
  },
  {
    title: "Attendance",
    icon: <CalendarTick className="navicon" />,
    iconHover: <CalendarTick className="navicon-hover" variant="Bold" />,
    path: "/attendances",
    cName: "nav-link",
    comp: <Attendances />,
    showInMenu: true,
  },
  {
    title: "Regularization",
    icon: <CalendarTick className="navicon" />,
    iconHover: <CalendarTick className="navicon-hover" variant="Bold" />,
    path: "/regularize-attendances",
    cName: "nav-link",
    comp: <RegularizeAttendance />,
    showInMenu: true,
  },
  {
    title: "Leave Request",
    icon: <CalendarRemove className="navicon" />,
    iconHover: <CalendarRemove className="navicon-hover" variant="Bold" />,
    path: "/leave-apply",
    cName: "nav-link",
    comp: <LeaveApply />,
    showInMenu: true,
  },
  {
    title: "Travel Management",
    icon: <SmartCar className="navicon" />,
    iconHover: <SmartCar className="navicon-hover" variant="Bold" />,
    path: "/travel",
    cName: "nav-link",
    comp: '',
    showInMenu: true,
    submenu: [
      {
        title: "Your Travel Request",
        path: "/travel/your-travel-req",
        cName: "nav-link",
        comp: <Travel />,
        icon: "",
      },
      {
        title: "Other Travel Request",
        path: "/travel/junior-travel-req",
        cName: "nav-link",
        comp: <ManagerHrApproval />,
        icon: "",
      },
    ],
  },

  {
    title: "Resignation",
    icon: <ReceiptEdit className="navicon" />,
    iconHover: <ReceiptEdit className="navicon-hover" variant="Bold" />,
    path: "/resignation",
    cName: "nav-link",
    comp: <Resignation />,
    showInMenu: true,
  },
  {
    title: "Tax & TDS",
    icon: <PercentageSquare className="navicon" />,
    iconHover: <PercentageSquare className="navicon-hover" variant="Bold" />,
    path: "/employee/tds",
    cName: "nav-link",
    comp: <Tds />,
    showInMenu: true,
  },
  {
    title: "User",
    icon: <UserSquare className="navicon" />,
    iconHover: <UserSquare className="navicon-hover" variant="Bold" />,
    path: "/user",
    cName: "nav-link",
    comp: <User />,
    showInMenu: true,
  },

  {
    title: "Employee",
    icon: <Profile className="navicon" />,
    iconHover: <Profile className="navicon-hover" variant="Bold" />,
    path: "/employee",
    cName: "nav-link",
    comp: <Employee />,
    showInMenu: true,
  },

  {
    title: "Probation Period",
    icon: <UserTag className="navicon" />,
    iconHover: <UserTag className="navicon-hover" variant="Bold" />,
    path: "/probation",
    cName: "nav-link",
    comp: <Probation />,
    showInMenu: true,
  },

  {
    title: "",
    icon: "",
    path: "/employee/salary",
    cName: "nav-link",
    comp: <EmployeeSalary />,
    showInMenu: false,
  },

  {
    title: "Department",
    icon: <People className="navicon" />,
    iconHover: <People className="navicon-hover" variant="Bold" />,
    path: "/department",
    cName: "nav-link",
    comp: <Department />,
    showInMenu: true,
  },
  // {
  //   title: "Role",
  //   icon: "",
  //   path: "/role",
  //   cName: "nav-link",
  //   comp: <Role />,
  // },

  {
    title: "Pay Slip",
    icon: "feed",
    path: "/xyz",
    cName: "nav-link",
    showInMenu: true,
  },
  {
    title: "Training",
    icon: "local_library",
    path: "/xyz",
    cName: "nav-link",
    showInMenu: true,
  },
  {
    title: "On-Boarding",
    icon: "person_add",
    path: "/xyz",
    cName: "nav-link",
    showInMenu: true,
  },
  // {
  //   title: "Tax & TDS Module",
  //   icon: "percent",
  //   path: "/xyz",
  //   cName: "nav-link",
  //   showInMenu: true,
  // },

  {
    title: "Announcement",
    icon: <NotificationStatus className="navicon" />,
    iconHover: <NotificationStatus className="navicon-hover" variant="Bold" />,
    path: "/announcement",
    comp: <Announcements />,
    cName: "nav-link",
    showInMenu: true,
  },
  {
    title: "Document",
    icon: "description",
    path: "/xyz",
    cName: "nav-link",
    showInMenu: true,
  },

  // {
  //   title: "Artisan",
  //   icon: "groups",
  //   path: "/artisan",
  //   comp: <Artisan />,
  //   cName: "nav-link",
  //   showInMenu: true,
  // },

  // {
  //   title: "Coupon",
  //   icon: "discount",
  //   path: "/coupon-code",
  //   comp: <Coupon />,
  //   cName: "nav-link",
  //   showInMenu: true,
  // },
  {
    title: "Coupon Management",
    icon: <TicketDiscount className="navicon" />,
    iconHover: <TicketDiscount className="navicon-hover" variant="Bold" />,
    path: "/coupon",
    cName: "nav-link has-dropdown",
    comp: "",
    showInMenu: true,
    submenu: [
      {
        title: "Artisan",
        path: "/coupon/artisan",
        cName: "nav-link",
        comp: <Artisan />,
        icon: "",
      },
      {
        title: "Coupon",
        path: "/coupon/coupon-code",
        cName: "nav-link",
        comp: <Coupon />,
        icon: "",
      },
      {
        title: "Coupon History",
        path: "/coupon/coupon-history",
        cName: "nav-link",
        comp: <CouponHistory />,
        icon: "",
      },
      // {
      //   title: "Coupon Value",
      //   path: "/coupon/coupon-value",
      //   cName: "nav-link",
      //   comp: <CouponValue />,
      //   icon: "",
      // },
    ],
  },
  {
    title: "Product",
    icon: <Box className="navicon" />,
    iconHover: <Box className="navicon-hover" variant="Bold" />,
    path: "/product",
    comp: <Product />,
    cName: "nav-link",
    showInMenu: true,
  },

  {
    title: "It Setup",
    icon: <Setting2 className="navicon" />,
    iconHover: <Setting2 className="navicon-hover" variant="Bold" />,
    path: "/it-setup",
    comp: <ItSetup />,
    cName: "nav-link",
    showInMenu: true,
  },

  {
    title: "Salary Management",
    icon: <Moneys className="navicon" />,
    iconHover: <Moneys className="navicon-hover" variant="Bold" />,
    path: "/salary",
    cName: "nav-link has-dropdown",
    comp: "",
    showInMenu: true,
    submenu: [
      {
        title: "Generate Salary",
        path: "/salary/employee-salary",
        cName: "nav-link",
        comp: <Salary />,
        icon: "",
      },
      {
        title: "Salary History",
        path: "/salary/salary-history",
        cName: "nav-link",
        comp: <SalaryHistoy />,
        icon: "",
      },
    ],
  },
  {
    title: "Leave Management",
    icon: <CalendarEdit className="navicon" />,
    iconHover: <CalendarEdit className="navicon-hover" variant="Bold" />,
    path: "/leave",
    cName: "nav-link has-dropdown",
    comp: "",
    showInMenu: true,
    submenu: [
      {
        title: "Leave Type",
        path: "/leave/leave-type",
        cName: "nav-link",
        comp: <LeaveType />,
        icon: "",
      },
      {
        title: "Holiday",
        path: "/leave/holiday",
        cName: "nav-link",
        comp: <Holiday />,
        icon: "",
      },
      {
        title: "Leave Balance",
        path: "/leave/leave-balance",
        cName: "nav-link",
        comp: <LeaveBalance />,
        icon: "",
      },

      {
        title: "Leaves",
        path: "/leave/leaves",
        cName: "nav-link",
        comp: <Leaves />,
        icon: "",
      },
    ],
  },
];

export default PrimaryMenu;
