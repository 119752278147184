import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import Button from "react-bootstrap/Button";
import * as RB from "react-bootstrap";
import avatarImg from "../assets/images/avatar-4.png";
import * as utils from "../Utils/comman";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { clockIn, clockOut } from "../reducers/userAttendanceSlice";
import { fetchUserProfile } from "../reducers/userProfileSlice";
import { CircularProgressbar } from "react-circular-progressbar";
const Dashboard = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state?.userProfile?.userProfile);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const clockStatus = useSelector((state) => state.clockStatus?.clockStatus);
  const checkTypeRedux = useSelector((state) => state.clockStatus?.checkType);
  const [currentTime, setCurrentTime] = useState("");
  const [announcement, setAnnouncement] = useState([]);
  const [leaveBalance, setLeaveBalance] = useState({});
  const currentYear = new Date().getFullYear();
  console.log(checkTypeRedux);
  const [checkType, setCheckType] = useState('local');

  const handleClick = () => {
    const dateTime = new Date().toLocaleString();

    if (clockStatus === "in") {
      dispatch(clockIn(checkType));
      submitAttendance("checkIn", dateTime);
    } else {
      dispatch(clockIn(checkType));
      submitAttendance("checkOut", dateTime);
    }
  };

  useEffect(() => {
    if (userProfile?.check_time === "checkIn") {
      // setClockStatus("out");
      dispatch(clockOut(userProfile?.check_type));
    } else {
      dispatch(clockIn(userProfile?.check_type));
    }
    if (checkTypeRedux) {
      setCheckType(checkTypeRedux);
    }
  }, [userProfile, checkTypeRedux]);
  const submitAttendance = (status, dateTime) => {
    var bodyFormData = new FormData();
    bodyFormData.append("userId", userProfile.id);
    bodyFormData.append("status", status);
    bodyFormData.append("check_type", checkType);
    bodyFormData.append("date", moment(dateTime).format("YYYY-MM-DD HH:mm:ss"));

    utils
      .callAPI("post", `/Attendance/add`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          dispatch(clockStatus === "in" ? clockOut() : clockIn());
          dispatch(fetchUserProfile());
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString("en-US", { hour12: true }));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getannouncement();
    getLeaveBalance();
  }, []);

  const getannouncement = () => {
    utils
      .callAPI("get", `/Announcement/view`)
      .then((res) => {
        if (typeof res !== "undefined") {
          if (Object.keys(res).length !== 0) {
            setAnnouncement(res);
          } else {
            setAnnouncement([]);
          }
        } else {
          setAnnouncement([]);
        }
      })
      .catch((err) => {
        setAnnouncement([]);
      });
  };

  const getLeaveBalance = () => {
    utils
      .callAPI("get", `/leave/summary?year=${currentYear}`)
      .then((res) => {
        if (typeof res !== "undefined") {
          if (Object.keys(res).length !== 0) {
            console.log(res);
            setLeaveBalance(res);
          } else {
            setLeaveBalance([]);
          }
        } else {
          setLeaveBalance([]);
        }
      })
      .catch((err) => {
        setLeaveBalance([]);
      });
  };

  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          {/* <div className="card position-relative card-hero">
            <div className="card-body">
              <div className="row justify-content-center justify-content-md-between align-items-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center text-center text-md-start">
                    <div className="flex-shrink-0 me-5">
                      <div className="avatar avatar-xl mb-3">
                        <img
                          src={
                            userProfile?.profilePic_path
                              ? userProfile?.profilePic_path
                              : avatarImg
                          }
                          alt="avatar"
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3 className="text-dark">{`Welcome back, ${userProfile?.firstName} ${userProfile?.lastName}`}</h3>
                      <div className="user-email d-flex  justify-content-center justify-content-md-start align-items-center text-body">
                        <i className="las la-envelope me-2"></i>
                        <span className="emailId fw-medium">
                          {userProfile?.email}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-badge position-absolute">
                <h6 className="profile-badge-text text-white mb-0">
                  {userProfile?.user_role?.[0].roleName}
                </h6>
              </div>
            </div>
          </div> */}
          <div className="section-body">
            <RB.Row className="mt-4">
              {utils.hasPermission(
                primaryMenuRes,
                "Dashboard",
                "dashboard.view"
              ) && (
                <RB.Col className="col-12 col-md-4 col-lg-4">
                  <RB.Card className="">
                    <RB.Card.Header>
                      <h4>Attendance</h4>
                      <div className="card-header-action">
                        <Link
                          className="btn btn-sm btn-light"
                          to="/attendances"
                        >
                          View All
                        </Link>
                      </div>
                    </RB.Card.Header>
                    <RB.Card.Body>
                      <div className="d-flex flex-column flex-xxl-row justify-content-between align-items-center">
                        <div>
                          <p className="current-time mb-0">CURRENT TIME</p>
                          <div className="time">
                            <span className="fs-2">
                              {currentTime.split(":").slice(0, 2).join(":")}
                            </span>
                            :
                            <span className="fs-6">
                              {currentTime.split(":").slice(2).join(":")}
                            </span>
                          </div>
                        </div>

                        <div className="text-center">
                          <Button
                            variant={clockStatus === "in" ? "dark" : "danger"}
                            onClick={handleClick}
                          >
                            {clockStatus === "in" ? "Clock-In" : "Clock-Out"}
                          </Button>
                          <div className="travel-clock mt-2">
                            <RB.Form>
                              <RB.Form.Group>
                                <RB.Form.Check
                                  inline
                                  label="Local"
                                  type="radio"
                                  id="local-radio"
                                  checked={checkType === "local"}
                                  onChange={() => setCheckType("local")}
                                  disabled={clockStatus === "out"}
                                />
                                <RB.Form.Check
                                  inline
                                  label="Travel"
                                  type="radio"
                                  id="travel-radio"
                                  checked={checkType === "travel"}
                                  onChange={() => setCheckType("travel")}
                                  disabled={clockStatus === "out"}
                                />
                              </RB.Form.Group>
                            </RB.Form>
                          </div>
                        </div>
                      </div>
                    </RB.Card.Body>
                  </RB.Card>
                  <RB.Card className="">
                    <RB.Card.Header>
                      <h4>Leave Balance</h4>
                    </RB.Card.Header>
                    <RB.Card.Body className="d-flex justify-content-center">
                      <RB.Row className="align-items-center justify-content-center justify-content-md-start">
                        {Object.values(leaveBalance).map((leaveType, index) => (
                          <RB.Col key={index} className="col-auto">
                            <RB.OverlayTrigger
                              placement="top"
                              overlay={
                                <RB.Tooltip id={`tooltip-${index}`}>
                                  Available Leave: {leaveType.remain_leave}
                                </RB.Tooltip>
                              }
                            >
                              <div className="levae-balance-progress">
                                <CircularProgressbar
                                  value={
                                    leaveType.assign_leave !== 0
                                      ? (leaveType.remain_leave /
                                          leaveType.assign_leave) *
                                        100
                                      : 0
                                  }
                                  text={`${leaveType.remain_leave}/${leaveType.assign_leave}`}
                                  styles={{
                                    path: { stroke: "#FFDE1B" },
                                    text: {
                                      fill: "black",
                                      // fontSize: "16px",
                                    },
                                  }}
                                />
                              </div>
                            </RB.OverlayTrigger>
                            <div className="mt-2">
                              <p className="mb-0">{leaveType.leave_name}</p>
                            </div>
                          </RB.Col>
                        ))}
                      </RB.Row>
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Col>
              )}
              {utils.hasPermission(
                primaryMenuRes,
                "Dashboard",
                "dashboard.announcement"
              ) && (
                <RB.Col className="col-12 col-md-8 col-lg-8">
                  <RB.Card className="">
                    <RB.Card.Header>
                      <h4>Announcements</h4>
                      <div className="card-header-action"></div>
                    </RB.Card.Header>

                    <RB.Card.Body className="mb-0">
                      <ul class="list-group list-group-flush">
                        {announcement.map((items) => (
                          <li class="list-group-item px-0 py-3 bg-transparent">
                            <div class="d-flex">
                              <div class="flex-shrink-0 me-3">
                                <div class="avatar avatar-md avatar-circle bg-light text-body-secondary">
                                  <i class="material-icons-outlined">
                                    record_voice_over
                                  </i>
                                </div>
                              </div>
                              <div class="flex-grow-1">
                                <div class="row justify-content-between">
                                  <div class="col-md-auto">
                                    <h6 className="card-header-text text-dark mb-1">
                                      {items.title}
                                    </h6>
                                  </div>
                                  <div class="col-md-auto">
                                    <p class="text-muted text-xs text-italic mb-1 comment-date">
                                      {" "}
                                      Announcement post on -{" "}
                                      {moment(items.createdAt).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <p
                                  className="pre-line mb-0"
                                  style={{ whiteSpace: "pre-line" }}
                                >
                                  {items.description}
                                </p>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                      {announcement.length == 0 && (
                        <RB.Row className="text-center">
                          <div className="d-flex justify-content-center align-items-center text-center">
                            <div className="me-2 fs-3">
                              <i className="material-icons-outlined">
                                campaign
                              </i>
                            </div>
                            <div className="fs-5">
                              <p className="mb-1">No announcement found.</p>
                            </div>
                          </div>
                        </RB.Row>
                      )}
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Col>
              )}
            </RB.Row>
            {/* <RB.Row>
              {utils.hasPermission(
                primaryMenuRes,
                "Dashboard",
                "dashboard.view"
              ) && (
                <>
                  <RB.Col className="col-12 col-md-4 col-lg-4">
                    <RB.Card className="">
                      <RB.Card.Header>
                        <h4>Leave Balance</h4>
                      </RB.Card.Header>
                      <RB.Card.Body className="d-flex justify-content-center">
                        <RB.Row className="align-items-center">
                          {Object.values(leaveBalance).map(
                            (leaveType, index) => (
                              <RB.Col key={index} className="col-auto me-3">
                                <RB.OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <RB.Tooltip id={`tooltip-${index}`}>
                                      Available Leave: {leaveType.remain_leave}
                                    </RB.Tooltip>
                                  }
                                >
                                  <div className="levae-balance-progress">
                                    <CircularProgressbar
                                      value={
                                        leaveType.assign_leave !== 0
                                          ? (leaveType.remain_leave /
                                              leaveType.assign_leave) *
                                            100
                                          : 0
                                      }
                                      text={`${leaveType.remain_leave}/${leaveType.assign_leave}`}
                                      styles={{
                                        path: { stroke: "#FFDE1B" },
                                        text: {
                                          fill: "black",
                                          // fontSize: "16px",
                                        },
                                      }}
                                    />
                                  </div>
                                </RB.OverlayTrigger>
                                <div className="mt-2">
                                  <p className="mb-0">{leaveType.leave_name}</p>
                                </div>
                              </RB.Col>
                            )
                          )}
                        </RB.Row>
                      </RB.Card.Body>
                    </RB.Card>
                  </RB.Col>
                </>
              )}
            </RB.Row> */}
          </div>
        </section>
      </div>
    </Layout>
  );
};
export default Dashboard;
