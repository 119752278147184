import {
  AddCardOutlined,
  AddCircle,
  AddCircleOutline,
  Delete,
  DeleteOutline,
} from "@mui/icons-material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as utils from "../../Utils/comman";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setEmpSalary } from "../../reducers/employeeSalarySlice";
import { setEmpTDS } from "./../../reducers/employeeTDSSlice";

function Add(props) {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state?.userProfile?.userProfile);
  const employeeTds = useSelector((state) => state?.empTDS?.employeeTDS);
  console.log("Bhai ka emp", employeeTds);

  console.log("id", userProfile?.id);

  const [isEdit, setisEdit] = useState(false);
  const [validated, setValidated] = useState(false);
  const [validateMobile, setValidateMobile] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [dynamicFields, setDynamicFields] = useState([
    { title: "", amount: "" },
  ]);
  const [formData, setFormData] = useState({
    houseRentAllowance: "",
    interestHousingLoanBefore1999: "",
    interestHousingLoanAfter1999: "",
    interestHousingLoanLetOut: "",
    lifeInsurancePremium: "",
    providentFund: "",
    publicProvidentFund: "",
    voluntaryProvidentFund: "",
    nationalSavingsCertificate: "",
    interestNSC: "",
    unitLinkedInsurancePolicy: "",
    equityLinkedSavingsScheme: "",
    tuitionFees: "",
    principalRepayment: "",
    stampDuty: "",
    infrastructureBonds: "",
    bankFixedDeposit: "",
    postOfficeTermDeposit: "",
    seniorCitizenSavingScheme: "",
    sukanyaSamriddhi: "",
    others80C: "",
    contributionPensionFunds: "",
    contributionPensionScheme: "",
    contributionNPS: "",
    medicalInsuranceIndividual: "",
    medicalInsuranceParents: "",
    preventiveHealthCheckup: "",
    interestEducationLoan: "",
    interestSavingAccount: "",
    others80: "",
    place: "",
  });
  const [taxScheme, setTaxScheme] = useState("new");

  const fieldData = [
    {
      section: "Exemption u/s 10",
      fields: [
        {
          name: "houseRentAllowance",
          label: "House Rent Allowance",
          particulars:
            "I am staying in a Rented House and I agree to submit Rent receipts or Rent Agreement with the Landlord when required. Rent Paid is ___x____ Months.",
          eligibility: "Metro/Non-Metro",
          placeholder: "Enter amount",
        },
      ],
    },
    {
      section: "Deduction u/s 24",
      fields: [
        {
          name: "interestHousingLoanBefore1999",
          label: "1",
          particulars:
            "Interest on Housing Loan u/s 24 (if the house is Self-Occupied and the loan was taken before 1st April 1999) Bankers Certificate to be Submitted",
          eligibility: (
            <>
              Upto Rs. 30,000/-
              <br />
              (If Self-Occupied)
            </>
          ),
          placeholder: "Enter amount",
        },
        {
          name: "interestHousingLoanAfter1999",
          label: "2",
          particulars:
            "Interest on Housing Loan u/s 24 (if the house is Self-Occupied and the loan was taken after 1st April 1999) Bankers Certificate to be Submitted",
          eligibility: (
            <>
              Upto Rs. 200,000/-
              <br />
              (If Self-Occupied)
            </>
          ),
          placeholder: "Enter amount",
        },
        {
          name: "interestHousingLoanLetOut",
          label: "3",
          particulars: (
            <>
              Interest on Housing Loan u/s 24 (Let-out/Deemed to be Let-out)
              <br />
              (if the property is LET-OUT Rental income need to be specified)
            </>
          ),

          eligibility: "",
          placeholder: "Enter amount",
        },
      ],
    },
    {
      section: "Deduction u/s 80C (Maximum Amount Upto Rs. 150000)",
      fields: [
        {
          name: "lifeInsurancePremium",
          label: "1",
          particulars: "Life Insurance Premium (LIC)",
          eligibility: "",
          placeholder: "Enter amount",
        },
        {
          name: "providentFund",
          label: "2",
          particulars: "Provident Fund (PF)",
          eligibility: "",
          placeholder: "Enter amount",
        },
        {
          name: "publicProvidentFund",
          label: "3",
          particulars: "Public Provident Fund (PPF)",
          eligibility: "",
          placeholder: "Enter amount",
        },
        {
          name: "voluntaryProvidentFund",
          label: "4",
          particulars: "Voluntary Provident Fund (VPF)",
          eligibility: "",
          placeholder: "Enter amount",
        },
        {
          name: "nationalSavingsCertificate",
          label: "5",
          particulars: "National Savings Certificate (NSC)",
          eligibility: "",
          placeholder: "Enter amount",
        },
        {
          name: "interestNSC",
          label: "6",
          particulars: "Interest accrued on NSC (Re-invested)",
          eligibility: "",
          placeholder: "Enter amount",
        },
        {
          name: "unitLinkedInsurancePolicy",
          label: "7",
          particulars: "Unit Linked Insurance Policy (ULIP)",
          eligibility: "",
          placeholder: "Enter amount",
        },
        {
          name: "equityLinkedSavingsScheme",
          label: "8",
          particulars: "Equity Linked Savings Scheme (ELSS) Mutual Fund",
          eligibility: "",
          placeholder: "Enter amount",
        },
        {
          name: "tuitionFees",
          label: "9",
          particulars:
            "Payment for Tuition Fees for Children (Max. 2 Children)",
          eligibility: "",
          placeholder: "Enter amount",
        },
        {
          name: "principalRepayment",
          label: "10",
          particulars: "Principal Repayment of Housing Loan",
          eligibility: "",
          placeholder: "Enter amount",
        },
        {
          name: "stampDuty",
          label: "11",
          particulars:
            "Stamp Duty Registration charges for Buying House (1st Year Only)",
          eligibility: "",
          placeholder: "Enter amount",
        },
        {
          name: "infrastructureBonds",
          label: "12",
          particulars: "Infrastructure Bonds",
          eligibility: "",
          placeholder: "Enter amount",
        },
        {
          name: "bankFixedDeposit",
          label: "13",
          particulars: "Bank Fixed Deposit for 5 Years & Above",
          eligibility: "",
          placeholder: "Enter amount",
        },
        {
          name: "postOfficeTermDeposit",
          label: "14",
          particulars: "Post Office Term Deposit For 5 Years & Above",
          eligibility: "",
          placeholder: "Enter amount",
        },
        {
          name: "seniorCitizenSavingScheme",
          label: "15",
          particulars: "Senior Citizen Saving Scheme",
          eligibility: "",
          placeholder: "Enter amount",
        },
        {
          name: "sukanyaSamriddhi",
          label: "16",
          particulars: "Sukanya Samriddhi Account Scheme",
          eligibility: "",
          placeholder: "Enter amount",
        },
        {
          name: "others80C",
          label: "17",
          particulars: "Others (Please Specify)",
          eligibility: "",
          placeholder: "Enter amount",
        },
      ],
    },
    {
      section: "Deduction u/s 80CCC",
      fields: [
        {
          name: "contributionPensionFunds",
          label: "Contribution to Certain Pension Funds",
          particulars: "",
          eligibility: "Upto Rs. 150,000/-",
          placeholder: "Enter amount",
        },
      ],
    },
    {
      section: "Deduction u/s 80CCD(1)",
      fields: [
        {
          name: "contributionPensionScheme",
          label: "Contribution to Pension Scheme of Central Government",
          particulars: "",
          eligibility: "Upto Rs. 150,000/-",
          placeholder: "Enter amount",
        },
      ],
    },
    {
      section: "Deduction u/s 80CCD(1B)",
      fields: [
        {
          name: "contributionNPS",
          label: "Contribution to National Pension Scheme",
          particulars: "",
          eligibility: "Upto Rs. 50,000/-",
          placeholder: "Enter amount",
        },
      ],
    },
    {
      section: "Deduction u/s 80",
      fields: [
        {
          name: "medicalInsuranceIndividual",
          label: "80 D",
          particulars:
            "Medical Insurance Premium for Individual, Spouse & Children",
          eligibility:
            "(Max Limit – Rs. 25,000/- Additional Rs. 25,000, if any person insured is a Senior Citizen)",
          placeholder: "Enter amount",
        },
        {
          name: "medicalInsuranceParents",
          label: "80 D",
          particulars: "Medical Insurance Premium for Parents",
          eligibility:
            "(Max Limit – Rs. 25,000/- Additional Rs. 25,000, if any person insured is a Senior Citizen)",
          placeholder: "Enter amount",
        },
        {
          name: "preventiveHealthCheckup",
          label: "80 D",
          particulars: "Preventive Health Check-up",
          eligibility:
            "Max. Rs. 5,000/- (Within overall limit of Rs. 25,000/50,000)",
          placeholder: "Enter amount",
        },
        {
          name: "interestEducationLoan",
          label: "80 E",
          particulars: "Interest for Loan taken for Higher Education",
          eligibility: "No Limit (Only Interest Portion)",
          placeholder: "Enter amount",
        },
        {
          name: "interestSavingAccount",
          label: "80 TTA",
          particulars:
            "Deduction in respect of interest on deposits in saving account",
          eligibility: "Upto Rs. 10,000/-",
          placeholder: "Enter amount",
        },
        {
          name: "others80",
          label: "Others (Please Specify)",
          particulars: "",
          eligibility: "",
          placeholder: "Enter amount",
        },
      ],
    },
  ];

  const handleDynamicFieldChange = (index, e) => {
    const { name, value } = e.target;

    // Filter out non-numeric characters for the 'amount' field
    const filteredValue =
      name === "amount" ? value.replace(/[^0-9.]/g, "") : value;

    const updatedFields = dynamicFields.map((field, i) =>
      i === index ? { ...field, [name]: filteredValue } : field
    );
    setDynamicFields(updatedFields);
  };

  const handleAddField = () => {
    setDynamicFields([...dynamicFields, { title: "", amount: "" }]);
  };

  const handleRemoveField = (index) => {
    const updatedFields = dynamicFields.filter((_, i) => i !== index);
    setDynamicFields(updatedFields);
  };

  useEffect(() => {
    const interestBefore1999 =
      parseFloat(formData?.interestHousingLoanBefore1999) || 0;
    const interestAfter1999 =
      parseFloat(formData?.interestHousingLoanAfter1999) || 0;

    const contributionPensionFunds =
      parseFloat(formData?.contributionPensionFunds) || 0;
    const contributionPensionScheme =
      parseFloat(formData?.contributionPensionScheme) || 0;

    const contributionNPS = parseFloat(formData?.contributionNPS) || 0;
    const tt80A = parseFloat(formData?.interestSavingAccount) || 0;

    if (isSubmit) {
      console.log("how api got called");
      var bodyFormData = new FormData();

      bodyFormData.append(
        "employeeId",
        userProfile?.user_role[0]?.roleName === "HR & Admin staff" ||
          userProfile?.user_role[0]?.roleName === "Super Admin"
          ? employeeTds?.employeeId
          : userProfile?.employeeId
      );
      if (isEdit) {
        bodyFormData.append("tdsid", employeeTds?.id);
      }

      bodyFormData.append(
        "verifyDocumentsStatus",
        employeeTds?.verifyDocumentsStatus || isEdit ? "0" : "1"
      );
      bodyFormData.append("tax_scheme", taxScheme);

      bodyFormData.append(
        "house_rent_allowance",
        formData.houseRentAllowance || "0"
      );

      bodyFormData.append(
        "deduction_24_a",
        formData.interestHousingLoanBefore1999 || "0"
      );

      bodyFormData.append(
        "deduction_24_b",
        formData.interestHousingLoanAfter1999 || "0"
      );
      bodyFormData.append(
        "deduction_24_c",
        formData.interestHousingLoanLetOut || "0"
      );
      bodyFormData.append("lic", formData.lifeInsurancePremium || "0");
      bodyFormData.append("pf", formData.providentFund || "0");
      bodyFormData.append("ppf", formData.publicProvidentFund || "0");
      bodyFormData.append("vpf", formData.voluntaryProvidentFund || "0");
      bodyFormData.append("nsc", formData.nationalSavingsCertificate || "0");
      bodyFormData.append("re_invested", formData.interestNSC || "0");
      bodyFormData.append("ulip", formData.unitLinkedInsurancePolicy || "0");
      bodyFormData.append("elss", formData.equityLinkedSavingsScheme || "0");
      bodyFormData.append("tution_fees", formData.tuitionFees || "0");
      bodyFormData.append(
        "housing_loan_repayment",
        formData.principalRepayment || "0"
      );
      bodyFormData.append(
        "stamp_duty_registration_charge",
        formData.stampDuty || "0"
      );
      bodyFormData.append(
        "infrastructure_bond",
        formData.infrastructureBonds || "0"
      );
      bodyFormData.append("bank_fd", formData.bankFixedDeposit || "0");
      bodyFormData.append(
        "post_term_deposit",
        formData.postOfficeTermDeposit || "0"
      );
      bodyFormData.append(
        "senior_citizen_saving",
        formData.seniorCitizenSavingScheme || "0"
      );
      bodyFormData.append(
        "sukanya_samriddhi_account",
        formData.sukanyaSamriddhi || "0"
      );
      bodyFormData.append("other_deduction", formData.others80C || "0");
      // bodyFormData.append(
      //   "deduction_80ccc",
      //   contributionPensionFunds > 150000
      //     ? "150000"
      //     : formData.contributionPensionFunds
      // );

      bodyFormData.append(
        "deduction_80ccc",
        formData.contributionPensionFunds || "0"
      );
      // bodyFormData.append(
      //   "deduction_80ccd_A",
      //   contributionPensionScheme > 150000
      //     ? "150000"
      //     : formData.contributionPensionScheme
      // );
      bodyFormData.append(
        "deduction_80ccd_A",
        formData.contributionPensionScheme || "0"
      );
      // bodyFormData.append(
      //   "deduction_80ccd_B",
      //   contributionNPS > "50000" ? "50000" : formData.contributionNPS
      // );
      bodyFormData.append("deduction_80ccd_B", formData.contributionNPS || "0");
      bodyFormData.append(
        "deduction_80_d_a",
        formData.medicalInsuranceIndividual || "0"
      );
      bodyFormData.append(
        "deduction_80_d_b",
        formData.medicalInsuranceParents || "0"
      );
      //  bodyFormData.append("other_income", "");
      bodyFormData.append(
        "deduction_80_d_c",
        formData.preventiveHealthCheckup || "0"
      );
      bodyFormData.append(
        "deduction_80_d_e",
        formData.interestEducationLoan || "0"
      );
      // bodyFormData.append(
      //   "deduction_80_d_tta",
      //   tt80A > 10000 ? "10000" : formData.interestSavingAccount
      // );
      bodyFormData.append(
        "deduction_80_d_tta",
        formData.interestSavingAccount || "0"
      );
      bodyFormData.append("deduction_80_d_other", formData.others80 || "0");
      bodyFormData.append("place", formData.place);

      dynamicFields.forEach((field, index) => {
        bodyFormData.append(
          `other_income_title[${index + 1}]`,
          field.title || ""
        );
        bodyFormData.append(
          `other_income_amount[${index + 1}]`,
          field.amount || "0"
        );
      });

      utils
        .callAPI("post", `/employee/save_tds`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            dispatch(setEmpTDS(res));
            setIsSubmit(false);
            setisEdit(false);
            const propdata = {
              mode: isEdit ? "view" : "list",
            };
            props.onChange(propdata);
          } else {
            setIsSubmit(false);
          }
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  useEffect(() => {
    if (employeeTds && Object.keys(employeeTds).length > 0) {
      console.log("Redux Employee TDS data", employeeTds);
      setisEdit(true);
      setTaxScheme(employeeTds.tax_scheme || "new");
      if (employeeTds?.other_income != "")
        setDynamicFields((prevDynamicFields) => {
          // Map employeeTds.other_income to create an array of objects
          const updatedFields =
            employeeTds?.other_income.map((other_income) => ({
              title: other_income.title,
              amount: other_income.amount,
            })) || [];
          return updatedFields;
        });
      setFormData({
        ...formData,
        houseRentAllowance: employeeTds.house_rent_allowance || "",
        interestHousingLoanBefore1999: employeeTds.deduction_24_a || "",
        interestHousingLoanAfter1999: employeeTds.deduction_24_b || "",
        interestHousingLoanLetOut: employeeTds.deduction_24_c || "",
        lifeInsurancePremium: employeeTds.lic || "",
        providentFund: employeeTds.pf || "",
        publicProvidentFund: employeeTds.ppf || "",
        voluntaryProvidentFund: employeeTds.vpf || "",
        nationalSavingsCertificate: employeeTds.nsc || "",
        interestNSC: employeeTds.re_invested || "",
        unitLinkedInsurancePolicy: employeeTds.ulip || "",
        equityLinkedSavingsScheme: employeeTds.elss || "",
        tuitionFees: employeeTds.tution_fees || "",
        principalRepayment: employeeTds.housing_loan_repayment || "",
        stampDuty: employeeTds.stamp_duty_registration_charge || "",
        infrastructureBonds: employeeTds.infrastructure_bond || "",
        bankFixedDeposit: employeeTds.bank_fd || "",
        postOfficeTermDeposit: employeeTds.post_term_deposit || "",
        seniorCitizenSavingScheme: employeeTds.senior_citizen_saving || "",
        sukanyaSamriddhi: employeeTds.sukanya_samriddhi_account || "",
        others80C: employeeTds.other_deduction || "",
        contributionPensionFunds: employeeTds.deduction_80ccc || "",
        contributionPensionScheme: employeeTds.deduction_80ccd_A || "",
        contributionNPS: employeeTds.deduction_80ccd_B || "",
        medicalInsuranceIndividual: employeeTds.deduction_80_d_a || "",
        medicalInsuranceParents: employeeTds.deduction_80_d_b || "",
        preventiveHealthCheckup: employeeTds.deduction_80_d_c || "",
        interestEducationLoan: employeeTds.deduction_80_d_e || "",
        interestSavingAccount: employeeTds.deduction_80_d_tta || "",
        others80: employeeTds.deduction_80_d_other || "",
        place: employeeTds.place || "",
      });
    } else {
      setisEdit(false);
    }
  }, [employeeTds]);

  const handleBackClick = () => {
    const propdata = {
      mode: isEdit ? "view" : "list",
    };
    props.onChange(propdata);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      if (validateMobile) {
        return;
      }
      setIsSubmit(true);
      // Submit formData here
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const regex = /^[0-9]*$/; // Only numbers
    let updatedValue = value; // Initialize updatedValue with the entered value

    if (regex.test(value)) {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handlePlaceChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTaxSchemeChange = (e) => {
    setTaxScheme(e.target.value);
  };

  return (
    <div className="section-body">
      <RB.Card className="card shadow">
        <RB.Card.Header className="border-0">
          <h4>{isEdit ? "Edit" : "Add"}</h4>

          <div className="card-header-action">
            <RB.Button
              className="btn btn-outline-dark"
              onClick={handleBackClick}
            >
              Back
            </RB.Button>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
            {/* <RB.Row>
              <h6>
                Please submit self attested investment declarations on or before
                31st May 2023
              </h6>
            </RB.Row> */}

            <RB.Row>
              <h6>
                Select the Tax Scheme to be availed for Financial Year 2023-24:
              </h6>
            </RB.Row>
            <RB.Row>
              <RB.Form.Group controlId="formTaxScheme" className="mb-3">
                <RB.Form.Check
                  type="radio"
                  name="taxScheme"
                  label="New Tax Scheme"
                  value="new"
                  checked={taxScheme === "new"}
                  onChange={handleTaxSchemeChange}
                />
                <RB.Form.Check
                  type="radio"
                  name="taxScheme"
                  label="Old Tax Scheme"
                  value="old"
                  checked={taxScheme === "old"}
                  onChange={handleTaxSchemeChange}
                />
              </RB.Form.Group>
            </RB.Row>
            <p>
              Note: All the tax reliefs and deductions provided under the Income
              Tax Act, 1961 can be availed under the Old Tax Scheme Only.
              Employees opting for Old Tax Scheme is required to fill the below
              investment declaration form. Default Tax Regime is New Tax Scheme.
            </p>
            <p>
              {" "}
              I hereby declare that the following investment will be made by me
              during the financial year 2023-24 starting from 1st of April 2023
              to 31st of March 2024
            </p>
            <div className="table-responsive">
              <RB.Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Item Name</th>
                    <th>Particulars</th>
                    <th>Eligibility (Rs.)</th>
                    <th>Amount (INR)</th>
                  </tr>
                </thead>

                <tbody>
                  {fieldData.map((section) => (
                    <React.Fragment key={section.section}>
                      <tr>
                        <td colSpan={4}>{section.section}</td>
                      </tr>
                      {section.fields.map((field) => (
                        <tr key={field.name}>
                          <td className="col-2">{field.label}</td>
                          <td className="col-4">{field.particulars}</td>
                          <td className="col-2">{field.eligibility}</td>
                          <td className="col-4">
                            <RB.Form.Control
                              type="text"
                              name={field.name}
                              value={formData[field.name] || ""}
                              onChange={handleInputChange}
                              placeholder={field.placeholder}
                            />
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </RB.Table>
            </div>
            <h6>Income from other Sources:-</h6>
            <RB.Form.Group
              as={RB.Row}
              className="mb-3"
              controlId="formHorizontalEmail"
            >
              {dynamicFields.map((field, index) => (
                <RB.Row key={index}>
                  <RB.Col md={5}>
                    <div className="mb-3">
                      <RB.FloatingLabel
                        label="Other Income"
                        className="floating-label-fancy"
                      >
                        <RB.Form.Control
                          type="text"
                          placeholder="Enter Title"
                          name="title"
                          value={field.title}
                          onChange={(e) => handleDynamicFieldChange(index, e)}
                        />
                      </RB.FloatingLabel>
                    </div>
                  </RB.Col>
                  <RB.Col md={5}>
                    <div className="mb-3">
                      <RB.FloatingLabel
                        label="Amount"
                        className="floating-label-fancy"
                      >
                        <RB.Form.Control
                          type="text"
                          placeholder="Enter Amount"
                          name="amount"
                          value={field.amount}
                          onChange={(e) => handleDynamicFieldChange(index, e)}
                        />
                      </RB.FloatingLabel>
                    </div>
                  </RB.Col>

                  {dynamicFields.length - 1 === index ? (
                    <RB.Col md={2} className="d-flex align-items-end mb-3">
                      <RB.Button variant="outline-dark">
                        <AddCircleOutline onClick={handleAddField} />
                      </RB.Button>
                    </RB.Col>
                  ) : (
                    <RB.Col md={2} className="d-flex align-items-end mb-3">
                      <RB.Button variant="outline-danger">
                        <DeleteOutline
                          onClick={() => handleRemoveField(index)}
                        />
                      </RB.Button>
                    </RB.Col>
                  )}
                </RB.Row>
              ))}
            </RB.Form.Group>

            <h6>Declaration:-</h6>
            <p>
              I{" "}
              {userProfile?.user_role[0]?.roleName !== "HR & Admin staff" &&
              userProfile?.user_role[0]?.roleName !== "Super Admin"
                ? userProfile?.firstName + " " + userProfile?.lastName
                : employeeTds.emp_firstName +
                  " " +
                  employeeTds.emp_lastName}{" "}
              hereby declare that the information given above is correct and
              true in all respects. I also undertake to indemnify the firm for
              any loss/liability may arise in the event of the above information
              being incorrect.
            </p>
            <RB.Form.Group
              as={RB.Row}
              className="mb-3 align-items-end"
              controlId="formHorizontalEmail"
            >
              {/* <RB.Form.Label column sm={4} className="mb-3 pb-0">
                Date
              </RB.Form.Label> */}
              <RB.Col className="mb-3" sm={2}>
                <RB.Form.Label className="floating-label-fancy">
                  Date
                </RB.Form.Label>
                <span className="d-block lh-1">
                  {new Date().toLocaleDateString()}
                </span>
              </RB.Col>

              <RB.Col className="mb-3" md={5}>
                <div>
                  <RB.FloatingLabel
                    label="Place"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Amount"
                      name="place"
                      value={formData.place}
                      onChange={handlePlaceChange}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Form.Group>

            <RB.Row className="justify-content-end mt-3">
              <RB.Col className="col-auto">
                <RB.Button variant="primary" type="submit">
                  Submit
                </RB.Button>
              </RB.Col>
            </RB.Row>
          </RB.Form>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default Add;
