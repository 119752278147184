import React, { useState, useEffect } from "react";
import * as RB from "react-bootstrap";
import * as utils from "../Utils/comman";
import { useNavigate } from "react-router-dom";
import Logo from "../Components/Logo";
import loginImg from "../assets/images/login-img.png";
import OTPInput, { ResendOTP } from "otp-input-react";

function ArtisanLogin() {
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState(sessionStorage.getItem("access_token"));
  const navigate = useNavigate();
  let year = new Date().getFullYear();
  const [mobileNo, setMoiileNo] = useState("");
  const [otp, setOtp] = useState("");
  //   const [error, setError] = useState(false);
  const [showOtp, setshowotp] = useState(false);
  const [error, setError] = useState("");

  const obj = {
    width: "30%",
  };

  const handleResend = () => {
    const bodyFormData = new FormData();
    bodyFormData.append("mobileNo", mobileNo);
    utils
      .callAPI("post", "/Client/login", bodyFormData)
      .then((res) => {
        if (res !== undefined) {
          setshowotp(true);
        } else {
        }
      })
      .catch((err) => {});
  };

  const handleOtpSend = () => {};
  const handleMobileOtp = () => {
    if (!mobileNo) {
      setError("Please enter a valid mobile number.");
      return false;
    } else {
      setError("");
    }
    const bodyFormData = new FormData();
    bodyFormData.append("mobileNo", mobileNo);
    utils
      .callAPI("post", "/Client/login", bodyFormData)
      .then((res) => {
        if (res !== undefined) {
          setshowotp(true);
        } else {
        }
      })
      .catch((err) => {});
  };

  const handleChange = (e) => {
    const userInput = e.target.value;
    const trimmedInput = userInput.slice(0, 10);
    const isValid = /^\d+$/.test(trimmedInput);

    if (isValid) {
      setMoiileNo(trimmedInput);
      setError("");
    } else {
      setError("Please enter a valid mobile number.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (otp) {
      setIsSubmit(true);
      // onSubmit();
    }
  };

  useEffect(() => {
    if (isSubmit) {
      const bodyFormData = new FormData();
      bodyFormData.append("mobileNo", mobileNo);
      bodyFormData.append("otp", otp);
      utils
        .callAPI("post", "/Client/check_otp", bodyFormData)
        .then((res) => {
          if (res !== undefined) {
            sessionStorage.setItem("artisan_token", res.access_token);
            navigate("/artisan/delete");
            setIsSubmit(false);
          } else {
            setIsSubmit(false);
          }
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
    // if(isSubmit){
    //   navigate("/dashboard");
    // }
  }, [isSubmit]);

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <>
      <section className="login-wrap min-vh-100 d-flex justify-content-center align-items-center">
        <RB.Container className="mt-5">
          <RB.Row className="row justify-content-center justify-content-lg-between align-items-center">
            <RB.Col md={10} lg={6} xl={7} className="order-2 order-lg-1">
              <div className="logo-block d-none d-lg-flex align-items-center mb-4">
                <Logo />
              </div>
              <h2 className="text-dark mb-4">
                A Futuristic Brand Conceived And Nurtured With The Experience Of
                120 Years.{" "}
                {/* A FUTURISTIC BRAND CONCEIVED AND NURTURED WITH THE EXPERIENCE OF
                  120 YEARS. */}
              </h2>
              <p className="text-md mt-3">
                Vura Bau-Chemie LLP is a global brand that manufactures
                adhesives, sealants, waterproofing, repairs, maintenance &
                building products which are formulated with world-class German
                polymers & technology. It has developed a comprehensive product
                range based on international standards to facilitate the global
                construction chemical manufacturer and building materials
                market.
                {/* The brains behind the product development have a
                  combined experience of more than 120 years in the same industry.
                  With this abundance of experience, the company is ready to build
                  better! */}
              </p>
              <div className="login-img">
                <RB.Image
                  src={loginImg}
                  alt="Vura login"
                  className="img-fluid"
                />
              </div>
            </RB.Col>
            <RB.Col
              md={10}
              lg={6}
              xl={5}
              className="order-1 order-lg-2 mb-3 mb-md-0"
            >
              <div className="logo-block d-flex d-lg-none my-4 justify-content-center">
                <Logo />
              </div>
              <RB.Card className="login-card p-5 rounded-4 shadow-lg border-0">
                <RB.CardHeader className="border-0">
                  <h2 className="text-dark mb-3 text-2xl">
                    Welcome,
                    <br />
                    Please Login Here to Delete Your Account
                  </h2>
                </RB.CardHeader>
                <RB.CardBody>
                  <RB.Form
                    onSubmit={handleSubmit}
                    noValidate
                    validated={validated}
                  >
                    <div className="mb-4">
                      <RB.FloatingLabel
                        controlId="floatingPhoneNumber"
                        label="Phone Number"
                        className="floating-label-fancy artisan-logn"
                      >
                        <RB.Form.Control
                          type="text"
                          placeholder="Phone Number"
                          name="phoneNumber"
                          onChange={handleChange}
                          value={mobileNo}
                        />
                        {error && <div className="text-danger">{error}</div>}
                        <i
                          className="material-icons-outlined ms-2  position-absolute end-0 translate-middle-y icon-password-eye"
                          style={{ cursor: "pointer" }}
                          onClick={handleMobileOtp}
                        >
                          send_to_mobile
                        </i>
                      </RB.FloatingLabel>
                    </div>

                    {showOtp && (
                      <>
                        <div className="mb-3">
                          <div className="form-group mt-3">
                            <OTPInput
                              value={otp}
                              onChange={setOtp}
                              //   inputClassName={` ${error ? "border-danger" : ""}`}
                              autoFocus
                              OTPLength={6}
                              otpType="number"
                              className="d-flex justify-content-between otp-container mt-3"
                              //   inputStyles={obj}
                              disabled={false}
                              secure
                            />
                          </div>
                          <div className="form-group me-3">
                            <div class="d-flex justify-content-end">
                              <ResendOTP
                                onResendClick={handleResend}
                                renderButton={otpResendButton}
                                renderTime={renderTime}
                                maxTime={60}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-grid gap-2">
                          <RB.Button
                            type="submit"
                            variant="primary"
                            disabled={otp.length !== 6}
                          >
                            Login
                          </RB.Button>
                        </div>
                      </>
                    )}
                  </RB.Form>
                </RB.CardBody>
              </RB.Card>
            </RB.Col>
          </RB.Row>
          <RB.Row className="row justify-content-between align-items-center">
            <RB.Col
              md={12}
              lg={6}
              xl={7}
              className="order-2 order-lg-1"
            ></RB.Col>
            <RB.Col md={12} lg={6} xl={5} className="order-2 order-lg-1">
              <div className="simple-footer">
                Copyright &copy; {process.env.REACT_APP_TITLE} {year}
              </div>
            </RB.Col>
          </RB.Row>
        </RB.Container>
      </section>
    </>
  );
}

const otpResendButton = (buttonProps) => {
  return (
    <>
      {buttonProps.remainingTime == 0 && (
        <a
          className="link-primary"
          style={{ cursor: "pointer" }}
          {...buttonProps}
        >
          Resend
        </a>
      )}
    </>
  );
};

const renderTime = (remainingTime) => {
  return <>{remainingTime !== 0 && <span>{remainingTime} sec</span>}</>;
};

export default ArtisanLogin;
