import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";

import {
  BorderStyle,
  Delete,
  Download,
  Replay,
  StarBorderPurple500,
  Visibility,
} from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import excelSvg from "../../assets/images/excel.svg";
import { debounce } from "lodash";

function TransactionDetail(props) {
  const { rowData } = props;
  const [loading, setLoading] = useState(true);

  const [couponCode, setCouponCode] = useState([]);

  const [activeTab, setActiveTab] = useState("Initiate");

  const [csvData, setCsvData] = useState([]);
  const [page, setPage] = useState(1);
  const [filterDate, setFilterDate] = useState("");
  const [selectable, setSelectable] = useState(false);
  const [filterCoupon, setFilterCoupon] = useState("");
  const [filterProduct, setFilterProduct] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startError, setStartError] = useState(false);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [active, setActive] = useState(false);
  const csvLink = createRef();
  const [selectedRows, setSelectedRows] = React.useState(false);

  const debouncedGetCouponList = useCallback(
    debounce(() => {
      getCouponList();
    }, 500), // Adjust the delay time as needed (e.g., 500 milliseconds)
    [] // Empty dependency array to ensure the function is created only once
  );

  const handleChange = ({ selectedRows }) => {
    if (activeTab === "all") {
      setSelectedRows(selectedRows.map((row) => row.coupon_batch));
    } else {
      setSelectedRows(selectedRows.map((row) => row.id));
    }
  };
  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  const baseColumns = [
    {
      name: "Sr.No",
      selector: (row, index) => (page - 1) * countPerPage + index + 1,
      sortable: false,
      maxWidth: "80px", // Adjust the maximum width as needed
      minWidth: "60px",
    },
    {
      name: <div>Request Date</div>,
      selector: (row) => moment(row.requestDate).format("DD-MM-yyyy HH:mm:ss"),
      maxWidth: "150px", // Adjust the maximum width as needed
      minWidth: "150px",
    },
    { name: "Account No", selector: (row) => row.account_no },
    { name: "IFSC Code", selector: (row) => row.ifsc_code },
    {
      name: <div>Account Holder Name</div>,
      selector: (row) => row.account_holder_name,
    },
  ];

  const columnsByTab = {
    Initiate: [
      ...baseColumns,
      { name: "Unique Id", selector: (row) => row.unique_id },
    ],
    Pending: [
      ...baseColumns,
      { name: "Response", selector: (row) => row.response },
      //  { name: "Message", selector: (row) => row.message },
    ],
    Succeed: [
      ...baseColumns,
      { name: "Unique Id", selector: (row) => row?.unique_id },
      { name: "UTR Number", selector: (row) => row?.utr_no },
      { name: "Response", selector: (row) => row?.response },
    ],
    Failed: [
      ...baseColumns,
      { name: "Unique Id", selector: (row) => row?.unique_id },
      { name: "UTR Number", selector: (row) => row?.utr_no },
      // { name: "NEFT Status", selector: (row) => row?.neftStatus },
      // { name: "Response", selector: (row) => row?.response },
      //  { name: "Message", selector: (row) => row?.message },
    ],
  };

  const columns = useMemo(() => columnsByTab[activeTab], [activeTab]);

  const handleStartDate = (date) => {
    setStartDate(date);
    setStartError(false);
  };

  useEffect(() => {
    setCouponCode([]);
    getCouponList();
  }, [page, activeTab]);

  const getCouponList = () => {
    setLoading(true);
    let endpoint = "";
    //URL-{{base_url}}/wallet/transaction-list?client_id
    endpoint = `/wallet/transaction-list?client_id=${
      rowData.id ? rowData.id : rowData.clientId
      //rowData.id ? rowData.id : rowData.clientId ? rowData.clientId : ""
    }&search=${activeTab}`;

    utils
      .callAPI(
        "get",
        `${endpoint}&date=${
          startDate ? moment(startDate).format("YYYY-MM-DD") : ""
        }&page=${page}&per_page=${countPerPage}`
      )
      .then((res) => {
        setLoading(false);
        if (typeof res !== "undefined") {
          setCouponCode(res);
        } else {
          setCouponCode([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setCouponCode([]);
      });
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setPage(1); // Reset page number when switching tabs
  };

  // useEffect(() => {
  //   getScannedCoupon();
  // }, [scanned, available, page]);

  // const getScannedCoupon = () => {
  //   utils
  //     .callAPI(
  //       "get",
  //       `/Coupon/listByCouponType?available=${available}&scanned=${scanned}from_date=${
  //         startDate ? moment(startDate).format("YYYY-MM-DD") : ""
  //       }&to_date=${
  //         endDate ? moment(endDate).format("YYYY-MM-DD") : ""
  //       }&start_date=${
  //         filterDate ? moment(filterDate).format("YYYY-MM-DD") : ""
  //       }&coupon_code=${filterCoupon ? filterCoupon : ""}&product_name=${
  //         filterProduct ? filterProduct : ""
  //       }&page=${page}&per_page=${countPerPage}`
  //     )
  //     .then((res) => {
  //       if (typeof res !== "undefined") {
  //         console.log("Coupon data", res);
  //         setCouponCode(res);
  //       } else {
  //         setCouponCode([]);
  //       }
  //     })
  //     .catch((err) => {
  //       setCouponCode([]);
  //     });
  // };

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const showDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let apiEndpoint = "";
        if (activeTab === "all") {
          apiEndpoint = `/Coupon/delete?id=${row.id}`;
        } else {
          apiEndpoint = `/Coupon/bulk-delete?coupon_batch=${row.coupon_batch}`;
        }

        utils
          .callAPI("get", apiEndpoint)
          .then((res) => {
            if (typeof res !== "undefined") {
              getCouponList();
            }
          })
          .catch((err) => {});
      }
    });
  });

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleDownloadBatch = (row) => {
    setCsvData([]);

    utils
      .callAPI("get", `Coupon/export?coupon_batch=${row.coupon_batch}`)
      .then((res) => {
        if (res && Object.keys(res).length > 0) {
          setCsvData(res);
          setActive(true);
        } else {
          setCsvData([]);
        }
      })
      .catch((err) => {
        setCsvData([]);
      });
  };

  function handleDownload() {
    setCsvData([]);

    utils
      .callAPI(
        "get",
        `Coupon/export?start_date=${
          filterDate ? moment(filterDate).format("YYYY-MM-DD") : ""
        }&coupon_code=${filterCoupon}&product_name=${filterProduct}`
      )
      .then((res) => {
        if (res && Object.keys(res).length > 0) {
          setCsvData(res);
          setActive(true);
        } else {
          setCsvData([]);
        }
      })
      .catch((err) => {
        setCsvData([]);
      });
  }

  const handleDate = (date) => {
    setFilterDate(date);
  };

  const handleSearch = () => {
    getCouponList();
  };

  const handleResetDate = () => {
    setStartDate(null);
    setEndDate(null);

    debouncedGetCouponList();
  };

  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [csvData]);

  const handleMultipleDelete = () => {
    setSelectable(!selectable);
  };

  const handleEndDateChange = (date) => {
    if (!startDate) {
      setStartError(true);
      return;
    }
    setEndDate(date);
  };

  const handleDeleteSelectedRows = () => {
    if (selectedRows.length === 0) {
      return;
    }

    const formattedIds = selectedRows.join(",");

    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete them!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("post", `/Coupon/bulk-delete?id=${formattedIds}`)
          .then((res) => {
            if (typeof res !== "undefined") {
              // Remove deleted rows from the table
              //  const updatedCouponCode = couponCode.filter(
              //    (row) => !idsToDelete.includes(row.id)
              //  );
              //  setCouponCode(updatedCouponCode);
              // Clear selected rows
              setSelectedRows([]);

              // Optionally, refresh the table data
              getCouponList();
            }
          })
          .catch((err) => {});
      }
    });
  };

  const handleFilter = () => {
    getCouponList();
  };

  const handleBackClick = useCallback((row) => async () => {
    const propdata = {
      mode: "view",
      rowData: row,
    };
    props.onChange(propdata);
  });
  return (
    <div className="section-body">
      {console.log("Render method couponCode:", couponCode)}
      <RB.Card className="card shadow">
        <RB.Card.Header className="border-0">
          <h4>{rowData ? rowData.name : "List"}</h4>
          <div className="card-header-action">
            <RB.Button
              className="btn btn-outline-dark"
              onClick={handleBackClick(rowData)}
            >
              Back
            </RB.Button>
          </div>
        </RB.Card.Header>
        <div className="col-8 px-4 pt-3">
          {activeTab !== "scanned" ? (
            <div className="row align-items-center mt-1 mb-3" text-center>
              <div className="col-md-9">
                <div className="row g-3 align-items-center">
                  <div className="col-md">
                    <DatePicker
                      selected={startDate}
                      onChange={handleStartDate}
                      selectsStart
                      // startDate={startDate}
                      // endDate={endDate}
                      dateFormat="dd-MM-yyyy"
                      className={`form-control range-datepicker-input ${
                        startError ? "date-border-danger" : ""
                      }`}
                      placeholderText="Select Date"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearch();
                        }
                      }}
                      // maxDate={new Date()}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <RB.Button
                  variant="primary"
                  className=" btn-md"
                  onClick={handleFilter}
                  disabled={!startDate}
                >
                  Find
                </RB.Button>
                <Link
                  className="ms-2 btn-link text-dark"
                  //variant="secondary"
                  onClick={handleResetDate}
                >
                  Reset
                </Link>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <RB.Card.Header>
          <RB.Row>
            <RB.Col md={12}>
              <RB.Nav variant="tabs" defaultActiveKey="link-0">
                <RB.Nav.Item>
                  <RB.Nav.Link
                    eventKey="link-0"
                    onClick={() => handleTabClick("Initiate")}
                  >
                    Initiate
                  </RB.Nav.Link>
                </RB.Nav.Item>
                <RB.Nav.Item>
                  <RB.Nav.Link
                    eventKey="link-1"
                    onClick={() => handleTabClick("Pending")}
                  >
                    Pending
                  </RB.Nav.Link>
                </RB.Nav.Item>
                <RB.Nav.Item>
                  <RB.Nav.Link
                    eventKey="link-2"
                    onClick={() => handleTabClick("Succeed")}
                  >
                    Succeed
                  </RB.Nav.Link>
                </RB.Nav.Item>
                <RB.Nav.Item>
                  <RB.Nav.Link
                    eventKey="link-3"
                    onClick={() => handleTabClick("Failed")}
                  >
                    Failed
                  </RB.Nav.Link>
                </RB.Nav.Item>
              </RB.Nav>
            </RB.Col>

            <div className="card-header-action">
              <div className="floating-btns-holder">
                <a
                  href
                  className="floating-btn"
                  variant="primary"
                  onClick={() => handleDownload()}
                >
                  <img src={excelSvg} />
                </a>
                <CSVLink data={csvData} ref={csvLink} />

                <a
                  href
                  className="floating-btn btn-dark"
                  variant="dark"
                  onClick={handleAddClick}
                >
                  <span className="material-icons-outlined">add</span>
                </a>
              </div>

              {/* <CSVLink data={csvData} ref={csvLink} target="_blank" /> */}
            </div>
          </RB.Row>
        </RB.Card.Header>
        <RB.Card.Body>
          {/* <div className="deleteOptions">
            {selectable ? (
              <>
                <RB.Button
                  className=" btn-md me-2"
                  variant="light"
                  onClick={handleMultipleDelete}
                >
                  Cancel
                </RB.Button>
                {selectedRows.length > 0 && (
                  <RB.Button
                    className="btn-md"
                    variant="danger"
                    onClick={handleDeleteSelectedRows}
                  >
                    Delete Selected
                  </RB.Button>
                )}
              </>
            ) : (
              <RB.Button
                className=" btn-md"
                variant="light"
                onClick={handleMultipleDelete}
              >
                Delete Multiple
              </RB.Button>
            )}
          </div> */}
          <DataTable
            columns={columns}
            className="table-stripped-columns"
            data={couponCode.result}
            highlightOnHover
            responsive
            pagination
            paginationServer
            selectableRows={selectable}
            paginationTotalRows={couponCode.total}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            striped
            progressPending={loading}
            onChangePage={(page) => setPage(page)}
            onSelectedRowsChange={handleChange}
            persistTableHead
            customStyles={{
              head: {
                style: {
                  fontSize: "0.875rem", // Adjust the font size as needed
                  fontWeight: "bold", // Adjust the font weight as needed
                },
              },

              // rows: {
              //   style: {
              //     backgroundColor: "#f7f7f7", // Default background color for all rows
              //   },
              // },
            }}
            // customRowStyles={[
            //   {
            //     when: (row, index) => index % 2 !== 0, // Apply style to odd rows
            //     style: {
            //       backgroundColor: "#ffffff", // Background color for odd rows
            //     },
            //   },
            // ]}
            // subHeader
            // subHeaderComponent={subHeaderComponent}
          />
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default TransactionDetail;
