import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, Visibility } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import excelSvg from "../../assets/images/excel.svg";

function List(props) {
  const [loading, setLoading] = useState(true);
  const [ClientList, setClientList] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [filterName, setFilterName] = useState("");

  const [filterMobileNo, setFilterMobileNo] = useState("");
  const [filterState, setFilterState] = useState("");
  const [filterCity, setFilterCity] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterReset, setFilterReset] = useState(false);
  const [empList, setEmpList] = useState([]);
  const getFormattedMonthYear = (date) => {
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${month}-${year}`;
  };

  const [selectedMonthYear, setSelectedMonthYear] = useState(() => {
    const currentDate = new Date();
    return getFormattedMonthYear(currentDate);
  });

  const [selectedEmployee, setSelectedEmployee] = useState("");
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const [active, setActive] = useState(false);
  const csvLink = createRef();

  const showDelete = useCallback((row) => async () => {
    console.log(row.id);
    Swal.fire({
      title: "Delete Artisan",
      text: "Once deleted, your data will be permanently removed and cannot be recovered. Are you sure you want to delete?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `Client/delete?id=${row.id}`)
          .then((res) => {
            if (typeof res !== "undefined") {
              getArtisianList();
            }
          })
          .catch((err) => {});
      }
    });
  });

  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [csvData]);

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };
  const columns = [
    {
      name: "Sr.No",
      selector: (row, index) => (page - 1) * countPerPage + index + 1,
      sortable: false,
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-yyyy"),
    },
    {
      name: "Name",
      selector: (row) => row.name,
      // style: {
      //   color: "blue", // Set text color to blue
      // },
    },
    {
      name: "Mobile no",
      selector: (row) => row.mobileNo,
    },
    {
      name: "Address",
      selector: (row) => row?.address,
    },
    {
      name: "State",
      selector: (row) => row?.state,
    },
    {
      name: "City",
      selector: (row) => row?.city,
    },
    {
      name: (
        <div>
          Wallet Amount <br /> Transfered
        </div>
      ),
      selector: (row) => row?.otp,
    },

    {
      name: "Status",
      cell: (row) => (
        <select
          //  className="form-select form-select-sm"
          value={row.status}
          onChange={(e) => handleStatusChange(row, e.target.value)}
        >
          <option value="Active">Active</option>
          <option value="Block">Block</option>
          <option value="Hold">Hold</option>
        </select>
      ),
    },

    {
      name: "View",
      cell: (row) => (
        <>
          <RB.Button
            className="btn-circle"
            variant="outline-primary"
            size="sm"
            onClick={handleView(row)}
          >
            <Visibility />
          </RB.Button>
        </>
      ),
      center: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <>
          <RB.Button
            variant="outline-primary"
            size="sm"
            onClick={handelEdit(row)}
          >
            <EditIcon />
          </RB.Button>{" "}
          &nbsp;
          <RB.Button
            variant="outline-danger"
            size="sm"
            onClick={showDelete(row)}
          >
            <Delete />
          </RB.Button>
        </>
      ),
      center: true,
    },
  ];

  const handleView = useCallback((row) => async () => {
    const propdata = {
      mode: "view",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const handleSearch = () => {
    getArtisianList();
  };

  useEffect(() => {
    getArtisianList();
    // getEmployeeList();
  }, [page]);

  const getArtisianList = () => {
    setLoading(true);
    utils
      .callAPI(
        "get",
        `Client/list-all?date=${
          filterDate ? moment(filterDate).format("YYYY-MM-DD") : ""
        }&name=${filterName ? filterName : ""}&mobile_no=${
          filterMobileNo ? filterMobileNo : ""
        }&state=${filterState ? filterState : ""}&city=${
          filterCity ? filterCity : ""
        }&page=${page}&per_page=${countPerPage}`
      )
      .then((res) => {
        setLoading(false);
        if (typeof res !== "undefined") {
          setClientList(res);
          setFilterReset(false);
        } else {
          setClientList([]);
          setFilterReset(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setClientList([]);
        setFilterReset(false);
      });
  };

  const handleStatusChange = (row, value) => {
    // Handle status change logic here
    var bodyFormData = new FormData();

    bodyFormData.append("client_id", row.id);
    bodyFormData.append("status", value);

    utils.callAPI("post", `Client/change-status`, bodyFormData).then((res) => {
      if (typeof res !== "undefined") {
        console.log("", res);
        const updatedClientList = [...ClientList.result];
        const updatedClientIndex = updatedClientList.findIndex(
          (item) => item.id === row.id
        );

        if (updatedClientIndex > -1) {
          updatedClientList[updatedClientIndex] = { ...row, status: value };

          setClientList({ ...ClientList, result: updatedClientList });
        }
      } else {
      }
    });

    console.log("Status changed to:", value, row.id);
  };

  const handleDate = (date) => {
    setFilterDate(date);
  };

  function handleDownload() {
    console.log("Handle Download Clicked");
    setCsvData([]);

    utils
      .callAPI(
        "get",
        `Client/export?date=${filterDate}&name=${filterName}&mobile_no=${filterMobileNo}&state=${filterState}&city=${filterCity}`
      )
      .then((res) => {
        if (res && Object.keys(res).length > 0) {
          setCsvData(res);
          setActive(true);
        } else {
          setCsvData([]);
        }
      })
      .catch((err) => {
        setCsvData([]);
      });
  }

  const handleReset = () => {
    getArtisianList();
    setFilterDate("");
    setFilterName("");
    setFilterMobileNo("");
    setFilterState("");
    setFilterCity("");
  };

  return (
    <div className="section-body">
      <RB.Card className="card">
        <div className="floating-btns-holder">
          <a
            href
            className="floating-btn"
            variant="primary"
            onClick={
              // Prevent default behavior of anchor tag
              () => handleDownload() // Call your download function
            }
          >
            <img src={excelSvg} />
          </a>
          <CSVLink data={csvData} ref={csvLink} target="_blank" />
          <a
            href
            className="floating-btn btn-dark"
            variant="dark"
            onClick={handleAddClick}
          >
            <span className="material-icons-outlined">add</span>
          </a>
        </div>

        <RB.Card.Body>
          <div className="row g-2 mb-3">
            <div className="col-md-2">
              {/* <input
                type="text"
                className="form-control"
                placeholder="Date"
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)}
              /> */}

              <DatePicker
                onChange={handleDate}
                value={filterDate}
                selected={filterDate ? new Date(filterDate) : null}
                selectsEnd
                dateFormat="dd-MM-yyyy"
                className="form-control  range-datepicker-input-artisan"
                placeholderText="Date"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
            </div>
            <div className="col-md">
              <input
                type="text"
                className="form-control "
                placeholder="Name"
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                maxLength={32}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
            </div>
            <div className="col-md">
              <input
                type="text"
                className="form-control "
                placeholder="Mobile No"
                value={filterMobileNo}
                onChange={(e) => {
                  const cleanedValue = e.target.value.replace(/\D/g, "");
                  setFilterMobileNo(cleanedValue);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                maxLength={10}
              />
            </div>
            <div className="col-md">
              <input
                type="text"
                className="form-control"
                placeholder="State"
                value={filterState}
                onChange={(e) => setFilterState(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                maxLength={32}
              />
            </div>
            <div className="col-md">
              <input
                type="text"
                className="form-control "
                placeholder="City"
                value={filterCity}
                onChange={(e) => setFilterCity(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                maxLength={32}
              />
            </div>
            <div className="col-md">
              <RB.Button
                className=" btn-md"
                variant="primary"
                onClick={handleSearch}
                disabled={
                  !filterCity &&
                  !filterDate &&
                  !filterMobileNo &&
                  !filterName &&
                  !filterState
                }
              >
                Find
              </RB.Button>
              <Link
                className="ms-2 btn-link text-dark"
                // variant="secondary"
                onClick={handleReset}
              >
                Reset
              </Link>
            </div>
          </div>
          {/* <RB.Row className="justify-content-between">
            <RB.Col md="auto">
              <div className="d-flex justify-content-start" text-center>
                <RB.Form.Select
                  aria-label="Default select example"
                  className="me-2"
                  value={selectedEmployee}
                  onChange={(e) => setSelectedEmployee(e.target.value)}
                >
                  <option>Select an employee...</option>
                  {empList?.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {`${employee.firstName} ${employee.lastName}`}
                    </option>
                  ))}
                </RB.Form.Select>
                <RB.Form.Select
                  aria-label="Default select example"
                  className="me-2"
                  value={selectedMonthYear}
                  onChange={(e) => setSelectedMonthYear(e.target.value)}
                >
                  <option value="">Select a month-year...</option>
                  {months}
                </RB.Form.Select>
                <RB.Button
                  className="ms-2"
                  variant="primary"
                  onClick={handleFilter}
                >
                  Find
                </RB.Button>
                <RB.Button
                  className="ms-2"
                  variant="secondary"
                  onClick={handleReset}
                >
                  Reset
                </RB.Button>
              </div>
            </RB.Col>
            <RB.Col md="auto">
              {" "}
              <div className=" justify-content-end" text-center>
                <RB.Button
                  className="ms-2"
                  variant="primary"
                  onClick={() => handleDownload()}
                >
                  Download
                </RB.Button>

                <CSVLink data={csvData} ref={csvLink} target="_blank" />
              </div>
            </RB.Col>
          </RB.Row> */}
          <DataTable
            columns={columns}
            data={ClientList.result}
            highlightOnHover
            responsive
            striped
            pagination
            paginationServer
            paginationTotalRows={ClientList.total}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            progressPending={loading}
            onChangePage={(page) => setPage(page)}
            persistTableHead
            customStyles={{
              head: {
                style: {
                  fontSize: "0.875rem", // Adjust the font size as needed
                  fontWeight: "bold", // Adjust the font weight as needed
                },
              },
            }}
            // subHeader
            // subHeaderComponent={subHeaderComponent}
          />
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default List;
