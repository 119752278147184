import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import {
  AccountBalanceWalletOutlined,
  Delete,
  East,
  EastOutlined,
  Explore,
  MoreHoriz,
} from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Send, Send2, TickCircle } from "iconsax-react";

function List(props) {
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterText, setFilterText] = useState("");
  const [showExtendModal, setShowExtendModal] = useState(false);
  const [extendProbationPeriod, setExtendProbationPeriod] = useState("");
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  const handleComplete = (row) => {
    var bodyFormData = new FormData();

    bodyFormData.append("employeeId", row.id);
    bodyFormData.append("probation_status", "completed");
    bodyFormData.append("probation_period", "");

    utils
      .callAPI("post", "/employee/probation-status", bodyFormData)
      .then((res) => {
        getUserList();
      });
  };

  const handleExtend = (row) => {
    setCurrentEmployee(row);
    setShowExtendModal(true);
  };

  const handleExtendSubmit = () => {
    var bodyFormData = new FormData();

    console.log("extend", extendProbationPeriod);
    bodyFormData.append("employeeId", currentEmployee.id);
    bodyFormData.append("probation_status", "extend");
    bodyFormData.append("probation_period", extendProbationPeriod + "-month");

    utils
      .callAPI("post", "/employee/probation-status", bodyFormData)
      .then((res) => {
        setShowExtendModal(false);
        setExtendProbationPeriod("");
        getUserList();
      });
  };

  const columns = [
    {
      name: "EMP ID",
      selector: (row) => row.employeeId,
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
    },
    {
      name: "Joining Date",
      selector: (row) => row.join_date,
    },

    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          <RB.OverlayTrigger
            trigger="click"
            rootClose
            key="bottom"
            placement="bottom"
            overlay={
              <RB.Popover id={`popover-positioned-bottom`}>
                <RB.Popover.Body className="p-0">
                  <div className="list-group">
                    <Link
                      className="list-group-item list-group-item-action"
                      onClick={() => handleComplete(row)}
                    >
                      <TickCircle /> Completed
                    </Link>
                    <Link
                      className="list-group-item list-group-item-action"
                      onClick={() => handleExtend(row)}
                    >
                      <Send /> Extend
                      {/* variant="Bold" */}
                    </Link>{" "}
                    {/* <Link
                    //   className="list-group-item list-group-item-action"
                    //   onClick={(e) => {
                    //     e.preventDefault();
                    //     handleEmpLeave(row)();
                    //   }}
                    // >
                    //   <EventNote /> Leave Balance
                    // </Link> */}
                    {/* {utils.hasPermission(
                      primaryMenuRes,
                      "Attendance",
                      "attendance.viewAll"
                    ) && (
                      <Link
                        className="list-group-item list-group-item-action"
                        onClick={(e) => {
                          e.preventDefault();
                          handleEmpAttendance(row)();
                        }}
                      >
                        <EventAvailable /> Employee Attendances
                      </Link>
                    )} */}
                  </div>
                </RB.Popover.Body>
              </RB.Popover>
            }
          >
            <RB.Button
              className="btn-circle"
              variant="outline-secondary"
              size="sm"
            >
              <MoreHoriz />
            </RB.Button>
          </RB.OverlayTrigger>

          {/* <RB.Button
              className="btn-circle ms-2"
              variant="outline-danger"
              size="sm"
              onClick={showDelete(row)}
              title="Delete"
            >
              <Delete />
            </RB.Button> */}
        </>
      ),

      omit:
        !utils.hasPermission(primaryMenuRes, "Employee", "employee.edit") &&
        !utils.hasPermission(primaryMenuRes, "Employee", "employee.delete"),
    },
  ];

  useEffect(() => {
    getUserList();
  }, [page]);

  const getUserList = () => {
    utils
      .callAPI("get", `/employee/probation-period-list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setUserList(res);
          console.log("Userlist api response", res);
        } else {
          setUserList([]);
        }
      })
      .catch((err) => {
        setUserList([]);
      });
  };

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let validatedValue = value;
    setExtendProbationPeriod(validatedValue);
  };

  const showDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `/users/users-delete?id=${row.id}`, "")
          .then((res) => {
            if (typeof res !== "undefined") {
              getUserList();
            }
          })
          .catch((err) => {});
      }
    });
  });

  const paginatedData = useMemo(() => {
    if (!Array.isArray(userList)) {
      return [];
    }

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    return userList?.slice(startIndex, endIndex);
  }, [userList, currentPage, rowsPerPage]);

  return (
    <div className="section-body">
      <RB.Card className="card shadow ">
        {/* <RB.Card.Header>
          <h4></h4>
          <div className="card-header-action">
            {utils.hasPermission(primaryMenuRes, "User", "user.create") && (
              <RB.Button
                className="btn btn-primary me-2"
                onClick={handleAddClick}
              >
                Add User
              </RB.Button>
            )}
          </div>
        </RB.Card.Header> */}
        <RB.Card.Body>
          <DataTable
            columns={columns}
            data={paginatedData}
            highlightOnHover
            responsive
            pagination
            paginationServer
            paginationTotalRows={userList?.length}
            paginationPerPage={rowsPerPage}
            striped
            persistTableHead
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            customStyles={{
              head: {
                style: {
                  fontSize: "0.875rem", // Adjust the font size as needed
                  fontWeight: "bold", // Adjust the font weight as needed
                },
              },
            }}
            onChangePage={(page) => setCurrentPage(page)}
          />
        </RB.Card.Body>
      </RB.Card>

      <RB.Modal
        show={showExtendModal}
        onHide={() => {
          setExtendProbationPeriod("");
          setShowExtendModal(false);
        }}
      >
        <RB.Modal.Header closeButton>
          <RB.Modal.Title>Extend Probation Period</RB.Modal.Title>
        </RB.Modal.Header>
        <RB.Modal.Body>
          <RB.Form>
            <RB.InputGroup>
              <RB.FloatingLabel
                label="Probation Period"
                className="floating-label-fancy"
              >
                <RB.Form.Control
                  type="text"
                  // placeholder="Enter Probation Period"
                  name="probation_period"
                  inputMode="numeric"
                  value={extendProbationPeriod}
                  onChange={handleInputChange}
                  onInput={(e) => {
                    let value = e.target.value.replace(/[^0-9]/g, "");

                    if (value !== "") {
                      const numericValue = parseInt(value, 10);
                      if (numericValue < 1) {
                        value = "1";
                      } else if (numericValue > 12) {
                        value = "12";
                      } else {
                        value = numericValue.toString();
                      }
                    }
                    e.target.value = value;
                  }}
                  required
                />
              </RB.FloatingLabel>
              <RB.InputGroup.Text
                id="months"
                className="border border-top-0 border-end-0"
              >
                Month
              </RB.InputGroup.Text>
            </RB.InputGroup>
          </RB.Form>
        </RB.Modal.Body>
        <RB.Modal.Footer>
          <RB.Button
            variant="secondary"
            onClick={() => {
              setExtendProbationPeriod("");
              setShowExtendModal(false);
            }}
          >
            Close
          </RB.Button>
          <RB.Button variant="primary" onClick={handleExtendSubmit}>
            Save Changes
          </RB.Button>
        </RB.Modal.Footer>
      </RB.Modal>
    </div>
  );
}

export default List;
