import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import {
  AccountBalanceWalletOutlined,
  Delete,
  EventAvailable,
  EventNote,
  ExpandMore,
  MoreHoriz,
} from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setEmpSalary } from "../../reducers/employeeSalarySlice";
import { useSelector } from "react-redux";
import useCommandRRedirect from "../../hooks/useCommandRRedirect";
import { fetchUserProfile } from "../../reducers/userProfileSlice";
import { setEmpTDS } from "./../../reducers/employeeTDSSlice";

function List(props) {
  const userProfile = useSelector((state) => state?.userProfile?.userProfile);
  const employeeTds = useSelector((state) => state?.empTDS?.employeeTDS);

  const dispatch = useDispatch();
  const navigte = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const id = queryParams.get("id");
  const [empList, setEmpList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [isCurrentFinancialYearPresent, setIsCurrentFinancialYearPresent] =
    useState("true");

  useEffect(() => {
    // Function to check if the current financial year is present
    const checkCurrentFinancialYearPresence = () => {
      const currentYear = new Date().getFullYear().toString(); // Get the current year

      // Check if any item in the empList array has the current financial year
      const isPresent = empList.some((item) =>
        item.FinancialYear.includes(currentYear)
      );

      // Update the state variable based on the presence of the current financial year
      setIsCurrentFinancialYearPresent(isPresent);
    };

    checkCurrentFinancialYearPresence(); // Call the function initially
  }, [empList]);
  // useCommandRRedirect("/dashboard");

  useEffect(() => {
    if (!userProfile) {
      dispatch(fetchUserProfile());
    }
  }, [userProfile]);

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  const handleView = useCallback((row) => async () => {
    console.log("Handle view ka rowdata", row);
    dispatch(setEmpTDS(row));
    const propdata = {
      mode: "view",
      rowData: employeeTds,
    };
    props.onChange(propdata);
  });
  const columns = [
    {
      name: "Sr.No",
      selector: (row, index) => (page - 1) * countPerPage + index + 1,
      sortable: false,
    },

    {
      name: "Year",
      selector: (row) => row.FinancialYear,
    },

    {
      name: "View",
      cell: (row) => (
        <>
          <RB.Button
            className="btn-circle"
            variant="outline-primary"
            size="sm"
            onClick={handleView(row)}
          >
            <Visibility />
          </RB.Button>
        </>
      ),
      center: true,
    },

    // {
    //   name: "Action",
    //   center: true,
    //   cell: (row) => (
    //     <>
    //       <RB.Button
    //         className="btn-circle"
    //         variant="outline-primary"
    //         size="sm"
    //         onClick={handelEdit(row)}
    //       >
    //         <EditIcon />
    //       </RB.Button>
    //       &nbsp;
    //     </>
    //   ),
    // },
  ];

  // if (
  //   userProfile?.user_role[0]?.roleName === "HR & Admin staff" ||
  //   userProfile?.user_role[0]?.roleName === "Super Admin"
  // ) {
  //   const actionColumnIndex = columns.findIndex(
  //     (column) => column.name === "Action"
  //   );
  //   if (actionColumnIndex !== -1) {
  //     columns.splice(actionColumnIndex, 1);
  //   }
  // }

  useEffect(() => {
    getEmployeeList();
  }, [userProfile?.employeeId]);

  const getEmployeeList = () => {
    var bodyFormData = new FormData();

    if (
      userProfile?.user_role[0]?.roleName === "HR & Admin staff" ||
      userProfile?.user_role[0]?.roleName === "Super Admin"
    ) {
      bodyFormData.append("employeeId", id);
    } else {
      bodyFormData.append("employeeId", userProfile?.employeeId);
    }

    bodyFormData.append("tdsId", "");
    utils
      .callAPI("post", `/employee/getAllTDSList`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          setEmpList(res.slice(0, 5));
        } else {
          setEmpList([]);
        }
      })
      .catch((err) => {
        setEmpList([]);
      });
  };

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const handleSalary = useCallback((row) => async () => {
    dispatch(setEmpSalary(row));

    navigte(`/employee/salary?id=${row.employeeId}`);
  });

  const handleTds = useCallback((row) => async () => {
    //dispatch(setEmpSalary(row));

    navigte(`/employee/tds?id=${row.employeeId}`);
  });

  const handleEmpLeave = useCallback((row) => async () => {
    navigte(`/leave/leave-balance?id=${row.userId}`);
  });

  const handleEmpAttendance = useCallback((row) => async () => {
    navigte(`/attendances?id=${row.id}`);
  });

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="section-body">
      <RB.Card className="card shadow ">
        <RB.Card.Header className="border-0">
          <h4></h4>
          <div className="card-header-action">
            {userProfile?.user_role[0]?.roleName !== "HR & Admin staff" &&
              userProfile?.user_role[0]?.roleName !== "Super Admin" &&
              !isCurrentFinancialYearPresent && (
                <RB.Button
                  className="btn btn-primary me-2"
                  onClick={handleAddClick}
                >
                  Add Tax & TDS
                </RB.Button>
              )}
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <DataTable
            columns={columns}
            data={empList}
            highlightOnHover
            responsive
            striped
            persistTableHead
            customStyles={{
              head: {
                style: {
                  fontSize: "0.875rem", // Adjust the font size as needed
                  fontWeight: "bold", // Adjust the font weight as needed
                },
              },
            }}
            //subHeader
            // subHeaderComponent={subHeaderComponent}
          />
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default List;
