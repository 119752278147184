import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, Visibility } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";

function List(props) {
  const [SalaryHistoy, setSalaryHistoy] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterReset, setFilterReset] = useState(false);
  const [empList, setEmpList] = useState([]);
  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = getFormattedMonth(currentDate);
    const currentYear = currentDate.getFullYear();

    setSelectedMonth(currentMonth);
    setSelectedYear(currentYear.toString());
  }, []);

  const getFormattedMonth = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[date.getMonth()];
  };
  const getFormattedMonthYear = (date) => {
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${month}-${year}`;
  };

  const [selectedMonthYear, setSelectedMonthYear] = useState(() => {
    const currentDate = new Date();
    return getFormattedMonthYear(currentDate);
  });

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const [selectedEmployee, setSelectedEmployee] = useState("");
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const [active, setActive] = useState(false);
  const csvLink = createRef();

  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [csvData]);

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };
  const columns = [
    {
      name: "First Name",
      selector: (row) => row.firstName,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Total salary",
      selector: (row) => row?.employee_salary?.net_salary,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          {row?.employee_salary && (
            <RB.Button
              className="btn-circle"
              variant="outline-primary"
              size="sm"
              onClick={handleView(row)}
            >
              <Visibility />
            </RB.Button>
          )}
        </>
      ),
    },
  ];

  const handleView = useCallback((row) => async () => {
    const propdata = {
      mode: "view",
      rowData: row,
    };
    props.onChange(propdata);
  });

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      getSalaryHistoy();
    }
  }, [page, selectedMonth, selectedYear]);
  useEffect(() => {
    getEmployeeList();
  }, []);
  const getSalaryHistoy = () => {
    utils
      .callAPI(
        "get",
        `/salary/payslip/list?month_year=${selectedMonth}-${selectedYear}&employeeId=${selectedEmployee}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setSalaryHistoy(res);
          setFilterReset(false);
        } else {
          setSalaryHistoy([]);
          setFilterReset(false);
        }
      })
      .catch((err) => {
        setSalaryHistoy([]);
        setFilterReset(false);
      });
  };

  const getEmployeeList = () => {
    utils
      .callAPI("get", `/employee/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setEmpList(res.result);
        } else {
          setEmpList([]);
        }
      })
      .catch((err) => {
        setEmpList([]);
      });
  };

  function handleDownload() {
    setCsvData([]);

    utils
      .callAPI(
        "post",
        `/salary/payslip/export-all-employee?month_year=${selectedMonth}-${selectedYear}&employeeId=${selectedEmployee}`
      )
      .then((res) => {
        if (res && Object.keys(res).length > 0) {
          setCsvData(res);
          setActive(true);
        } else {
          setCsvData([]);
        }
      })
      .catch((err) => {
        setCsvData([]);
      });
  }

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  // const months = useMemo(() => {
  //   const options = [];
  //   const currentDate = new Date();
  //   for (let i = 0; i < 12; i++) {
  //     const date = new Date(currentDate);
  //     console.log("Date getting is", date.getFullYear());
  //     date.setMonth(date.getMonth() - i);
  //     //console.log("Date setting is", date.setMonth(date.getMonth() - i));
  //     const monthYear = getFormattedMonthYear(date);
  //     options.push(
  //       <option key={i} value={monthYear}>
  //         {monthYear}
  //       </option>
  //     );
  //   }
  //   return options;
  // }, []);

  const months = useMemo(() => {
    const monthOptions = [
      { value: "January", key: "Jan" },
      { value: "February", key: "Feb" },
      { value: "March", key: "Mar" },
      { value: "April", key: "Apr" },
      { value: "May", key: "May" },
      { value: "June", key: "Jun" },
      { value: "July", key: "Jul" },
      { value: "August", key: "Aug" },
      { value: "September", key: "Sep" },
      { value: "October", key: "Oct" },
      { value: "November", key: "Nov" },
      { value: "December", key: "Dec" },
      { value: "1st Quater", key: "1_quarter" },
      { value: "2nd Quater", key: "2_quarter" },
      { value: "3rd Quater", key: "3_quarter" },
      { value: "1st Half", key: "1_half" },
      { value: "2nd Half", key: "2_half" },
      { value: "Yearly", key: "year" },
    ];

    return monthOptions.map((month, index) => (
      <option key={index} value={month.key}>
        {month.value}
      </option>
    ));
  }, []);

  const currentYear = new Date().getFullYear();
  const years = useMemo(() => {
    const options = [];
    for (let year = currentYear; year >= 2023; year--) {
      options.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }
    return options;
  }, []);

  const handleFilter = () => {
    getSalaryHistoy();
  };

  const handleReset = () => {
    const currentDate = new Date();
    setSelectedMonthYear(getFormattedMonthYear(currentDate));
    setSelectedEmployee("");
    setFilterReset(true);
  };

  useEffect(() => {
    if (filterReset) {
      getSalaryHistoy();
    }
  }, [filterReset]);
  return (
    <div className="section-body">
      <RB.Card className="card">
        {/* <RB.Card.Header className="border-0">
          <h4>list</h4>
          <div className="card-header-action">
            {utils.hasPermission(primaryMenuRes, "User", "user.create") && (
              <RB.Button
                className="btn btn-primary me-2"
                onClick={handleAddClick}
              >
                Add User
              </RB.Button>
            )}
          </div>
        </RB.Card.Header> */}
        <RB.Card.Body>
          <RB.Row className="justify-content-between">
            <RB.Col md="auto">
              <div
                className="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-4 row-cols-xl-5 g-3 justify-content-between align-items-center"
                text-center
              >
                <RB.Col>
                  <RB.Form.Select
                    aria-label="Default select example"
                    className="me-2"
                    value={selectedEmployee}
                    onChange={(e) => setSelectedEmployee(e.target.value)}
                  >
                    <option>Select an employee...</option>
                    {empList?.map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {`${employee.firstName} ${employee.lastName}`}
                      </option>
                    ))}
                  </RB.Form.Select>
                </RB.Col>
                <RB.Col>
                  <RB.Form.Select
                    aria-label="Default select example"
                    className="me-2"
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                  >
                    <option value="">Select a month</option>
                    {months}
                  </RB.Form.Select>
                </RB.Col>
                <RB.Col>
                  <RB.Form.Select
                    aria-label="Default select example"
                    className="me-2"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                  >
                    <option value="">Select a year</option>
                    {years}
                  </RB.Form.Select>
                </RB.Col>
                <RB.Col>
                  <div className="d-flex align-items-center">
                    <RB.Button
                      className="ms-2"
                      variant="primary"
                      onClick={handleFilter}
                    >
                      Find
                    </RB.Button>
                    <RB.Button
                      className="ms-2"
                      variant="secondary"
                      onClick={handleReset}
                    >
                      Reset
                    </RB.Button>
                  </div>
                </RB.Col>
                <RB.Col>
                  <div className="d-xl-flex justify-content-end" text-center>
                    <RB.Button
                      className="ms-2"
                      variant="primary"
                      onClick={() => handleDownload()}
                    >
                      Download
                    </RB.Button>

                    <CSVLink data={csvData} ref={csvLink} target="_blank" />
                  </div>
                </RB.Col>
              </div>
            </RB.Col>
            {/* <RB.Col md="auto">
              {" "}
              <div className=" justify-content-end" text-center>
                <RB.Button
                  className="ms-2"
                  variant="primary"
                  onClick={() => handleDownload()}
                >
                  Download
                </RB.Button>

                <CSVLink data={csvData} ref={csvLink} target="_blank" />
              </div>
            </RB.Col> */}
          </RB.Row>
          <DataTable
            columns={columns}
            data={SalaryHistoy.result}
            highlightOnHover
            responsive
            pagination
            striped
            paginationServer
            paginationTotalRows={SalaryHistoy.total}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            onChangePage={(page) => setPage(page)}
            persistTableHead
            customStyles={{
              head: {
                style: {
                  fontSize: "0.875rem", // Adjust the font size as needed
                  fontWeight: "bold", // Adjust the font weight as needed
                },
              },
            }}
            // subHeader
            // subHeaderComponent={subHeaderComponent}
          />
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default List;
