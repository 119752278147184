import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import { PrimaryMenu } from "./Components/PrimaryMenu";
import { ProtectedRoute } from "./Components/Protected.route";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import PasswordChange from "./Components/UserProfile/PasswordChange";
import UserProfile from "./Components/UserProfile/UserProfile";
import ArtisanLogin from "./Pages/ArtisanLogin";
import ArtisanDelete from "./Pages/ArtisanDelete";
import "./assets/script";

function App() {
  return (
    <>
      <Router basename="/">
        <Routes>
          {PrimaryMenu.map((item, index) => {
            if (!("submenu" in item)) {
              return (
                <Route
                  key={index}
                  exact
                  path={item.path}
                  element={<ProtectedRoute />}
                >
                  <Route exact path={item.path} element={item.comp} />
                </Route>
              );
            } else {
              return (
                <Route
                  path={item.path}
                  key={index}
                  element={<ProtectedRoute />}
                >
                  <Route index={true} element={item.comp} key={index} />
                  {item.submenu.map((subitem, subindex) => {
                    return (
                      <Route
                        exact
                        path={subitem.path}
                        element={subitem.comp}
                        key={subindex}
                      />
                    );
                  })}
                </Route>
              );
            }
          })}
          ;
          <Route path="/" exact element={<Login />} key="login" />
          <Route
            path="/artisan"
            exact
            element={<ArtisanLogin />}
            key="artisanLogin"
          />
          <Route
            path="/artisan/delete"
            exact
            element={<ArtisanDelete />}
            key="artisan-delete"
          />
          <Route
            path="/forgot-password"
            exact
            element={<ForgotPassword />}
            key="forgot-password"
          />
          <Route
            path="/reset-password"
            exact
            element={<ResetPassword />}
            key="reset-password"
          />
          <Route path="/profile" element={<ProtectedRoute />}>
            <Route index={true} element={<UserProfile />} />
          </Route>
          <Route path="/change-password" element={<ProtectedRoute />}>
            <Route index={true} element={<PasswordChange />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
