import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Check, Close, Delete } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import moment from "moment";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Document, Eye } from "iconsax-react";

function List(props) {
  const [travelList, settravelList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [hideAction, setHideAction] = useState(false);
  const location = useLocation();
  const [status, setstatus] = useState("");

  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  console.log(userProfile);

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  const columns = [
    {
      name: (
        <div>
          Request Id <br />
        </div>
      ),
      selector: (row) => row.requestId,
    },
    {
      name: (
        <div>
          Employee Id <br />
        </div>
      ),
      selector: (row) => row.employeeId,
    },
    {
      name: (
        <div>
          Destination <br />
        </div>
      ),
      selector: (row) => row.destination,
    },
    {
      name: (
        <div>
          Departure Date <br />
        </div>
      ),
      selector: (row) => moment(row.departureDate).format("DD-MM-YYYY"),
    },
    {
      name: (
        <div>
          Return Date <br />
        </div>
      ),
      selector: (row) => moment(row.returnDate).format("DD-MM-YYYY"),
    },
    {
      name: (
        <div>
          Expenses Total <br />
        </div>
      ),
      selector: (row) => row.expenses,
    },

    {
      name: <div>Approve By</div>,
      selector: (row) => row.approval_name,
    },
    // {
    //   name: (
    //     <div>
    //       Approval By Hr <br />
    //     </div>
    //   ),
    //   selector: (row) => row.hr_name,
    // },
    // {
    //   name: (
    //     <div>
    //       Manager Approval <br />
    //     </div>
    //   ),
    //   selector: (row) => row.approval_status_manager,
    // },
    {
      name: (
        <div>
          Status <br />
        </div>
      ),
      selector: (row) => row.approvalStatus,
    },
    {
      name: (
        <div>
          Action <br />
        </div>
      ),
      cell: (row) => (
        <>
          {utils.hasPermission(
            primaryMenuRes,
            "Your Travel Request",
            "travel_request.edit"
          ) && (
            <>
              {" "}
              {row.approvalStatus == "Pending" && (
                <RB.Button
                  className="btn-circle"
                  variant="outline-primary"
                  size="sm"
                  onClick={handelEdit(row)}
                >
                  <EditIcon />
                </RB.Button>
              )}
            </>
          )}
          &nbsp;
          {utils.hasPermission(
            primaryMenuRes,
            "Your Travel Request",
            "travel_request.delete"
          ) && (
            <>
              {" "}
              {row.approvalStatus == "Pending" && (
                <RB.Button
                  variant="outline-danger"
                  className="btn-circle me-1"
                  size="sm"
                  onClick={showDelete(row)}
                >
                  <Delete />
                </RB.Button>
              )}
            </>
          )}
          <>
            {row.approvalStatus == "Approved" && (
              <>
                <RB.Button
                  variant="outline-primary"
                  className="btn-circle me-1"
                  size="sm"
                  onClick={handleDocUpload(row)}
                >
                  <Document />
                </RB.Button>
              </>
            )}
            {row?.travelDocuments && row?.travelDocuments?.length !== 0 && (
              <RB.Button
                variant="outline-info"
                className="btn-circle"
                size="sm"
                onClick={handleView(row)}
              >
                <Eye />
              </RB.Button>
            )}
          </>
        </>
      ),
      omit:
        !utils.hasPermission(
          primaryMenuRes,
          "Your Travel Request",
          "travel_request.edit"
        ) &&
        !utils.hasPermission(
          primaryMenuRes,
          "Your Travel Request",
          "travel_request.delete"
        ),
    },
  ];

  useEffect(() => {
    getTravelList();
  }, [page, location.pathname]);

  const getTravelList = () => {
    utils
      .callAPI(
        "get",
        `/travel/listForUser?page=${page}&per_page=${countPerPage}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          settravelList(res);
          setHideAction(res?.result?.some((item) => item.status !== "0"));
        } else {
          settravelList([]);
        }
      })
      .catch((err) => {
        settravelList([]);
      });
  };

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const showDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `/travel/delete?id=${row.id}`, "")
          .then((res) => {
            if (typeof res !== "undefined") {
              getTravelList();
            }
          })
          .catch((err) => {});
      }
    });
  });

  const handleDocUpload = useCallback((row) => async () => {
    const propdata = {
      mode: "travel",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const handleView = useCallback((row) => async () => {
    const propdata = {
      mode: "view",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <div className="section-body">
        <RB.Card className="card shadow ">
          <RB.Card.Header>
            <h4></h4>
            <div className="card-header-action">
              {utils.hasPermission(
                primaryMenuRes,
                "Leave Request",
                "leave_request.create"
              ) && (
                <RB.Button
                  className="btn btn-primary me-2"
                  onClick={handleAddClick}
                >
                  Apply Request
                </RB.Button>
              )}
            </div>
          </RB.Card.Header>
          <RB.Card.Body>
            {utils.hasPermission(
              primaryMenuRes,
              "Leave Request",
              "leave_request.view"
            ) && (
              <DataTable
                columns={columns}
                data={travelList.result}
                highlightOnHover
                responsive
                striped
                pagination
                paginationServer
                paginationTotalRows={travelList.total}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                onChangePage={(page) => setPage(page)}
                persistTableHead
                customStyles={{
                  head: {
                    style: {
                      fontSize: "0.875rem", // Adjust the font size as needed
                      fontWeight: "bold", // Adjust the font weight as needed
                    },
                  },
                }}
                // subHeader
                // subHeaderComponent={subHeaderComponent}
              />
            )}
          </RB.Card.Body>
        </RB.Card>
      </div>
    </>
  );
}

export default List;
