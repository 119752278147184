import React, { useState, useEffect } from "react";
import * as RB from "react-bootstrap";
import avatarImg from "../assets/images/avatar-4.png";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../reducers/userProfileSlice";
import { fetchPrimaryMenu } from "../reducers/primaryMenuSlice";
import {
  ArrowCircleDown,
  Key,
  LogoutCurve,
  Profile,
  SearchZoomOut1,
  Setting2,
} from "iconsax-react";
function Header() {
  const navigate = useNavigate();
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const primaryMenuRes = useSelector((state) => state.primaryMenu.primaryMenu);

  const dispatch = useDispatch();

  const handleLogout = () => {
    sessionStorage.removeItem("access_token");
    navigate("/");
  };
  useEffect(() => {
    if (!userProfile) {
      dispatch(fetchUserProfile());
    }
    if (!primaryMenuRes) {
      dispatch(fetchPrimaryMenu());
    }
  }, []);
  const handleSearchSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <RB.Navbar
        expand="xl"
        className="navbar navbar-expand-lg main-navbar bg-white ps-2 pe-3"
      >
        <form
          className="form-inline d-flex align-items-center"
          onSubmit={handleSearchSubmit}
        >
          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="offcanvas"
            // data-bs-target="#slidebarNaigation"
            // aria-controls="navbarSupportedContent"
            // aria-expanded="false"
            // aria-label="Toggle navigation"

            data-bs-target="#main-sidebar"
            aria-controls="main-sidebar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <ul className="navbar-nav me-3 d-none">
            <li>
              <a
                data-bs-toggle="collapse"
                data-bs-target="#slidebarNaigation"
                className="nav-link nav-link-lg"
              >
                <i className="fas fa-bars"></i>
              </a>
            </li>
            <li>
              <a
                data-bs-toggle="search"
                className="nav-link nav-link-lg d-sm-none"
              >
                <i className="fas fa-search"></i>
              </a>
            </li>
          </ul>
          {/* <h6 className="mb-0 d-none d-lg-block">Time To Build Better</h6> */}
          <div class="search-element ms-0">
            {/* <span class="search-icon">
							<i class="fas fa-search"></i>
						</span> */}
            <input
              class="form-control"
              type="search"
              placeholder="Search"
              aria-label="Search"
              data-width="250"
            />
            <button class="btn" type="submit">
              <SearchZoomOut1 />
            </button>
            <div class="search-backdrop"></div>
            <div class="search-result">
              <div class="search-header">Search Suggestion</div>
              <div class="search-item">
                <a>Vura Suggestion</a>
                <a class="search-close">
                  <i class="fas fa-times"></i>
                </a>
              </div>
              <div class="search-item">
                <a>Vura Suggestion</a>
                <a class="search-close">
                  <i class="fas fa-times"></i>
                </a>
              </div>
              <div class="search-item">
                <a>Vura Suggestion</a>
                <a class="search-close">
                  <i class="fas fa-times"></i>
                </a>
              </div>
              <div class="search-header">Search Result</div>
              <div class="search-item">
                <a>User Attendance</a>
              </div>
              <div class="search-item">
                <a>User Attendance</a>
              </div>
              <div class="search-item">
                <a>User Attendance</a>
              </div>
            </div>
          </div>
        </form>
        {/* <ul className="navbar-nav navbar-right"> */}
        <div className="navbar-right ms-auto d-flex align-items-center user-profile-module">
          <span className="user-profile-name me-2">
            {userProfile?.firstName + " " + userProfile?.lastName}
          </span>
          <span
            className="avatar me-n2 avatar-circle text-dark fw-medium"
            data-initial="AD"
          >
            <img
              src={
                userProfile?.profilePic_path
                  ? userProfile?.profilePic_path
                  : avatarImg
              }
              className="img-fluid"
              alt="user"
            />
          </span>
          <RB.Dropdown>
            <RB.Dropdown.Toggle variant="light">
              <ArrowCircleDown size="20" color="#000000" />
            </RB.Dropdown.Toggle>

            <RB.Dropdown.Menu className="dropdown-menu-end">
              <RB.Dropdown.Item as={Link} to="/profile">
                <Profile />
                <span className="ms-2">Profile</span>
              </RB.Dropdown.Item>
              <RB.Dropdown.Item as={Link} to="/change-password">
                <Setting2 />
                <span className="ms-2">Change Password</span>
              </RB.Dropdown.Item>
              <RB.Dropdown.Item onClick={handleLogout} className="logout-link">
                <LogoutCurve />
                <span className="ms-2">Logout</span>
              </RB.Dropdown.Item>
            </RB.Dropdown.Menu>
          </RB.Dropdown>

          {/* <RB.NavDropdown title="" align="end" className="text-dark">
						<ArrowCircleDown size="32" color="#FF8A65" />
						<RB.NavDropdown.Item
							as={Link}
							to="/profile"
							className="fs-6 d-flex align-items-center justify-content-start">
							<i class="material-icons-outlined">manage_accounts</i>
							<span className="ms-2">Profile</span>
						</RB.NavDropdown.Item>
						<RB.NavDropdown.Item
							as={Link}
							to="/change-password"
							className="fs-6 d-flex align-items-center justify-content-start">
							<span class="material-icons-outlined">lock_reset</span>
							<span className="ms-2">Change Password</span>
						</RB.NavDropdown.Item>
						<RB.NavDropdown.Item
							onClick={handleLogout}
							className="text-danger fs-6 d-flex align-items-center justify-content-start">
							<i className="material-icons-outlined">logout</i>
							<span className="ms-2">Logout</span>
						</RB.NavDropdown.Item>
					</RB.NavDropdown> */}
        </div>
      </RB.Navbar>
      {/* </ul> */}
    </>
  );
}
export default Header;
