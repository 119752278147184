import moment from "moment";
import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as utils from "../../Utils/comman";
// import Select from "react-select";
import CustomDropdown from "./CustomDropdown";

function Add(props) {
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    leaveType: "",
    leaveName: "",
    reason: "",
    applied_date: new Date(),
    year: new Date().getFullYear(),
    leave_day: "",
    leave_type_id: "",
    isActive: "",
  });
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState();
  const statusList = [
    { id: "", name: "Select an option..." },
    { id: "1", name: "Active" },
    { id: "2", name: "Inactive" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name, "valeu", value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDropDownChange = (id, name) => {
    setFormData({
      ...formData,
      leaveType: id,
      leaveName: name,
    });
  };

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      if (editId) {
        bodyFormData.append("id", editId);
      }
      bodyFormData.append("leave_no", "leave/2024/0003");
      bodyFormData.append(
        "applied_date",
        moment(formData.applied_date).format("YYYY-MM-DD")
      );
      bodyFormData.append("year", formData.year);
      bodyFormData.append("leave_day", formData.leave_day);
      bodyFormData.append("leave_type_id", formData.leaveType);

      bodyFormData.append(
        "start_date",
        moment(formData.startDate).format("YYYY-MM-DD")
      );
      bodyFormData.append(
        "end_date",
        moment(formData.endDate).format("YYYY-MM-DD")
      );
      // bodyFormData.append("leave_type", formData.leaveType);
      bodyFormData.append("reason", formData.reason);
      bodyFormData.append("isActive", "1");

      utils
        .callAPI("post", `/leave/leave-request/save`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmit(false);
            setEditId("");
            setFormData({
              startDate: null,
              endDate: null,
              leaveType: "",
              reason: "",
              applied_date: new Date(),
              year: new Date().getFullYear(),
              leave_day: "",
              leave_type_id: "",
              isActive: "",
            });
            const propdata = {
              mode: "list",
            };
            props.onChange(propdata);
          }
          setIsSubmit(false);
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  useEffect(() => {
    getLeaveTypeList();
  }, []);

  const getLeaveTypeList = () => {
    utils
      .callAPI("get", `/leave/leave-type/list `)
      .then((res) => {
        if (typeof res !== "undefined") {
          setLeaveTypeList(res);
          console.log(res);
        } else {
          setLeaveTypeList([]);
        }
      })
      .catch((err) => {
        setLeaveTypeList([]);
      });
  };

  useEffect(() => {
    if (props.rowData && Object.keys(props.rowData).length > 0) {
      setEditId(props.rowData.id);
      setIsEdit(true);
      setFormData({
        startDate: new Date(props.rowData.start_date),
        endDate: new Date(props.rowData.end_date),
        leaveType: props.rowData.leave_type_id,
        reason: props.rowData.reason,
        applied_date: new Date(props.rowData.applied_date),
        year: props.rowData.year,
        leave_day: props.rowData.leave_day,
        isActive: props.rowData.isActive,
      });
    } else {
      setIsEdit(false);
    }
  }, [props.rowData]);

  return (
    <div className="section-body ">
      <RB.Card className="">
        <RB.Card.Header>
          <h4>{isEdit ? "Edit" : "Leave Request Form"}</h4>
          <div className="card-header-action">
            <RB.Button
              className="btn btn-primary me-2"
              onClick={handleBackClick}
            >
              Back
            </RB.Button>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3 mt-4">
                  <RB.FloatingLabel className="floating-label-fancy">
                    <DatePicker
                      selected={formData.applied_date}
                      onChange={(date) =>
                        setFormData({ ...formData, applied_date: date })
                      }
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      placeholderText="Leave Applied Date"
                      minDate={new Date()}
                      required
                      disabled
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel className="floating-label-fancy">
                    <RB.Form.Control
                      type="text"
                      placeholder="Year"
                      name="year"
                      value={formData.year}
                      onChange={handleInputChange}
                      required
                    />
                    <RB.Form.Label>Year</RB.Form.Label>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel className="floating-label-fancy">
                    <RB.Form.Control
                      as="select"
                      name="leave_day"
                      value={formData.leave_day}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Leave Day...</option>
                      <option value="full">Full Day</option>
                      <option value="first_half">First Half</option>
                      <option value="second_half">Second Half</option>
                    </RB.Form.Control>
                    <RB.Form.Label>Leave Day</RB.Form.Label>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              {/* <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Leave Type"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="leaveType"
                      value={formData.leaveType}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Leave Type...</option>
                      {leaveTypeList.map((leaveType) => (
                        <option
                          key={leaveType.id}
                          value={leaveType.id}
                          disabled={leaveType.remain_leave === 0}
                        >
                          <span>{leaveType.leave_name}</span> -{" "}
                          <span style={{ fontSize: "0.8em" }}>
                            {leaveType.remain_leave} Leaves Available
                          </span>
                        </option>
                      ))}
                    </RB.Form.Control>
                  </RB.FloatingLabel>
                </div>
              </RB.Col> */}

              <CustomDropdown
                formData={formData}
                leaveTypeList={leaveTypeList}
                handleInputChange={handleDropDownChange}
              />
            </RB.Row>

            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel className="floating-label-fancy">
                    <DatePicker
                      selected={formData.startDate}
                      onChange={(date) =>
                        setFormData({ ...formData, startDate: date })
                      }
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      placeholderText="Select Start Date"
                      minDate={new Date()}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel className="floating-label-fancy">
                    <DatePicker
                      selected={formData.endDate}
                      onChange={(date) =>
                        setFormData({ ...formData, endDate: date })
                      }
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      placeholderText="Select End Date"
                      minDate={formData.startDate}
                      required
                    />
                    <RB.Form.Control.Feedback type="invalid">
                      Please provide end date.
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Reason"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="textarea"
                      placeholder="Enter Reason"
                      name="reason"
                      value={formData.reason}
                      onChange={handleInputChange}
                      required
                      maxLength={200}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              {/* <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="status"
                    label="Status"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="isActive"
                      value={formData.isActive}
                      onChange={handleInputChange}
                      required
                    >
                      {statusList.map((status) => (
                        <option key={status.id} value={status.id}>
                          {status.name}
                        </option>
                      ))}
                    </RB.Form.Control>
                     <RB.Form.Control.Feedback type="invalid">
                                Please select a status.
                              </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div>
              </RB.Col> */}
            </RB.Row>

            <RB.Row className="justify-content-start mt-3">
              <RB.Col className="col-auto">
                <RB.Button variant="primary" type="submit">
                  Submit
                </RB.Button>
              </RB.Col>
            </RB.Row>
          </RB.Form>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default Add;
