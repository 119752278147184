import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import * as utils from "../../Utils/comman";
import DatePicker from "react-datepicker";
import Multiselect from "multiselect-react-dropdown";
import moment from "moment";
import noImage from "../../assets/images/no_image.jpg";

function Add(props) {
  const status = [
    { id: "", name: "Select an option..." },
    { id: "1", name: "Active" },
    { id: "2", name: "Inactive" },
  ];
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedKit, setSelectedKit] = useState(null);
  const [selectedIt, setSelectedIt] = useState(null);
  const [isEdit, setisEdit] = useState(false);
  const [holidayGroups, setHolidayGroups] = useState([]);
  const [formData, setFormData] = useState({
    employeeId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    roleId: "",
    departmentId: "",
    join_date: "",
    panNo: "",
    reporting_manager: [],
    probation_period: "",
    permanentAdd: "",
    onboard_plan: [],
    training_schedule: "",
    holidayGroup: "",
    welcome_kit: [],
    it_setup: [],
    emp_handbook: "",
    emergency_contact_1: "",
    emergency_contact_2: "",
    emergency_contact_name: "",
    emergency_contact_relationship: "",
    emergency_contact_address: "",
    bank_name: "",
    bank_branch: "",
    bank_account_no: "",
    bank_ifsc_code: "",
    isActive: "",
    training_time: "",
    personalEmail: "",
    dob: null,
    pfUan: "",
    courierAdd: "",
    motherName: "",
    gender: "",
    maritalStatus: "",
    bloodGroup: "",
  });
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [role, setRole] = useState([]);
  const [department, setDepartment] = useState([]);

  const [manager, setManager] = useState([]);
  const [onboardPlan, setOnboardPlan] = useState([
    { id: 1, planName: "Plan 1" },
    { id: 2, planName: "Plan 2" },
    { id: 3, planName: "Plan 3" },
  ]);

  const [welcomeKit, setWelcomeKit] = useState([]);
  const [itSetup, setItSetup] = useState([]);
  const [emergencyContactError1, setEmergencyContactError1] = useState(false);
  const [emergencyContactError2, setEmergencyContactError2] = useState(false);
  const [tempStudentProfilePic, setTempStudentProfile] = useState(noImage);
  const [studentProfilePic, setStudentProfilePic] = useState("");

  useEffect(() => {
    getRoleList();
    getDepartmentList();
    getManagerList();
    // getOnboardPlanList();
    getWelcomeKitList();
    getItSetupList();
  }, []);

  const getRoleList = () => {
    utils
      .callAPI("get", `/role/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setRole(res.result);
        } else {
          setRole([]);
        }
      })
      .catch((err) => {
        setRole([]);
      });
  };

  const getDepartmentList = () => {
    utils
      .callAPI("get", `/department/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setDepartment(res.result);
        } else {
          setDepartment([]);
        }
      })
      .catch((err) => {
        setDepartment([]);
      });
  };

  const getManagerList = () => {
    utils
      .callAPI("get", `/employee/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setManager(res.result);
        } else {
          setManager([]);
        }
      })
      .catch((err) => {
        setManager([]);
      });
  };

  const getOnboardPlanList = () => {};

  const getWelcomeKitList = () => {
    utils
      .callAPI("get", `/employee/welcomekit/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setWelcomeKit([
            // { id: "", roleName: "Select an option..." },
            ...res.result,
          ]);
        } else {
          setWelcomeKit([]);
        }
      })
      .catch((err) => {
        setWelcomeKit([]);
      });
  };

  const getItSetupList = () => {
    utils
      .callAPI("get", `/employee/itsetup/list`, "")
      .then((res) => {
        if (typeof res !== "undefined") {
          setItSetup([
            // { id: "", roleName: "Select an option..." },
            ...res.result,
          ]);
        } else {
          setItSetup([]);
        }
      })
      .catch((err) => {
        setItSetup([]);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let validatedValue = value;

    if (name === "emergency_contact_1") {
      validatedValue = value.replace(/\D/g, "");
      setEmergencyContactError1(validatedValue.length !== 10);
    } else if (name === "emergency_contact_2") {
      validatedValue = value.replace(/\D/g, "");
      setEmergencyContactError2(validatedValue.length !== 10);
    }

    setFormData({
      ...formData,
      [name]: validatedValue,
    });
  };

  const handleStudentUpload = (e) => {
    // const file = e.target.files[0];
    //   const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    var link = document.getElementById("studentProfilePic");
    link.click();
  };

  const handleStudentFileChange = (e) => {
    setTempStudentProfile(URL.createObjectURL(e.target.files[0]));
    setStudentProfilePic(e.target.files[0]);
  };

  const handlePlanMultiSelect = (selectedList, selectedItem) => {
    const planIds = selectedList.map((item) => item.cat).join(",");
    setSelectedPlan(selectedList);
    setFormData({
      ...formData,
      onboard_plan: planIds,
    });
  };

  const handlePlanRemove = (selectedList, removedItem) => {
    const removedId = String(removedItem.cat);

    const updatedOnboardPlan = formData.onboard_plan
      .split(",")
      .filter((id) => id !== removedId);

    setFormData({
      ...formData,
      onboard_plan: updatedOnboardPlan.join(","),
    });
  };

  const handleManagerMultiSelect = (selectedList, selectedItem) => {
    const managerIds = selectedList.map((item) => item.cat).join(",");
    setFormData({
      ...formData,
      reporting_manager: managerIds,
    });
  };

  const handleManagerRemove = (selectedList, removedItem) => {
    const removedId = String(removedItem.cat);

    const updatedReportingManager = formData.reporting_manager
      .split(",")
      .filter((id) => id !== removedId);

    setFormData({
      ...formData,
      reporting_manager: updatedReportingManager.join(","),
    });
  };

  const handleKitMultiSelect = (selectedList, selectedItem) => {
    const kitIds = selectedList.map((item) => item.cat).join(",");
    setSelectedKit(selectedList);
    setFormData({
      ...formData,
      welcome_kit: kitIds,
    });
  };

  const handleKitRemove = (selectedList, removedItem) => {
    const removedId = String(removedItem.cat);

    const updatedOnboardPlan = formData.welcome_kit
      .split(",")
      .filter((id) => id !== removedId);

    setFormData({
      ...formData,
      welcome_kit: updatedOnboardPlan.join(","),
    });
  };

  const handleItMultiSelect = (selectedList, selectedItem) => {
    const itIds = selectedList.map((item) => item.cat).join(",");
    setSelectedIt(selectedList);
    setFormData({
      ...formData,
      it_setup: itIds,
    });
  };

  const handleitRemove = (selectedList, removedItem) => {
    const removedId = String(removedItem.cat);

    const updatedOnboardPlan = formData.it_setup
      .split(",")
      .filter((id) => id !== removedId);
    setFormData({
      ...formData,
      it_setup: updatedOnboardPlan.join(","),
    });
  };

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      if (emergencyContactError1 || emergencyContactError2) {
        return;
      }
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      if (isEdit) {
        bodyFormData.append("id", props.rowData.id);
        bodyFormData.append("role_edit_id", props.rowData.role_edit_id);
      }
      bodyFormData.append("employee_photo", "");
      //bodyFormData.append("employeeId", "");
      bodyFormData.append("firstName", formData.firstName);
      bodyFormData.append("middleName", formData.middleName);
      bodyFormData.append("lastName", formData.lastName);
      bodyFormData.append("email", formData.email);
      bodyFormData.append("roleId", formData.roleId);
      bodyFormData.append("departmentId", formData.departmentId);
      bodyFormData.append(
        "join_date",
        moment(formData.join_date).format("YYYY-MM-DD")
      );
      bodyFormData.append("reporting_manager", formData.reporting_manager);
      bodyFormData.append("location", formData.permanentAdd);
      bodyFormData.append("onboard_plan", formData.onboard_plan);
      bodyFormData.append("training_schedule", formData.training_schedule);
      bodyFormData.append("training_time", formData.training_time);
      bodyFormData.append("welcome_kit", formData.welcome_kit);
      bodyFormData.append("it_setup", formData.it_setup);
      bodyFormData.append("emp_handbook", formData.emp_handbook);
      bodyFormData.append("emergency_contact", formData.emergency_contact_1);
      bodyFormData.append("bank_name", formData.bank_name);
      bodyFormData.append(
        "panNo",
        formData.panNo ? formData.panNo : "XXXPX0000X"
      );
      bodyFormData.append("bank_branch", formData.bank_branch);
      bodyFormData.append("bank_account_no", formData.bank_account_no);
      bodyFormData.append("bank_ifsc_code", formData.bank_ifsc_code);
      bodyFormData.append("isActive", formData.isActive);
      bodyFormData.append(
        "probation_period",
        formData.probation_period + "-month"
      );
      bodyFormData.append("holiday_group", formData.holidayGroup);
      bodyFormData.append("doc_submit", "");
      bodyFormData.append("pf_uan_no", formData.pfUan);
      bodyFormData.append("courierAddress", formData.courierAdd);
      bodyFormData.append("motherName", formData.motherName);
      bodyFormData.append("personal_email", formData.personalEmail);
      bodyFormData.append(
        "birth_date",
        moment(formData.dob).format("YYYY-MM-DD")
      );
      bodyFormData.append("gender", formData.gender);
      bodyFormData.append("maritalStatus", formData.maritalStatus);
      bodyFormData.append("bloodGroup", formData.bloodGroup);
      bodyFormData.append("emergencyContactNo2", formData.emergency_contact_2);
      bodyFormData.append(
        "emergencyContactNo2Name",
        formData.emergency_contact_name
      );
      bodyFormData.append(
        "emergencyRelationship",
        formData.emergency_contact_relationship
      );
      bodyFormData.append(
        "emergencyAddress",
        formData.emergency_contact_address
      );

      utils
        .callAPI("post", `/employee/save`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmit(false);
            setisEdit(false);
            const propdata = {
              mode: "list",
            };
            props.onChange(propdata);
          } else {
            setIsSubmit(false);
          }
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  useEffect(() => {
    if (props.rowData && Object.keys(props.rowData).length > 0) {
      console.log("Edit ke ander toh gaya huin bhai..", props.rowData);
      setisEdit(true);
      const rowData = props.rowData;
      setFormData({
        employeeId: rowData.employeeId || "",
        firstName: rowData.firstName || "",
        middleName: rowData.middleName || "",
        lastName: rowData.lastName || "",
        email: rowData.email || "",
        roleId: rowData.roleId || "",
        departmentId: rowData.departmentId || "",
        join_date: new Date(rowData.join_date) || "",
        reporting_manager: rowData.reporting_manager || "",
        probation_period:
          (rowData.probation_period || "").match(/\d+/)[0] || "",
        holidayGroup: rowData.holiday_group || "",

        location: rowData.location || "",
        onboard_plan: rowData.onboard_plan || "",
        //   .split(",")
        //   .map((id) => ({ cat: id, key: `Plan ${id}` })),
        training_schedule: rowData.training_schedule || "",
        welcome_kit: rowData.welcome_kit || "",
        //   .split(",")
        //   .map((id) => ({ cat: id, key: `Welcome kit ${id}` })),
        it_setup: rowData.it_setup || "",
        //   .split(",")
        //   .map((id) => ({ cat: id, key: `it setup ${id}` })),
        emp_handbook: rowData.emp_handbook || "",
        emergency_contact: rowData.emergency_contact || "",
        bank_name: rowData.bank_name || "",
        panNo: rowData.panNo || "",
        bank_branch: rowData.bank_branch || "",
        bank_account_no: rowData.bank_account_no || "",
        bank_ifsc_code: rowData.bank_ifsc_code || "",
        isActive: rowData.isActive || "",
        training_time: rowData.training_time || "",
        personalEmail: rowData?.personal_email || "",
        dob: new Date(rowData?.birth_date) || "",
        pfUan: rowData?.pf_uan_no,
        permanentAdd: rowData?.location,
        courierAdd: rowData?.courierAddress,
        motherName: rowData?.motherName,
        gender: rowData?.gender,
        maritalStatus: rowData?.maritalStatus,
        bloodGroup: rowData?.bloodGroup,
        emergency_contact_1: rowData?.emergency_contact,
        emergency_contact_2: rowData?.emergencyContactNo2,
        emergency_contact_name: rowData?.emergencyContactNo2Name,
        emergency_contact_relationship: rowData?.emergencyRelationship,
        emergency_contact_address: rowData?.emergencyAddress,
      });
      // setOnboardPlan( rowData.onboard_plan.split(",").map(id => ({ cat: id, key: `Plan ${id}` })))
    } else {
      console.log("Edit ke bahar aagaya huin bhai..");
      setisEdit(false);
    }
  }, [props.rowData]);

  const handleSelectChange = (e) => {
    const value = e.target.value;
    console.log("Selected holiday group:", value);
    setFormData({
      ...formData,
      holidayGroup: value,
    });
  };

  useEffect(() => {
    utils
      .callAPI("get", "/leave/holiday/group-list")
      .then((res) => {
        if (res && res.result && res.result.length > 0) {
          setHolidayGroups(res.result);
        }
      })
      .catch((err) => {
        console.error("Error fetching holiday groups:", err);
      });
  }, []);

  const renderHolidayGroupOptions = () => {
    return holidayGroups.map((group) => (
      <option key={group.id} value={group.id}>
        {group.group_name}
      </option>
    ));
  };

  return (
    <div className="section-body">
      <RB.Card className="card shadow ">
        <RB.Card.Header>
          <h4>{isEdit ? "Edit" : "Add"}</h4>

          <div className="card-header-action">
            <RB.Button
              className="btn btn-outline-dark"
              onClick={handleBackClick}
            >
              Back
            </RB.Button>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
            <h6>Personal Information</h6>
            <hr className="hr-lg mt-1" />

            <RB.Row>
              <RB.Col md={3}>
                <RB.Row>
                  <div className="col-md-10 text-center">
                    <div className="profile-photo rounded mb-3">
                      <img
                        src={tempStudentProfilePic}
                        className="img-fluid rounded"
                        id="img_profile4"
                        alt="profile"
                      />
                    </div>
                    <a
                      className="btn btn-block btn-outline-primary upload-button"
                      id="btn-f-upload"
                      onClick={handleStudentUpload}
                    >
                      Upload Photo
                    </a>
                    <input
                      id="studentProfilePic"
                      type="file"
                      className="form-control d-none"
                      name="studentProfilePic"
                      onChange={handleStudentFileChange}
                    />
                  </div>
                </RB.Row>
              </RB.Col>
              <RB.Col md={9}>
                <RB.Row>
                  {isEdit && (
                    <RB.Col md={6}>
                      {" "}
                      <div className="mb-3">
                        <RB.FloatingLabel
                          label="Employee ID"
                          className="floating-label-fancy"
                        >
                          <RB.Form.Control
                            type="text"
                            placeholder="Enter Employee ID"
                            name="employeeId"
                            value={formData.employeeId}
                            onChange={handleInputChange}
                            required
                            readOnly
                          />
                        </RB.FloatingLabel>
                      </div>
                    </RB.Col>
                  )}
                  <RB.Col md={6}>
                    <div className="mb-3">
                      <RB.FloatingLabel
                        label="First Name"
                        className="floating-label-fancy"
                      >
                        <RB.Form.Control
                          type="text"
                          placeholder="Enter First Name"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleInputChange}
                          required
                          maxLength={32}
                        />
                      </RB.FloatingLabel>
                    </div>
                  </RB.Col>
                  <RB.Col md={6}>
                    <div className="mb-3">
                      <RB.FloatingLabel
                        label="Middle Name"
                        className="floating-label-fancy"
                      >
                        <RB.Form.Control
                          type="text"
                          placeholder="Enter Middle Name"
                          name="middleName"
                          value={formData.middleName}
                          onChange={handleInputChange}
                          maxLength={32}
                        />
                      </RB.FloatingLabel>
                    </div>
                  </RB.Col>
                  <RB.Col md={6}>
                    <div className="mb-3">
                      <RB.FloatingLabel
                        label="Last Name"
                        className="floating-label-fancy"
                      >
                        <RB.Form.Control
                          type="text"
                          placeholder="Enter Last Name"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleInputChange}
                          required
                          maxLength={32}
                        />
                      </RB.FloatingLabel>
                    </div>
                  </RB.Col>
                  <RB.Col md={6}>
                    <div className="mb-3">
                      <RB.FloatingLabel
                        label="Email 1"
                        className="floating-label-fancy"
                      >
                        <RB.Form.Control
                          type="email"
                          placeholder="Enter Email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                      </RB.FloatingLabel>
                    </div>
                  </RB.Col>
                  <RB.Col md={6}>
                    <div className="mb-3">
                      <RB.FloatingLabel
                        label="Personal Email"
                        className="floating-label-fancy"
                      >
                        <RB.Form.Control
                          type="email"
                          placeholder="Enter Email"
                          name="personalEmail"
                          value={formData.personalEmail}
                          onChange={handleInputChange}
                        />
                      </RB.FloatingLabel>
                    </div>
                  </RB.Col>
                  <RB.Col md={6}>
                    <div className="mb-3 mt-3">
                      <RB.FloatingLabel
                        // label="Date Of Birth"
                        className="floating-label-fancy"
                      >
                        <DatePicker
                          selected={formData.dob}
                          onChange={(date) =>
                            setFormData({ ...formData, dob: date })
                          }
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="Select Date of Birth"
                          maxDate={new Date()}
                        />
                      </RB.FloatingLabel>
                    </div>
                  </RB.Col>
                  <RB.Col md={6}>
                    <div className="mb-3">
                      <RB.FloatingLabel
                        label="PF UAN No."
                        className="floating-label-fancy"
                      >
                        <RB.Form.Control
                          type="text"
                          placeholder="Enter PF UAN No."
                          name="pfUan"
                          value={formData.pfUan}
                          onChange={handleInputChange}
                        />
                      </RB.FloatingLabel>
                    </div>
                  </RB.Col>
                  <RB.Col md={6}>
                    <div className="mb-3">
                      <RB.FloatingLabel
                        label="Permanent Address"
                        className="floating-label-fancy"
                      >
                        <RB.Form.Control
                          as="textarea"
                          type="text"
                          placeholder="Enter Location"
                          name="permanentAdd"
                          value={formData.permanentAdd}
                          onChange={handleInputChange}
                        />
                      </RB.FloatingLabel>
                    </div>
                  </RB.Col>
                </RB.Row>
              </RB.Col>
            </RB.Row>
            <RB.Row>
              <RB.Col md={4}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Courier Address (Full)"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="textarea"
                      type="text"
                      placeholder="Enter Courier Address"
                      name="courierAdd"
                      value={formData.courierAdd}
                      onChange={handleInputChange}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={4}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Mother Name"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Mother's Name"
                      name="motherName"
                      value={formData.motherName}
                      onChange={handleInputChange}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={4}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Gender"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Select
                      name="gender"
                      value={formData.gender}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </RB.Form.Select>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={4}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Marital Status"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Select
                      name="maritalStatus"
                      value={formData.maritalStatus}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Marital Status</option>
                      <option value="married">Married</option>
                      <option value="unmarried">Unmarried</option>
                    </RB.Form.Select>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={4}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Blood Group"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Blood Group"
                      name="bloodGroup"
                      value={formData.bloodGroup}
                      onChange={handleInputChange}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={4}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Status"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="isActive"
                      value={formData.isActive}
                      onChange={handleInputChange}
                      required
                    >
                      {status.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                    </RB.Form.Control>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>
            <h6 className="mt-2">Emergency Contact</h6>
            <hr className="hr-lg mt-1" />
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Emergency Contact No. (1)"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Emergency Contact No. (1)"
                      name="emergency_contact_1"
                      value={formData.emergency_contact_1}
                      onChange={handleInputChange}
                      maxLength={10}
                    />
                    {emergencyContactError1 && (
                      <span className="text-danger">
                        Please provide a valid 10-digit emergency contact
                        number.
                      </span>
                    )}
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              {/* Emergency Contact No. (2) */}
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Emergency Contact No. (2)"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Emergency Contact No. (2)"
                      name="emergency_contact_2"
                      value={formData.emergency_contact_2}
                      onChange={handleInputChange}
                      required
                      maxLength={10}
                    />
                    {emergencyContactError2 && (
                      <span className="text-danger">
                        Please provide a valid 10-digit emergency contact
                        number.
                      </span>
                    )}
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Name"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Name"
                      name="emergency_contact_name"
                      value={formData.emergency_contact_name}
                      onChange={handleInputChange}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Name of Relationship"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Relationship"
                      name="emergency_contact_relationship"
                      value={formData.emergency_contact_relationship}
                      onChange={handleInputChange}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={12}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Address"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="textarea"
                      placeholder="Enter Address"
                      name="emergency_contact_address"
                      value={formData.emergency_contact_address}
                      onChange={handleInputChange}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>
            <h6 className="mt-2">On-boarding Information</h6>
            <hr className="hr-lg mt-1" />
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Department"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="departmentId"
                      value={formData.departmentId}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Department</option>
                      {department.map((deptItem) => (
                        <option key={deptItem.id} value={deptItem.id}>
                          {deptItem.department_name}
                        </option>
                      ))}
                    </RB.Form.Control>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3 mt-3">
                  <RB.FloatingLabel
                    // label="Join Date"
                    className="floating-label-fancy"
                  >
                    <DatePicker
                      selected={formData.join_date}
                      onChange={(date) =>
                        setFormData({ ...formData, join_date: date })
                      }
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select Join Date"
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Role"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="roleId"
                      value={formData.roleId}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Role</option>
                      {role.map((roleItem) => (
                        <option key={roleItem.id} value={roleItem.id}>
                          {roleItem.roleName}
                        </option>
                      ))}
                    </RB.Form.Control>
                    <RB.Form.Control.Feedback type="invalid">
                      Please select Role ID.
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3 mt-4">
                  <RB.FloatingLabel
                    // label="Reporting Manager"
                    className="floating-label-fancy"
                  >
                    <Multiselect
                      name="reporting_manager"
                      displayValue="key"
                      id="reporting_manager_multiselect"
                      onKeyPressFn={function noRefCheck() {}}
                      onRemove={handleManagerRemove}
                      onSearch={function noRefCheck() {}}
                      onSelect={handleManagerMultiSelect}
                      options={manager.map((managerItem) => ({
                        cat: managerItem.id,
                        key: `${managerItem.firstName} ${managerItem.lastName}`,
                      }))}
                      selectedValues={manager
                        .filter((managerItem) =>
                          props?.rowData?.reporting_manager
                            .split(",")
                            .includes(managerItem.id.toString())
                        )
                        .map((managerItem) => ({
                          cat: managerItem.id,
                          key: `${managerItem.firstName} ${managerItem.lastName}`,
                        }))}
                      placeholder="Select Reporting Managers.."
                      customCloseIcon={
                        <>
                          <i className="material-icons-outlined md14 ms-1">
                            cancel
                          </i>
                        </>
                      }
                      style={{
                        chips: {
                          background: "#FFDE1B",
                          color: "#000000",
                          borderRadius: "1rem",
                          cursor: "pointer",
                        },
                        multiselectContainer: {
                          zIndex: 10,
                        },
                        searchBox: {
                          border: "none",
                          borderBottom: "1px solid #dee2e6",
                          borderRadius: "0px",
                        },
                      }}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3 mt-4">
                  <Multiselect
                    //  hidePlaceholder
                    required
                    name="onboard_plan"
                    displayValue="key"
                    id="css_custom"
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={handlePlanRemove}
                    onSearch={function noRefCheck() {}}
                    onSelect={handlePlanMultiSelect}
                    options={onboardPlan.map((planItem) => ({
                      cat: planItem.id,
                      key: planItem.planName,
                    }))}
                    selectedValues={onboardPlan
                      .filter((plan) =>
                        props?.rowData?.onboard_plan
                          .split(",")
                          .includes(plan.id.toString())
                      )
                      .map((plan) => ({ cat: plan.id, key: plan.planName }))}
                    placeholder="Select on-board Plans.."
                    customCloseIcon={
                      <>
                        <i class="material-icons-outlined md14 ms-1">cancel</i>
                      </>
                    }
                    style={{
                      chips: {
                        background: "#FFDE1B",
                        color: "#000000",
                        borderRadius: "1rem",
                      },
                      multiselectContainer: {
                        zIndex: 10,
                      },
                      searchBox: {
                        border: "none",
                        borderBottom: "1px solid #dee2e6",
                        borderRadius: "0px",
                      },
                    }}
                  />
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Training Schedule"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="training_schedule"
                      value={formData.training_schedule}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Training Schedule</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </RB.Form.Control>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              {formData.training_schedule === "yes" && (
                <RB.Col md={6}>
                  <div className="mb-3">
                    <RB.FloatingLabel
                      label="Training Time"
                      className="floating-label-fancy"
                    >
                      <RB.Form.Control
                        type="text"
                        name="training_time"
                        placeholder="Enter Last Name"
                        value={formData.training_time}
                        onChange={handleInputChange}
                        required
                      />
                    </RB.FloatingLabel>
                  </div>
                </RB.Col>
              )}
              <RB.Col md={6}>
                <div className="mb-3 mt-2">
                  <Multiselect
                    hidePlaceholder
                    name="welcome_kit"
                    displayValue="key"
                    id="css_custom"
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={handleKitRemove}
                    onSearch={function noRefCheck() {}}
                    onSelect={handleKitMultiSelect}
                    options={welcomeKit.map((kit) => ({
                      cat: kit.id,
                      key: kit.name,
                    }))}
                    selectedValues={
                      isEdit
                        ? welcomeKit
                            .filter((plan) =>
                              props.rowData.welcome_kit
                                .split(",")
                                .includes(plan.id.toString())
                            )
                            .map((plan) => ({ cat: plan.id, key: plan.name }))
                        : ""
                    }
                    placeholder="Select welcome kit.."
                    customCloseIcon={
                      <>
                        <i class="material-icons-outlined md14 ms-1">cancel</i>
                      </>
                    }
                    style={{
                      chips: {
                        background: "#FFDE1B",
                        color: "#000000",
                        borderRadius: "1rem",
                      },
                      multiselectContainer: {
                        zIndex: 5,
                      },
                      searchBox: {
                        border: "none",
                        borderBottom: "1px solid #dee2e6",
                        borderRadius: "0px",
                      },
                    }}
                  />
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3 mt-2">
                  <Multiselect
                    hidePlaceholder
                    name="it_setup"
                    displayValue="key"
                    id="css_custom"
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={handleitRemove}
                    onSearch={function noRefCheck() {}}
                    onSelect={handleItMultiSelect}
                    options={itSetup.map((it) => ({
                      cat: it.id,
                      key: it.name,
                    }))}
                    selectedValues={
                      isEdit
                        ? itSetup
                            .filter((plan) =>
                              props.rowData.it_setup
                                .split(",")
                                .includes(plan.id.toString())
                            )
                            .map((plan) => ({ cat: plan.id, key: plan.name }))
                        : ""
                    }
                    placeholder="Select it setup.."
                    customCloseIcon={
                      <>
                        <i class="material-icons-outlined md14 ms-1">cancel</i>
                      </>
                    }
                    style={{
                      chips: {
                        background: "#FFDE1B",
                        color: "#000000",
                        borderRadius: "1rem",
                      },
                      multiselectContainer: {
                        zIndex: 5,
                      },
                      searchBox: {
                        border: "none",
                        borderBottom: "1px solid #dee2e6",
                        borderRadius: "0px",
                      },
                    }}
                  />
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Employee Handbook"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="emp_handbook"
                      value={formData.emp_handbook}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Employee Handbook</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </RB.Form.Control>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Select Holiday Group"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="holidayGroup"
                      value={formData.holidayGroup}
                      onChange={handleSelectChange}
                      required
                    >
                      <option value="">Select Holiday Group...</option>

                      {renderHolidayGroupOptions()}
                    </RB.Form.Control>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.InputGroup>
                    <RB.FloatingLabel
                      label="Probation Period"
                      className="floating-label-fancy"
                    >
                      <RB.Form.Control
                        type="text"
                        // placeholder="Enter Probation Period"
                        name="probation_period"
                        inputMode="numeric"
                        value={formData.probation_period}
                        onChange={handleInputChange}
                        onInput={(e) => {
                          let value = e.target.value.replace(/[^0-9]/g, "");

                          if (value !== "") {
                            const numericValue = parseInt(value, 10);
                            if (numericValue < 1) {
                              value = "1";
                            } else if (numericValue > 12) {
                              value = "12";
                            } else {
                              value = numericValue.toString();
                            }
                          }
                          e.target.value = value;
                        }}
                        required
                      />
                    </RB.FloatingLabel>
                    <RB.InputGroup.Text
                      id="months"
                      className="border border-top-0 border-end-0"
                    >
                      Month
                    </RB.InputGroup.Text>
                  </RB.InputGroup>
                </div>
              </RB.Col>
            </RB.Row>

            <h6 className="mt-2">Banking Information</h6>
            <hr className="hr-lg mt-1" />
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Bank Name"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Bank Name"
                      name="bank_name"
                      value={formData.bank_name}
                      onChange={handleInputChange}
                      required
                      maxLength={50}
                    />
                    <RB.Form.Control.Feedback type="invalid">
                      Please Enter Bank Name.
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Bank Branch"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Bank Branch"
                      name="bank_branch"
                      value={formData.bank_branch}
                      onChange={handleInputChange}
                      required
                      maxLength={50}
                    />
                    <RB.Form.Control.Feedback type="invalid">
                      Please Enter Bank Branch Name.
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Bank Account No"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Bank Account No"
                      name="bank_account_no"
                      value={formData.bank_account_no}
                      onChange={handleInputChange}
                      required
                    />
                    <RB.Form.Control.Feedback type="invalid">
                      Please Enter Bank Account No.
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Bank IFSC Code"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Bank IFSC Code"
                      name="bank_ifsc_code"
                      value={formData.bank_ifsc_code}
                      onChange={handleInputChange}
                      required
                    />
                    <RB.Form.Control.Feedback type="invalid">
                      Please Enter Bank IFSC Code.
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Pan Card No"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Pan Card"
                      name="panNo"
                      value={formData.panNo}
                      onChange={handleInputChange}
                      required
                      maxLength={10}
                    />
                  </RB.FloatingLabel>
                  <small className="text-muted">
                    Note* : As per the Application norms, Please mention your
                    right PAN Card no. only, if you enter your wrong PAN card
                    no. then it will deducted automatically 20% more amount of
                    your total TDS amount.
                  </small>
                </div>
              </RB.Col>
            </RB.Row>

            <RB.Row className="align-items-center mt-3">
              <RB.Col className="col-auto">
                <RB.Button variant="primary" type="submit">
                  Submit
                </RB.Button>
              </RB.Col>
            </RB.Row>
          </RB.Form>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default Add;
