import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import * as RB from "react-bootstrap";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";

const localizer = momentLocalizer(moment);

// const sampleEvents = [
//   {
//     id: 1,
//     title: "Event 1",
//     start: new Date(2024, 1, 10),
//     end: new Date(2024, 1, 15),
//     color: 'green',
//   },
//   {
//     id: 2,
//     title: "Event 2",
//     start: new Date(2024, 1, 16),
//     end: new Date(2024, 1, 17),
//     color: 'blue',
//   },
//   {
//     id: 3,
//     title: "Event 3",
//     start: new Date(2024, 1, 16),
//     end: new Date(2024, 1, 17),
//     color: 'red',
//   },
// ];
function View(props) {
  const [holidayList, setHolidayList] = useState([]);
  const [holiday, setholiday] = useState([]);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const [holidayGroups, setHolidayGroups] = useState([]);
  const userProfile = useSelector((state) => state.userProfile.userProfile);

  const handleAddClick = () => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  useEffect(() => {
    getHolidays();
  }, [holiday, userProfile?.holiday_group]);
  console.log(userProfile?.holiday_group);
  const holidayId = utils.hasPermission(
    primaryMenuRes,
    "Holiday",
    "holiday.holidayGroup"
  ) ? holiday : userProfile?.holiday_group
  const getHolidays = () => {
    utils
      .callAPI("get", `/leave/holiday/list?group_id=${holidayId}`)
      .then((res) => {
        if (typeof res !== "undefined") {
          const convertedEvents = res.result.map((event, index) => ({
            id: event.id,
            title: event.holiday_name,
            start: new Date(event.start_date),
            end: new Date(event.end_date),
            color: index % 3 === 0 ? "green" : index % 3 === 1 ? "blue" : "red",
          }));
          setHolidayList(convertedEvents);
        } else {
          setHolidayList([]);
        }
      })
      .catch((err) => {
        setHolidayList([]);
      });
  };

  useEffect(() => {
    utils
      .callAPI("get", "/leave/holiday/group-list")
      .then((res) => {
        if (res && res.result && res.result.length > 0) {
          setHolidayGroups(res.result);
        }
      })
      .catch((err) => {
        console.error("Error fetching holiday groups:", err);
      });
  }, []);

  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = event.color;
    const style = {
      backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "white",
      border: "none",
      display: "block",
    };
    return {
      style: style,
    };
  };

  //   const customDayNamesStyle = `
  //     .rbc-row .rbc-header {
  //       background-color: #9675CE;
  //       color: white;
  //     }
  //   `;

  const renderHolidayGroupOptions = () => {
    return holidayGroups.map((group) => (
      <option key={group.id} value={group.id}>
        {group.group_name}
      </option>
    ));
  };

  return (
    <div className="section-body">
      {/* <style>{customDayNamesStyle}</style> */}
      <RB.Card className="">
        <RB.Card.Header>
          <h4>View Holidays</h4>
          {utils.hasPermission(
            primaryMenuRes,
            "Holiday",
            "holiday.holidayGroup"
          ) && (
            <div>
              <RB.Form.Select
                name="holidayGroup"
                value={holiday}
                onChange={(e) => setholiday(e.target.value)}
                required
              >
                <option value="">Select Holiday Group...</option>
                {renderHolidayGroupOptions()}
                <option value="Other">Other</option>
              </RB.Form.Select>
            </div>
          )}
          <div className="card-header-action mt-0 ms-0 ms-md-auto">
            {utils.hasPermission(
              primaryMenuRes,
              "Holiday",
              "holiday.create"
            ) && (
              <RB.Button
                className="btn btn-primary me-md-2"
                onClick={handleAddClick}
              >
                Add Holiday
              </RB.Button>
            )}
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          {utils.hasPermission(primaryMenuRes, "Holiday", "holiday.view") && (
            <div className="custom-calendar">
              <Calendar
                localizer={localizer}
                events={holidayList}
                startAccessor="start"
                endAccessor="end"
                style={{ height: "500px" }}
                views={["month", "week", "day"]}
                //   defaultView="month"
                //   eventPropGetter={eventStyleGetter}
                onSelectEvent={(event) => console.log(event)}
              />
            </div>
          )}
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default View;
