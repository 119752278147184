import moment from "moment";
import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as utils from "../../Utils/comman";

function Add(props) {
  const [formData, setFormData] = useState({
    holidayName: "",
    holidayGroup: "",
    startDate: null,
    endDate: null,
    status: "",
    otherHolidayGroup: "",
  });
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [editId, setEditId] = useState();
  const [holidayGroups, setHolidayGroups] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      const currentYear = new Date().getFullYear();
      const days =
        moment(formData.endDate).diff(formData.startDate, "days") + 1;

      var bodyFormData = new FormData();
      if (editId) {
        bodyFormData.append("id", editId);
      }
      bodyFormData.append("year", currentYear);
      bodyFormData.append("days", days);
      bodyFormData.append(
        "start_date",
        moment(formData.startDate).format("YYYY-MM-DD")
      );
      bodyFormData.append(
        "end_date",
        moment(formData.endDate).format("YYYY-MM-DD")
      );
      bodyFormData.append("holiday_name", formData.holidayName);
      bodyFormData.append("isActive", formData.status);
      if (formData.holidayGroup !== "Other" && formData.holidayGroup !== "") {
        bodyFormData.append("holiday_group", formData.holidayGroup);
      } else {
        bodyFormData.append("holiday_group", formData.otherHolidayGroup);
      }
      utils
        .callAPI("post", `/leave/holiday/save`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmit(false);
            setEditId("");
            setFormData({
              holidayName: "",
              startDate: null,
              endDate: null,
              status: "",
            });
            const propdata = {
              mode: "list",
            };
            props.onChange(propdata);
          }
        })
        .catch((err) => {});
    }
  }, [isSubmit]);

  const handleSelectChange = (e) => {
    const value = e.target.value;
    console.log("Selected holiday group:", value);
    setFormData({
      ...formData,
      holidayGroup: value,
    });
  };

  useEffect(() => {
    utils
      .callAPI("get", "/leave/holiday/group-list")
      .then((res) => {
        if (res && res.result && res.result.length > 0) {
          setHolidayGroups(res.result);
        }
      })
      .catch((err) => {
        console.error("Error fetching holiday groups:", err);
      });
  }, []);

  const renderHolidayGroupOptions = () => {
    return holidayGroups.map((group) => (
      <option key={group.id} value={group.id}>
        {group.group_name}
      </option>
    ));
  };
  return (
    <div className="section-body ">
      <RB.Card className="">
        <RB.Card.Header className="border-0">
          <h4>{isEdit ? "Edit" : "Add"}</h4>
          <div className="card-header-action mt-0 ms-0 ms-md-auto">
            <RB.Button
              className="btn btn-primary me-2 "
              onClick={handleBackClick}
            >
              Back
            </RB.Button>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Row>
            <RB.Col md={6}>
              <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="mb-3">
                  <RB.Form.Label>Select Holiday Group</RB.Form.Label>
                  <RB.Form.Select
                    name="holidayGroup"
                    value={formData.holidayGroup}
                    onChange={handleSelectChange}
                    required
                  >
                    <option value="">Select Holiday Group...</option>
                    {renderHolidayGroupOptions()}
                    <option value="Other">Other</option>
                  </RB.Form.Select>
                  {formData.holidayGroup === "Other" && (
                    <div className="mb-3 mt-3">
                      <RB.FloatingLabel
                        controlId="formHolidayGroup"
                        label="Holiday Group"
                        className="floating-label-fancy"
                      >
                        <RB.Form.Control
                          type="text"
                          placeholder="Enter Holiday Group"
                          name="otherHolidayGroup"
                          value={formData.otherHolidayGroup}
                          onChange={handleInputChange}
                          required
                        />
                      </RB.FloatingLabel>
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formHolidayName"
                    label="Holiday Name"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Holiday Name"
                      name="holidayName"
                      value={formData.holidayName}
                      onChange={handleInputChange}
                      required
                    />
                    <RB.Form.Control.Feedback type="invalid">
                      Please provide holiday name.
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div>

                {/* <div className="mb-3">
									<RB.FloatingLabel
										controlId="formStartDate"
										// label="Start Date"
										className="floating-label-fancy">
										<DatePicker
											selected={formData.startDate}
											onChange={(date) => setFormData({ ...formData, startDate: date })}
											dateFormat="yyyy-MM-dd"
											className="form-control"
											placeholderText="Select Start Date"
											required
										/>
										<RB.Form.Control.Feedback type="invalid">
											Please provide start date.
										</RB.Form.Control.Feedback>
									</RB.FloatingLabel>
								</div> */}
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formStartDate"
                    // label="Start Date"
                    className="floating-label-fancy"
                  >
                    <DatePicker
                      selected={formData.startDate}
                      onChange={(date) =>
                        setFormData({ ...formData, startDate: date })
                      }
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      placeholderText="Select Start Date"
                      minDate={new Date()}
                      required
                    />
                    {/* <RB.Form.Control.Feedback type="invalid">
                      Please provide start date.
                    </RB.Form.Control.Feedback> */}
                  </RB.FloatingLabel>
                </div>

                <div className="mb-2">
                  <RB.FloatingLabel
                    controlId="formEndDate"
                    // label="End Date"
                    className="floating-label-fancy"
                  >
                    <DatePicker
                      selected={formData.endDate}
                      onChange={(date) =>
                        setFormData({ ...formData, endDate: date })
                      }
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      placeholderText="Select End Date"
                      minDate={formData.startDate}
                      required
                    />
                    {/* <RB.Form.Control.Feedback type="invalid">
                      Please provide end date.
                    </RB.Form.Control.Feedback> */}
                  </RB.FloatingLabel>
                </div>

                <div className="mb-2">
                  <RB.FloatingLabel
                    controlId="formStatus"
                    label="Status"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="status"
                      value={formData.status}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Status...</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </RB.Form.Control>
                    {/* <RB.Form.Control.Feedback type="invalid">
                      Please select a status.
                    </RB.Form.Control.Feedback> */}
                  </RB.FloatingLabel>
                </div>

                <RB.Row className="justify-content-start mt-3">
                  <RB.Col className="col-auto">
                    <RB.Button variant="primary" type="submit">
                      Submit
                    </RB.Button>
                  </RB.Col>
                </RB.Row>
              </RB.Form>
            </RB.Col>
          </RB.Row>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default Add;
