import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import {
  AccountBalanceWalletOutlined,
  Delete,
  EventNote,
  NorthEast,
  PendingActions,
  Search,
  SouthWest,
} from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { clockIn, clockOut } from "../../reducers/userAttendanceSlice";
import { fetchUserProfile } from "../../reducers/userProfileSlice";
import DatePicker from "react-datepicker";
import { Add } from "iconsax-react";
import { update } from "lodash";

function List(props) {
  const dispatch = useDispatch();
  const navigte = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [attendace, setAttendace] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [currentTime, setCurrentTime] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startError, setStartError] = useState(false);
  const clockStatus = useSelector((state) => state.clockStatus?.clockStatus);
  const checkTypeRedux = useSelector((state) => state.clockStatus?.checkType);
  // const [clockStatus, setClockStatus] = useState("in");
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const [dateFilter, setDateFilter] = useState(false);
  const [checkType, setCheckType] = useState('local');
  const [showModal, setShowModal] = useState(false);
  const [selectedLog, setSelectedLog] = useState([]);

  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const columns = [
    {
      name: "Attendance Date",
      selector: (row) => moment(row.date).format("DD-MM-YYYY"),
    },
    {
      name: "Working Hours",
      selector: (row) => (
        <>
          <span>{row.working_hours}</span>
        </>
      ),
    },
    {
      name: "Regularization Status",
      selector: (row) => row.update_status,
    },
    {
      name: "Logs",
      center: true,
      selector: (row) => (
        <>
          <RB.OverlayTrigger
            trigger="click"
            rootClose
            key="bottom"
            placement="bottom"
            overlay={
              <RB.Popover id={`popover-positioned-bottom`}>
                <RB.Popover.Body className="p-0 overflow-auto popover-scroll ">
                  <div className="list-group">
                    {row.check_time
                      .sort((a, b) => new Date(a.date) - new Date(b.date))
                      .map((time, index) => {
                        if (index % 2 === 0) {
                          const checkOutIndex = index + 1;
                          const checkOutTime = row.check_time[checkOutIndex];
                          const checkInTime = moment(time.date).format(
                            "YYYY-MM-DD hh:mm A"
                          );
                          const formattedCheckOutTime = checkOutTime
                            ? moment(checkOutTime.date).format(
                                "YYYY-MM-DD hh:mm A"
                              )
                            : "Missing";

                          return (
                            <div
                              key={index}
                              className="list-group-item d-flex align-items-center"
                            >
                              <div className="d-flex align-items-center">
                                <SouthWest />
                                <span>{checkInTime}</span>
                              </div>
                              <div className="mx-2">-</div>
                              <div className="d-flex align-items-center">
                                <NorthEast />
                                <span>{formattedCheckOutTime}</span>
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                  </div>
                </RB.Popover.Body>
              </RB.Popover>
            }
          >
            <RB.Button
              className="btn-circle"
              variant="outline-secondary"
              size="sm"
            >
              <PendingActions />
            </RB.Button>
          </RB.OverlayTrigger>
        </>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          {!row.update_status && (
            <RB.Button variant="primary" onClick={() => handleRegularize(row)}>
              Regularize
            </RB.Button>
          )}
        </>
      ),
    },
    // {
    //   name: "Joinnig Date",
    //   selector: (row) => row.join_date,
    // },
    // {
    //   name: "Status",
    //   selector: (row) => (row.isActive == "1" ? "Active" : "inActive"),
    // },
    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <>
    //       &nbsp;
    //       {utils.hasPermission(
    //         primaryMenuRes,
    //         "Attendance",
    //         "attendance.delete"
    //       ) && (
    //         <RB.Button
    //           className="btn-circle"
    //           variant="outline-danger"
    //           size="sm"
    //           onClick={showDelete(row)}
    //           title="Delete"
    //         >
    //           <Delete />
    //         </RB.Button>
    //       )}
    //     </>
    //   ),
    // },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString("en-US", { hour12: true }));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (userProfile?.check_time === "checkIn") {
      // setClockStatus("out");
      dispatch(clockOut());
    } else {
      // setClockStatus("in");
      dispatch(clockIn());
    }
  }, [userProfile]);

  useEffect(() => {
    getAttendanceList();
  }, [page, id]);

  const getAttendanceList = () => {
    let apiUrl = "/Attendance/list?monthandyear";
    const idToFetch = id || queryParams.get("id");
    if (startDate && endDate) {
      apiUrl += `&sdate=${moment(startDate).format(
        "YYYY-MM-DD"
      )}&edate=${moment(endDate).format("YYYY-MM-DD")}`;
    }
    if (startDate && endDate) {
      apiUrl += `&sdate=${moment(startDate).format(
        "YYYY-MM-DD"
      )}&edate=${moment(endDate).format("YYYY-MM-DD")}`;
    }
    if (idToFetch) {
      apiUrl += `&employeeId=${idToFetch}`;
    }

    utils
      .callAPI("get", apiUrl)
      .then((res) => {
        if (typeof res !== "undefined") {
          setAttendace(res);
          setDateFilter(false);
          // setStartDate(null);
          // setEndDate(null);
          setDateFilter(false);
          // setStartDate(null);
          // setEndDate(null);
        } else {
          setAttendace([]);
          setDateFilter(false);
          setStartDate(null);
          setEndDate(null);
          setDateFilter(false);
          setStartDate(null);
          setEndDate(null);
        }
      })
      .catch((err) => {
        setAttendace([]);
        setDateFilter(false);
        setStartDate(null);
        setEndDate(null);
        setDateFilter(false);
        setStartDate(null);
        setEndDate(null);
      });
  };

  const handleClick = () => {
    const dateTime = new Date().toLocaleString();

    if (clockStatus === "in") {
      dispatch(clockIn(checkType));
      submitAttendance("checkIn", dateTime);
    } else {
      dispatch(clockIn(checkType));
      submitAttendance("checkOut", dateTime);
    }
  };

  const submitAttendance = (status, dateTime) => {
    var bodyFormData = new FormData();
    // bodyFormData.append("userId", userProfile.id);
    bodyFormData.append("status", status);
    bodyFormData.append("check_type", checkType);

    bodyFormData.append("date", moment(dateTime).format("YYYY-MM-DD HH:mm:ss"));

    utils
      .callAPI("post", `/Attendance/add`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          // setClockStatus(status === "checkIn" ? "out" : "in");
          dispatch(clockStatus === "in" ? clockOut() : clockIn());
          if (clockStatus === "out") {
            getAttendanceList();
          }
          dispatch(fetchUserProfile());
        }
      })
      .catch((err) => {});
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setDateFilter(false);
  };

  useEffect(() => {
    if (!startDate && !endDate) {
      getAttendanceList();
    }
  }, [startDate, endDate]);

  const handleFilter = () => {
    setDateFilter(true);
  };

  const handleEndDateChange = (date) => {
    if (!startDate) {
      setStartError(true);
      return;
    }
    setEndDate(date);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    setStartError(false);
  };

  useEffect(() => {
    if (dateFilter) {
      getAttendanceList();
    }
  }, [dateFilter]);

  const [newTimeLog, setNewTimeLog] = useState([]);
  const [currentLogDate, setCurrentLogDate] = useState(null);

  const handleRegularize = (log) => {
    console.log(log);
    setCurrentLogDate(log.date);
    const sortedLogs = log?.check_time?.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    const groupedLogs = [];

    for (let i = 0; i < sortedLogs.length; i++) {
      if (sortedLogs[i].status === "checkIn") {
        const checkInLog = sortedLogs[i];
        const checkOutLog =
          sortedLogs[i + 1] && sortedLogs[i + 1].status === "checkOut"
            ? sortedLogs[i + 1]
            : null;

        groupedLogs.push({
          checkIn: checkInLog,
          checkOut: checkOutLog,
        });

        if (checkOutLog) {
          i++;
        }
      }
    }
    console.log(groupedLogs);
    setSelectedLog(groupedLogs);
    setShowModal(true);
  };

  const transformLogs = (logs) => {
    const transformedData = {
      employeeId: logs[0]?.checkIn?.employeeId,
      date: currentLogDate,
      check_time: [],
    };

    logs.forEach((log) => {
      const { checkIn, checkOut } = log;

      if (checkIn) {
        transformedData.check_time.push({
          id: checkIn.id,
          employeeId: checkIn.employeeId,
          check_type: checkIn.check_type,
          status: checkIn.status,
          date: checkIn.date,
          new_time: checkIn.new_time
            ? moment(checkIn.new_time).format("YYYY-MM-DD HH:mm:ss")
            : null,
        });
      }

      if (checkOut) {
        transformedData.check_time.push({
          id: checkOut.id,
          employeeId: checkOut.employeeId,
          check_type: checkOut.check_type,
          status: checkOut.status,
          date: checkOut.date,
          new_time: checkOut.new_time
            ? moment(checkOut.new_time).format("YYYY-MM-DD HH:mm:ss")
            : null,
        });
      }
    });

    return transformedData;
  };

  const handleSaveRegularize = () => {
    const transformedLogs = transformLogs(newTimeLog);

    utils
      .callAPI("post", `/Attendance/update`, transformedLogs)
      .then((res) => {
        if (typeof res !== "undefined") {
          getAttendanceList();
          setShowModal(false);
          setSelectedLog([]);
        }
      })
      .catch((err) => {});
  };

  const handleAddTime = () => {
    const newLog = {
      checkIn: {
        id: "",
        employeeId: userProfile?.employeeId,
        check_type: checkType,
        status: "checkIn",
        date: currentLogDate
          ? `${moment(currentLogDate).format("YYYY-MM-DD")} 00:00:00`
          : null,
      },
      checkOut: {
        id: "",
        employeeId: userProfile?.employeeId,
        check_type: checkType,
        status: "checkOut",
        date: currentLogDate
          ? `${moment(currentLogDate).format("YYYY-MM-DD")} 00:00:00`
          : null,
      },
    };

    console.log(newLog);
    setSelectedLog([...selectedLog, newLog]);
  };

  const handleEditTime = (index, type, newTime) => {
    const updatedLogs = [...selectedLog];

    if (type === "checkIn") {
      if (!updatedLogs[index].checkIn.id) {
        updatedLogs[index].checkIn.new_time = newTime
          ? moment(newTime).format("YYYY-MM-DD HH:mm:ss")
          : null;
        updatedLogs[index].checkIn.date = newTime
          ? moment(newTime).format("YYYY-MM-DD HH:mm:ss")
          : null;
      } else {
        // updatedLogs[index].checkIn.date = newTime
        //   ? moment(newTime).format("YYYY-MM-DD HH:mm:ss")
        //   : null;
        if (
          newTime &&
          moment(newTime).format("YYYY-MM-DD HH:mm:ss") !==
            updatedLogs[index].checkIn.new_time
        ) {
          updatedLogs[index].checkIn.new_time = moment(newTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        }
      }
    } else if (type === "checkOut") {
      if (!updatedLogs[index].checkOut) {
        const checkInDate = moment(updatedLogs[index].checkIn.date).format(
          "YYYY-MM-DD"
        );
        const newCheckOutDateTime = newTime
          ? `${checkInDate} ${moment(newTime).format("HH:mm:ss")}`
          : null;

        updatedLogs[index].checkOut = {
          id: "",
          employeeId: userProfile?.employeeId,
          check_type: checkType,
          status: "checkOut",
          date: newCheckOutDateTime,
          new_time: newCheckOutDateTime,
        };
      } else {
        if (!updatedLogs[index].checkOut.id) {
          updatedLogs[index].checkOut.new_time = newTime
            ? moment(newTime).format("YYYY-MM-DD HH:mm:ss")
            : null;
          updatedLogs[index].checkOut.date = newTime
            ? moment(newTime).format("YYYY-MM-DD HH:mm:ss")
            : null;
        } else {
          updatedLogs[index].checkOut.new_time = newTime
            ? moment(newTime).format("YYYY-MM-DD HH:mm:ss")
            : null;
        }
      }
    }

    setNewTimeLog(updatedLogs);
    setSelectedLog(updatedLogs);
  };

  return (
    <>
      <RB.Row>
        <RB.Col md={4}>
          <RB.Card className="mb-2">
            <RB.Card.Body>
              <div className="d-flex flex-column flex-xxl-row justify-content-between align-items-center">
                <div>
                  <p className="current-time mb-0">CURRENT TIME</p>
                  <div className="time">
                    <span className="fs-2">
                      {currentTime.split(":").slice(0, 2).join(":")}
                    </span>
                    :
                    <span className="fs-6">
                      {currentTime.split(":").slice(2).join(":")}
                    </span>
                  </div>
                </div>

                {utils.hasPermission(
                  primaryMenuRes,
                  "Attendance",
                  "attendance.create"
                ) && (
                  <div className="text-center">
                    <RB.Button
                      variant={clockStatus === "in" ? "dark" : "danger"}
                      onClick={handleClick}
                    >
                      {clockStatus === "in" ? "Clock-In" : "Clock-Out"}
                    </RB.Button>
                    <div className="travel-clock mt-2">
                      <RB.Form>
                        <RB.Form.Group>
                          <RB.Form.Check
                            inline
                            label="Local"
                            type="radio"
                            id="local-radio"
                            checked={checkType === "local"}
                            onChange={() => setCheckType("local")}
                            disabled={clockStatus === "out"}
                          />
                          <RB.Form.Check
                            inline
                            label="Travel"
                            type="radio"
                            id="travel-radio"
                            checked={checkType === "travel"}
                            onChange={() => setCheckType("travel")}
                            disabled={clockStatus === "out"}
                          />
                        </RB.Form.Group>
                      </RB.Form>
                    </div>
                  </div>
                )}
              </div>
            </RB.Card.Body>
          </RB.Card>
        </RB.Col>
      </RB.Row>

      <div className="section-body">
        <RB.Card className="card shadow ">
          <RB.Card.Header>
            <h4>{id ? "" : ""}</h4>
          </RB.Card.Header>

          <RB.Card.Body>
            <RB.Row>
              <RB.Col md={12} className="mb-3">
                <div className="row g-3 align-items-center" text-center>
                  <div className="col-sm-auto d-flex justify-content-center">
                    <DatePicker
                      selected={startDate}
                      onChange={handleStartDate}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="dd-MM-yyyy"
                      className={`form-control range-datepicker-input ${
                        startError ? "date-border-danger" : ""
                      }`}
                      placeholderText="Select Start Date"
                      maxDate={new Date()}
                      required
                    />
                  </div>
                  <div className="col-12 col-sm-auto text-center">To</div>
                  <div className="col-sm-auto d-flex justify-content-center">
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      maxDate={new Date()}
                      dateFormat="dd-MM-yyyy"
                      className="form-control range-datepicker-input"
                      placeholderText="Select End Date"
                      required
                    />
                  </div>
                  <div className="col-sm-auto d-flex justify-content-center">
                    <RB.Button
                      className="ms-2"
                      variant="primary"
                      onClick={handleFilter}
                      disabled={!startDate || !endDate}
                    >
                      Find
                    </RB.Button>
                    <RB.Button
                      className="ms-2"
                      variant="secondary"
                      onClick={handleReset}
                    >
                      Reset
                    </RB.Button>
                  </div>
                </div>
              </RB.Col>
              <RB.Col md={6}></RB.Col>
            </RB.Row>

            {utils.hasPermission(
              primaryMenuRes,
              "Attendance",
              "attendance.view"
            ) && (
              <DataTable
                columns={columns}
                data={attendace}
                highlightOnHover
                responsive
                striped
                pagination
                paginationServer
                paginationTotalRows={attendace.total}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                onChangePage={(page) => setPage(page)}
                persistTableHead
                customStyles={{
                  head: {
                    style: {
                      fontSize: "0.875rem", // Adjust the font size as needed
                      fontWeight: "bold", // Adjust the font weight as needed
                    },
                  },
                }}
                // subHeader
                // subHeaderComponent={subHeaderComponent}
              />
            )}
          </RB.Card.Body>
        </RB.Card>
        <RB.Modal show={showModal} onHide={() => setShowModal(false)}>
          <RB.Modal.Header closeButton>
            <RB.Modal.Title>
              Regularize Attendance(
              {moment(currentLogDate).format("DD-MM-YYYY")})
            </RB.Modal.Title>
          </RB.Modal.Header>
          <RB.Modal.Body>
            <div className="list-group">
              {selectedLog.map((log, index) => {
                const checkInTime = log.checkIn.date
                  ? moment(log.checkIn.date).format("hh:mm A")
                  : "Set Time";
                const checkOutTime =
                  log.checkOut && log.checkOut.date
                    ? moment(log.checkOut.date).format("hh:mm A")
                    : "Set Time";

                return (
                  <div
                    key={index}
                    className="list-group-item d-flex align-items-center"
                  >
                    <div className="d-flex align-items-center">
                      <SouthWest />
                      <DatePicker
                        selected={
                          log.checkIn.new_time
                            ? new Date(log.checkIn.new_time)
                            : log.checkIn.date
                            ? log.checkIn.date.endsWith("00:00:00")
                              ? null // Render null when date ends with 00:00:00
                              : new Date(log.checkIn.date)
                            : null
                        }
                        onChange={(date) =>
                          handleEditTime(index, "checkIn", date, log)
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={1}
                        dateFormat="hh:mm aa"
                        className="form-control"
                        placeholderText="Set Time"
                      />
                    </div>
                    <div className="mx-2">-</div>
                    <div className="d-flex align-items-center">
                      <NorthEast />
                      <DatePicker
                        selected={
                          log.checkOut && log.checkOut.new_time
                            ? new Date(log.checkOut.new_time)
                            : log.checkOut && log.checkOut.date
                            ? log.checkOut.date.endsWith("00:00:00")
                              ? null
                              : new Date(log.checkOut.date)
                            : null
                        }
                        onChange={(date) =>
                          handleEditTime(index, "checkOut", date, log)
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={1}
                        dateFormat="hh:mm aa"
                        className="form-control"
                        placeholderText="Set Time"
                      />
                    </div>
                    {index === selectedLog.length - 1 && (
                      <RB.Button
                        variant="outline-primary"
                        onClick={handleAddTime}
                        size="sm"
                        className="ms-2"
                      >
                        <Add />
                      </RB.Button>
                    )}
                  </div>
                );
              })}
            </div>
          </RB.Modal.Body>

          <RB.Modal.Footer>
            <RB.Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </RB.Button>
            <RB.Button variant="primary" onClick={handleSaveRegularize}>
              Save Changes
            </RB.Button>
          </RB.Modal.Footer>
        </RB.Modal>
      </div>
    </>
  );
}

export default List;
