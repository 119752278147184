import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Check, Close, Delete } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import moment from "moment";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Layout from "../../Layout";
import { Eye } from "iconsax-react";

function ManagerHrApproval() {
  const [travelList, settravelList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [hideAction, setHideAction] = useState(false);
  const location = useLocation();
  const [status, setstatus] = useState("");
  const [show, setShow] = useState(false);
  const [reason, setReason] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [error, seterror] = useState("");
  const [id, setId] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  console.log(userProfile);
  const [showDoc, setShowDoc] = useState(false);
  const [travelDocuments, settravelDocuments] = useState([]);
  // const handleAddClick = (e) => {
  //   const propdata = {
  //     mode: "add",
  //   };
  //   props.onChange(propdata);
  // };

  const columns = [
    {
      name: (
        <div>
          Request Id <br />
        </div>
      ),
      selector: (row) => row.requestId,
    },
    {
      name: (
        <div>
          Employee Id <br />
        </div>
      ),
      selector: (row) => row.employeeId,
    },
    {
      name: (
        <div>
          Destination <br />
        </div>
      ),
      selector: (row) => row.destination,
    },
    {
      name: (
        <div>
          Departure Date <br />
        </div>
      ),
      selector: (row) => moment(row.departureDate).format("DD-MM-YYYY"),
    },
    {
      name: (
        <div>
          Return Date <br />
        </div>
      ),
      selector: (row) => moment(row.returnDate).format("DD-MM-YYYY"),
    },

    {
      name: (
        <div>
          Expenses Total <br />
        </div>
      ),
      selector: (row) => row.expenses,
    },

    {
      name: <div>Approve By</div>,
      selector: (row) => row.approval_name,
    },
    // {
    //   name: (
    //     <div>
    //       Approval By Hr <br />
    //     </div>
    //   ),
    //   selector: (row) => row.hr_name,
    // },
    // {
    //   name: (
    //     <div>
    //       Manager Approval <br />
    //     </div>
    //   ),
    //   selector: (row) => row.approval_status_manager,
    // },
    {
      name: (
        <div>
          Status <br />
        </div>
      ),
      selector: (row) => row.approvalStatus,
    },
    {
      name: (
        <div>
          Action <br />
        </div>
      ),
      cell: (row) => (
        <>
          {/* {utils.hasPermission(
              primaryMenuRes,
              "Your Travel Request",
              "travel_request.edit"
            ) && (
              <>
                {" "}
                {row.approval_status_manager == "Pending" && (
                  <RB.Button
                    className="btn-circle"
                    variant="outline-primary"
                    size="sm"
                    onClick={handelEdit(row)}
                  >
                    <EditIcon />
                  </RB.Button>
                )}
              </>
            )}
            &nbsp;
            {utils.hasPermission(
              primaryMenuRes,
              "Your Travel Request",
              "travel_request.delete"
            ) && (
              <>
                {" "}
                {row.approval_status_manager == "Pending" && (
                  <RB.Button
                    variant="outline-danger"
                    className="btn-circle"
                    size="sm"
                    onClick={showDelete(row)}
                  >
                    <Delete />
                  </RB.Button>
                )}
              </>
            )} */}
          {utils.hasPermission(
            primaryMenuRes,
            "Other Travel Request",
            "other_travel_request.approve"
          ) && (
            <>
              {row.approvalStatus == "Pending" && (
                <RB.Button
                  variant="outline-success"
                  className="btn-circle me-2"
                  size="sm"
                  onClick={hanldeApprove(row, "Approved")}
                >
                  <Check />
                </RB.Button>
              )}
            </>
          )}
          {utils.hasPermission(
            primaryMenuRes,
            "Other Travel Request",
            "other_travel_request.approve"
          ) && (
            <>
              {" "}
              {row.approvalStatus == "Pending" && (
                <RB.Button
                  variant="outline-danger"
                  className="btn-circle"
                  size="sm"
                  onClick={hanldeApprove(row, "Rejected")}
                >
                  <Close />
                </RB.Button>
              )}
            </>
          )}
          {row?.travelDocuments && row?.travelDocuments?.length !== 0 && (
            <RB.Button
              variant="outline-info"
              className="btn-circle"
              size="sm"
              onClick={handleView(row)}
            >
              <Eye />
            </RB.Button>
          )}
        </>
      ),
      omit:
        !utils.hasPermission(
          primaryMenuRes,
          "Your Travel Request",
          "travel_request.edit"
        ) &&
        !utils.hasPermission(
          primaryMenuRes,
          "Your Travel Request",
          "travel_request.delete"
        ),
    },
  ];

  const handleView = useCallback((row) => async () => {
    console.log(row);
    settravelDocuments(row.travelDocuments);
    setShowDoc(true);
  });

  const handleBackClick = () => {
    settravelDocuments([]);
    setShowDoc(false);
  };
  const hanldeApprove = useCallback((row, st) => async () => {
    setId(row.id);
    setstatus(st);
    if (st == "Approved") {
      setIsSubmit(true);
    } else {
      handleShow();
    }
  });

  const handleReject = (currentSt) => {
    if (!reason) {
      seterror("Please enter reason.");
      return;
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      const bodyFormData = new FormData();
      bodyFormData.append("travelReqId", id);
      bodyFormData.append("status", status);
      bodyFormData.append("reason", reason);
      utils
        .callAPI("post", `/travel/status`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmit(false);
            setReason("");
            getTravelList();
            handleClose();
          }
          setIsSubmit(false);
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  useEffect(() => {
    getTravelList();
  }, [page, location.pathname]);

  const getTravelList = () => {
    const apiUrl =
      location.pathname === "/travel/junior-travel-req"
        ? `/travel/list?page=${page}&per_page=${countPerPage}`
        : `/travel/listForUser?page=${page}&per_page=${countPerPage}`;

    utils
      .callAPI("get", apiUrl)
      .then((res) => {
        if (typeof res !== "undefined") {
          settravelList(res);
          setHideAction(res?.result?.some((item) => item.status !== "0"));
        } else {
          settravelList([]);
        }
      })
      .catch((err) => {
        settravelList([]);
      });
  };

  // const handelEdit = useCallback((row) => async () => {
  //   const propdata = {
  //     mode: "edit",
  //     rowData: row,
  //   };
  //   props.onChange(propdata);
  // });

  const showDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `/leave/leave-request/delete?id=${row.id}`, "")
          .then((res) => {
            if (typeof res !== "undefined") {
              getTravelList();
            }
          })
          .catch((err) => {});
      }
    });
  });

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleDownload = async (url, filename) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Failed to download image:", error);
    }
  };

  return (
    <>
      <Layout>
        <div className="main-content">
          <section className="section">
            <div className="section-header">
              <h1>Travel Request</h1>
            </div>

            <div className="section-body">
              {showDoc ? (
                <>
                  <RB.Card className="card shadow ">
                    <RB.Card.Header>
                      <h4></h4>
                      <div className="card-header-action">
                        <RB.Button
                          className="btn btn-primary me-2"
                          onClick={handleBackClick}
                        >
                          Back
                        </RB.Button>
                      </div>
                    </RB.Card.Header>
                    <RB.Card.Body>
                      <RB.Row className="mt-3 g-3">
                        {travelDocuments?.map((doc, index) => (
                          <RB.Col key={index} className="col-md-6 col-lg-3">
                            {console.log(doc)}
                            <div className="position-relative me-2 mb-4 rounded border p-3 text-center">
                              <div className="travel-doc-upload">
                                <a
                                  href={doc.travelDocument}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-decoration-none text-dark"
                                >
                                  {doc.travelDocument.endsWith(".pdf") ? (
                                    <i className="material-icons-outlined md-64">
                                      picture_as_pdf
                                    </i>
                                  ) : (
                                    <i className="material-icons-outlined md-64">
                                      image
                                    </i>
                                  )}
                                  <div className="text-center">
                                    Travel Bill {index + 1}
                                  </div>
                                </a>
                              </div>
                              <div className="d-flex justify-content-between  position-absolute top-100 start-50 translate-middle">
                                {/* <RB.Button
                                variant="danger"
                                className="btn btn-sm btn-circle me-2"
                                onClick={() =>
                                  handleDeleteDocument(doc.travelDocId)
                                }
                              >
                                <i className="material-icons-outlined">
                                  delete
                                </i>
                              </RB.Button> */}
                                {!doc.travelDocument.endsWith(".pdf") && (
                                  <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() =>
                                      handleDownload(
                                        doc.travelDocument,
                                        `TravelBill${index + 1}.jpg`
                                      )
                                    }
                                  >
                                    <i className="material-icons-outlined">
                                      file_download
                                    </i>
                                  </button>
                                )}
                              </div>
                            </div>
                          </RB.Col>
                        ))}
                      </RB.Row>
                    </RB.Card.Body>
                  </RB.Card>
                </>
              ) : (
                <>
                  <RB.Card className="card shadow ">
                    <RB.Card.Header>
                      <h4></h4>
                      <div className="card-header-action">
                        {/* {utils.hasPermission(
                  primaryMenuRes,
                  "Leave Request",
                  "leave_request.create"
                ) && (
                  <RB.Button
                    className="btn btn-primary me-2"
                    onClick={handleAddClick}
                  >
                    Apply Request
                  </RB.Button>
                )} */}
                      </div>
                    </RB.Card.Header>
                    <RB.Card.Body>
                      {utils.hasPermission(
                        primaryMenuRes,
                        "Leave Request",
                        "leave_request.view"
                      ) && (
                        <DataTable
                          columns={columns}
                          data={travelList.result}
                          highlightOnHover
                          responsive
                          striped
                          pagination
                          paginationServer
                          paginationTotalRows={travelList.total}
                          paginationPerPage={countPerPage}
                          paginationComponentOptions={{
                            noRowsPerPage: true,
                          }}
                          onChangePage={(page) => setPage(page)}
                          persistTableHead
                          customStyles={{
                            head: {
                              style: {
                                fontSize: "0.875rem", // Adjust the font size as needed
                                fontWeight: "bold", // Adjust the font weight as needed
                              },
                            },
                          }}
                          // subHeader
                          // subHeaderComponent={subHeaderComponent}
                        />
                      )}
                    </RB.Card.Body>
                  </RB.Card>
                </>
              )}
            </div>
            <RB.Modal show={show} onHide={handleClose}>
              <RB.Modal.Header closeButton>
                <RB.Modal.Title>Reject Travel Request</RB.Modal.Title>
              </RB.Modal.Header>
              <RB.Modal.Body>
                <textarea
                  className="form-control"
                  rows="3"
                  placeholder="Enter reason"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                ></textarea>
                {error && <div className="text-danger">{error}</div>}
              </RB.Modal.Body>
              <RB.Modal.Footer>
                <RB.Button
                  variant="primary"
                  onClick={() => handleReject("Reject")}
                >
                  Reject
                </RB.Button>
              </RB.Modal.Footer>
            </RB.Modal>
          </section>
        </div>
      </Layout>
    </>
  );
}

export default ManagerHrApproval;
