import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as RB from "react-bootstrap";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import noImage from "../../assets/images/no_image.jpg";
import { Cancel, Verified } from "@mui/icons-material";

function View(props) {
  const [ClientData, setClientData] = useState({});
  const [tempStudentProfilePic, setTempStudentProfile] = useState(
    props.rowData.image ? props.rowData.image : noImage
  );
  const [studentProfilePic, setStudentProfilePic] = useState("");
  const { rowData } = props;

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handleScanClick = useCallback((row) => async () => {
    const propdata = {
      mode: "scan",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const handleTransactionClick = useCallback((row) => async () => {
    const propdata = {
      mode: "transaction",
      rowData: row,
    };
    props.onChange(propdata);
  });

  useEffect(() => {
    getCouponHistoy();
    // getEmployeeList();
  }, [rowData?.id]);

  const getCouponHistoy = () => {
    utils
      .callAPI("get", `Client/list?clientId=${rowData?.id}`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setClientData(res);
        } else {
          setClientData([]);
        }
      })
      .catch((err) => {
        setClientData([]);
      });
  };

  const handleStudentUpload = (e) => {
    // const file = e.target.files[0];
    //   const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    var link = document.getElementById("studentProfilePic");
    link.click();
  };

  const handleStudentFileChange = (e) => {
    setTempStudentProfile(URL.createObjectURL(e.target.files[0]));
    setStudentProfilePic(e.target.files[0]);
  };

  return (
    <div className="section-body">
      <RB.Card className="card shadow ">
        <RB.Card.Header className="border-0">
          <h4 className="d-flex align-items-center">
            {ClientData[0]?.name}
            {ClientData[0]?.bank_verify === "1" ? (
              <i className="material-icons text-success ms-2">verified</i>
            ) : (
              <i className="material-icons text-danger ms-2">cancel</i>
            )}
          </h4>
          <div className="card-header-action">
            <RB.Button className="btn btn-light" onClick={handleBackClick}>
              Back
            </RB.Button>
            <RB.Button
              className="btn btn-outline-dark ms-2"
              onClick={handleScanClick(rowData)}
            >
              Scan Coupon
            </RB.Button>

            <RB.Button
              className="btn btn-outline-dark ms-2"
              onClick={handleTransactionClick(rowData)}
            >
              Transaction Detail
            </RB.Button>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Row>
            <>
              <h6>Personal Information</h6>
              <hr className="hr-lg mt-1" />
            </>
            <RB.Col md={3}>
              <RB.Row>
                <div className="col-md-10 text-center mb-3">
                  <div className="profile-photo rounded mb-3">
                    <img
                      src={tempStudentProfilePic}
                      className="img-fluid rounded"
                      id="img_profile4"
                      alt="profile"
                    />
                  </div>
                </div>
              </RB.Row>
            </RB.Col>
            <RB.Col md={9}>
              <RB.Row className="mb-3">
                {ClientData[0]?.name && (
                  <RB.Col md={4}>
                    <span className="text-uppercase fw-bold small text-body-secondary">
                      Full Name
                    </span>
                    <p className="fw-medium text-sm fw-semibold">
                      {ClientData[0]?.name}
                    </p>
                  </RB.Col>
                )}
                {ClientData[0]?.mobileNo && (
                  <RB.Col md={4}>
                    <span className="text-uppercase fw-bold small text-body-secondary">
                      Mobile No
                    </span>
                    <p className="fw-medium text-sm fw-semibold">
                      {ClientData[0]?.mobileNo}
                    </p>
                  </RB.Col>
                )}

                {ClientData[0]?.dateOfBirth && (
                  <RB.Col md={4}>
                    <span className="text-uppercase fw-bold small text-body-secondary">
                      Date of Birth
                    </span>
                    <p className="fw-medium text-sm fw-semibold">
                      {ClientData[0]?.dateOfBirth}
                    </p>
                  </RB.Col>
                )}

                {ClientData[0]?.gender && (
                  <RB.Col md={4}>
                    <span className="text-uppercase fw-bold small text-body-secondary">
                      Gender
                    </span>
                    <p className="fw-medium text-sm fw-semibold">
                      {ClientData[0]?.gender.charAt(0).toUpperCase() +
                        ClientData[0]?.gender.slice(1)}
                    </p>
                  </RB.Col>
                )}

                {ClientData[0]?.status && (
                  <RB.Col md={4}>
                    <span className="text-uppercase fw-bold small text-body-secondary">
                      Status
                    </span>
                    <p className="fw-medium text-sm fw-semibold">
                      {ClientData[0]?.status}
                    </p>
                  </RB.Col>
                )}
              </RB.Row>
            </RB.Col>
          </RB.Row>

          {ClientData[0]?.city ||
          ClientData[0]?.street ||
          ClientData[0]?.state ||
          ClientData[0]?.pinCode ||
          ClientData[0]?.district ? (
            <>
              <h6>Address Information</h6>
              <hr className="hr-lg mt-1" />
            </>
          ) : null}

          <RB.Row className="mb-3">
            {ClientData[0]?.address && (
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  Flat/House
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {ClientData[0]?.address}
                </p>
              </RB.Col>
            )}

            {ClientData[0]?.street && (
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  Area/Street
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {ClientData[0]?.street}
                </p>
              </RB.Col>
            )}

            {ClientData[0]?.city && (
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  City/Town
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {ClientData[0]?.city}
                </p>
              </RB.Col>
            )}

            {ClientData[0]?.state && (
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  State
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {ClientData[0]?.state}
                </p>
              </RB.Col>
            )}
            {ClientData[0]?.district && (
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  District
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {ClientData[0]?.district}
                </p>
              </RB.Col>
            )}

            {ClientData[0]?.pincode && (
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  Pin Code
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {ClientData[0]?.pincode}
                </p>
              </RB.Col>
            )}

            {ClientData[0]?.country && (
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  Country
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {ClientData[0]?.country}
                </p>
              </RB.Col>
            )}

            {/* Add more personal information fields with conditions as needed */}
          </RB.Row>
          {ClientData[0]?.bankName ||
          ClientData[0]?.accountHolderName ||
          ClientData[0]?.accountNumber ||
          ClientData[0]?.ifscCode ? (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="mt-2">Banking Information</h6>
                {/* {ClientData[0]?.bank_verify === "1" ? (
                  <span className="text-success">Account Verified</span>
                ) : (
                  <span className="text-danger">Account Not Verified</span>
                )} */}
              </div>
              <hr className="hr-lg mt-1" />
            </>
          ) : null}

          <RB.Row className="mb-3">
            {ClientData[0]?.bankName && (
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  Bank Name
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {ClientData[0]?.bankName}
                </p>
              </RB.Col>
            )}
            {ClientData[0]?.accountHolderName && (
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  Account Holder Name
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {ClientData[0]?.accountHolderName}
                </p>
              </RB.Col>
            )}

            {ClientData[0]?.accountNumber && (
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  Account No.
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {ClientData[0]?.accountNumber}
                </p>
              </RB.Col>
            )}

            {ClientData[0]?.ifscCode && (
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  IFSC Code
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {ClientData[0]?.ifscCode}
                </p>
              </RB.Col>
            )}

            {/* Add more personal information fields with conditions as needed */}
          </RB.Row>
          {ClientData[0]?.aadharCardNo ||
          ClientData[0]?.panCardNo ||
          ClientData[0]?.document ? (
            <>
              <h6>Document Information</h6>
              <hr className="hr-lg mt-1" />
              <RB.Row>
                {ClientData[0]?.document ? (
                  <RB.Col md={3}>
                    <RB.Row>
                      <div className="col-md-10 text-center mb-3">
                        <div className="profile-photo rounded mb-3">
                          <img
                            src={ClientData[0]?.document || noImage}
                            className="img-fluid rounded"
                            alt="document"
                          />
                        </div>
                      </div>
                    </RB.Row>
                  </RB.Col>
                ) : (
                  ""
                )}
                <RB.Col md={9}>
                  <RB.Row className="mb-3">
                    {ClientData[0]?.aadharCardNo && (
                      <RB.Col md={4}>
                        <span className="text-uppercase fw-bold small text-body-secondary">
                          Aadhar Card No.
                        </span>
                        <p className="fw-medium text-sm fw-semibold">
                          {ClientData[0]?.aadharCardNo}
                        </p>
                      </RB.Col>
                    )}
                    {ClientData[0]?.panCardNo && (
                      <RB.Col md={4}>
                        <span className="text-uppercase fw-bold small text-body-secondary">
                          Pan Card No.
                        </span>
                        <p className="fw-medium text-sm fw-semibold">
                          {ClientData[0]?.panCardNo}
                        </p>
                      </RB.Col>
                    )}
                    {/* Add more document fields as needed */}
                  </RB.Row>
                </RB.Col>
              </RB.Row>
            </>
          ) : null}

          {/* {ClientData[0]?.aadharCardNo || ClientData[0]?.panCardNo ? (
            <>
              <h6>Documents</h6>
              <hr className="hr-lg mt-1" />
              <div>
                <div
                  //className=" rounded mb-3"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "400px",
                    overflow: "hidden",
                  }}
                >
                  
                  <img
                    src={ClientData[0]?.document}
                    className=" rounded"
                    style={{ width: "300px", height: "200px" }}
                    alt="document"
                  />
                </div>
              </div>
            </>
          ) : null} */}

          {/* {ClientData[0]?.aadharCardNo && (
            <RB.Col md={4}>
              <span className="text-uppercase fw-bold small text-body-secondary">
                Aadhar Card No.
              </span>
              <p className="fw-medium text-sm fw-semibold">
                {ClientData[0]?.aadharCardNo}
              </p>
            </RB.Col>
          )} */}

          {/* {ClientData[0]?.panCardNo && (
            <RB.Col md={4}>
              <span className="text-uppercase fw-bold small text-body-secondary">
                Pan Card No.
              </span>
              <p className="fw-medium text-sm fw-semibold">
                {ClientData[0]?.panCardNo}
              </p>
            </RB.Col>
          )} */}
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default View;
