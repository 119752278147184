import moment from "moment";
import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as utils from "../../Utils/comman";

function Add(props) {
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    destination: null,
    budget: null,
    reason: null,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState();
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  const handleDepartureDateChange = (date) => {
    if (formData.endDate && date > formData.endDate) {
      // If the selected departure date is after the selected return date,
      // set the return date to null to prevent inconsistency
      setFormData({ ...formData, startDate: null });
    } else {
      setFormData({ ...formData, startDate: date });
    }
  };

  const handleReturnDateChange = (date) => {
    if (formData.startDate && date < formData.startDate) {
      // If the selected return date is before the selected departure date,
      // set the departure date to null to prevent inconsistency
      setFormData({ ...formData, endDate: null });
    } else {
      setFormData({ ...formData, endDate: date });
    }
  };

  useEffect(() => {
    if (isSubmit) {
      // Prepare form data
      const bodyFormData = new FormData();
      if (editId) {
        bodyFormData.append("id", editId);
      }
      bodyFormData.append("destination", formData.destination);
      bodyFormData.append("budget", formData.budget);
      bodyFormData.append(
        "departureDate",
        moment(formData.startDate).format("YYYY-MM-DD")
      );
      bodyFormData.append(
        "returnDate",
        moment(formData.endDate).format("YYYY-MM-DD")
      );
      bodyFormData.append("purpose", formData.reason);

      // Call API to save data
      utils
        .callAPI("post", `/travel/request`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmit(false);
            setFormData({
              startDate: null,
              endDate: null,
              destination: null,
              budget: null,
              reason: null,
            });
            const propdata = {
              mode: "list",
            };
            props.onChange(propdata);
          }
          setIsSubmit(false);
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  useEffect(() => {
    if (props.rowData && Object.keys(props.rowData).length > 0) {
      console.log(props.rowData);
      setEditId(props.rowData.id);
      setIsEdit(true);
      setFormData({
        startDate: props.rowData.departureDate,
        endDate: props.rowData.returnDate,
        destination: props.rowData.destination,
        budget: props.rowData.budget,
        reason: props.rowData.reason || "",
      });
    } else {
      setFormData({
        startDate: null,
        endDate: null,
        destination: null,
        budget: null,
        reason: null,
      });
      setIsEdit(false);
    }
  }, [props.rowData]);

  return (
    <div className="section-body ">
      <RB.Card className="">
        <RB.Card.Header>
          <h4>Add</h4>
          <div className="card-header-action">
            <RB.Button
              className="btn btn-primary me-2"
              onClick={handleBackClick}
            >
              Back
            </RB.Button>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel className="floating-label-fancy">
                    <RB.Form.Control
                      type="text"
                      placeholder="Destination"
                      name="destination"
                      value={formData.destination}
                      onChange={handleInputChange}
                      required
                    />
                    <RB.Form.Label>Destination</RB.Form.Label>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel className="floating-label-fancy">
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Budget"
                      name="budget"
                      value={formData.budget}
                      onChange={handleInputChange}
                      required
                    />
                    <RB.Form.Label>Budget</RB.Form.Label>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel className="floating-label-fancy">
                    <DatePicker
                      selected={formData.startDate}
                      onChange={handleDepartureDateChange}
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      placeholderText="Departure Date"
                      minDate={new Date()}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel className="floating-label-fancy">
                    <DatePicker
                      selected={formData.endDate}
                      onChange={handleReturnDateChange}
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      placeholderText="Return Date"
                      minDate={formData.startDate}
                      required
                    />
                    <RB.Form.Control.Feedback type="invalid">
                      Please provide end date.
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Reason"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="textarea"
                      placeholder="Enter Reason"
                      name="reason"
                      value={formData.reason}
                      onChange={handleInputChange}
                      required
                      maxLength={200}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <RB.Row className="justify-content-start mt-3">
              <RB.Col className="col-auto">
                <RB.Button variant="primary" type="submit">
                  Submit
                </RB.Button>
              </RB.Col>
            </RB.Row>
          </RB.Form>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default Add;
