import React from "react";
import * as RB from "react-bootstrap";

function View(props) {
  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const { rowData } = props;

  const calculateAllowanceTotal = (itemsString) => {
    if (!itemsString) return 0;
    const items = JSON.parse(itemsString);
    return items
      .reduce((total, item) => total + parseFloat(item.amt), 0)
      .toFixed(2);
  };

  const calculateDeductionTotal = (itemsString) => {
    if (!itemsString) return 0;
    const items = JSON.parse(itemsString);
    return items
      .reduce((total, item) => total + parseFloat(item.damt), 0)
      .toFixed(2);
  };

  const renderCompensationTable = (salary) => {
    const allowances = JSON.parse(salary.allowance);
    const deductions = JSON.parse(salary.deduction);

    const findAmount = (items, name, type = "amt") => {
      const item = items.find((item) => item.name === name);
      return item ? parseFloat(item[type]) : 0;
    };

    const data = [
      { label: "Basic", value: findAmount(allowances, "Basic Salary") },
      { label: "HRA", value: findAmount(allowances, "HRA") },
      {
        label: "Other Allow.",
        value: findAmount(allowances, "Special Allowances"),
      },
      {
        label: "Actual Variable Amount",
        value: findAmount(allowances, "Actual Variable Amount"),
      },
      {
        label: "Scheme Incentive",
        value: findAmount(allowances, "Scheme Incentive"),
      },
      { label: "Gross Salary", value: parseFloat(salary.gross_salary) },
      { label: "P.F.", value: findAmount(deductions, "PF", "damt") },
      { label: "ESIC", value: findAmount(deductions, "ESIC", "damt") },
      { label: "Prof Tax", value: findAmount(deductions, "Prof Tax", "damt") },
      { label: "Gratuity", value: findAmount(deductions, "Gratuity", "damt") },
      {
        label: "Advance/Loan",
        value: findAmount(deductions, "Advance Loan", "damt"),
      },
      {
        label: "Mediclaim",
        value: findAmount(deductions, "Mediclaim", "damt"),
      },
      { label: "TDS", value: findAmount(deductions, "TDS", "damt") },
      { label: "Net Ded.", value: parseFloat(salary.net_deduction) },
      { label: "Net Salary (Employees)", value: parseFloat(salary.net_salary) },
      { label: "PF (Employer)", value: parseFloat(salary.employee_pf) },
      {
        label: "Total Net Salary (Including Employer PF)",
        value: parseFloat(salary.total_net_salary),
      },
    ];

    return data.map((row, index) => (
      <tr key={index}>
        <td>{row.label}</td>
        <td>{Math.round(row.value)}</td>
        {/* <td>
          {row.label == "TDS"
            ? salary?.tds_month
              ? Math.round(salary?.tds_month)
              : 0
            : Math.round(row.value * 12)}
        </td> */}
      </tr>
    ));
  };

  return (
    <div className="section-body">
      <RB.Card className="card shadow ">
        <RB.Card.Header className="border-0">
          <h4>Salary View</h4>
          <div className="card-header-action">
            <RB.Button
              className="btn btn-outline-dark"
              onClick={handleBackClick}
            >
              Back
            </RB.Button>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <h6>Personal Information</h6>
          <hr className="hr-lg mt-1" />
          <RB.Row className="mb-3">
            {rowData?.firstName && (
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  First Name
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {rowData?.firstName}
                </p>
              </RB.Col>
            )}
            {rowData?.middleName && (
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  Middle Name
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {rowData?.middleName}
                </p>
              </RB.Col>
            )}
            {rowData?.lastName && (
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  Last Name
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {rowData?.lastName}
                </p>
              </RB.Col>
            )}
          </RB.Row>
          <RB.Row className="mb-3">
            {rowData?.employeeId && (
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  Employee id
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {rowData?.employeeId}
                </p>
              </RB.Col>
            )}
            {rowData?.email && (
              <RB.Col md={4}>
                <span className="text-uppercase fw-bold small text-body-secondary">
                  Email
                </span>
                <p className="fw-medium text-sm fw-semibold">
                  {rowData?.email}
                </p>
              </RB.Col>
            )}
            {/* Add more personal information fields with conditions as needed */}
          </RB.Row>
          {rowData?.employee_salary && (
            <>
              <h6>Salary Information</h6>
              <hr className="hr-lg mt-1" />
              {console.log("salary", rowData)}
              <RB.Row>
                <RB.Col md={4}>
                  <span className="text-uppercase fw-bold small text-body-secondary">
                    CTC
                  </span>
                  <p className="fw-medium text-sm fw-semibold">
                    {rowData?.employee_salary?.ctc}
                  </p>
                </RB.Col>
                {/* <RB.Col md={4}>
                    <span className="text-uppercase fw-bold small text-body-secondary">
                      variable Per
                    </span>
                    <p className="fw-medium text-sm fw-semibold">
                      {salary.variable_per}%
                    </p>
                  </RB.Col> */}
                {rowData?.employee_salary?.variable_pay && (
                  <RB.Col md={4}>
                    <span className="text-uppercase fw-bold small text-body-secondary">
                      variable Pay
                    </span>
                    <p className="fw-medium text-sm fw-semibold">
                      {rowData?.employee_salary?.variable_pay}
                    </p>
                  </RB.Col>
                )}
                {rowData?.employee_salary?.total && (
                  <RB.Col md={4}>
                    <span className="text-uppercase fw-bold small text-body-secondary">
                      Total
                    </span>
                    <p className="fw-medium text-sm fw-semibold">
                      {rowData?.employee_salary?.total}
                    </p>
                  </RB.Col>
                )}

                <RB.Col md={4}>
                  <span className="text-uppercase fw-bold small text-body-secondary">
                    ACTUAL DAYS AMOUNT
                  </span>
                  <p className="fw-medium text-sm fw-semibold">
                    {rowData?.employee_salary?.actual_days}
                  </p>
                </RB.Col>
              </RB.Row>

              <RB.Row>
                {rowData?.employee_salary?.present_days && (
                  <RB.Col md={4}>
                    <span className="text-uppercase fw-bold small text-body-secondary">
                      Days
                    </span>
                    <p className="fw-medium text-sm fw-semibold">
                      {rowData?.employee_salary?.present_days}
                    </p>
                  </RB.Col>
                )}
              </RB.Row>
              <h6>Payment Information</h6>
              <hr className="hr-lg mt-1" />
              <RB.Row className="mb-3">
                {rowData?.employee_salary?.payment_frequency !== undefined && (
                  <RB.Col md={4}>
                    <span className="text-uppercase fw-bold small text-body-secondary">
                      Payment Frequency
                    </span>
                    <p className="fw-medium text-sm fw-semibold">
                      {" "}
                      {rowData?.employee_salary?.payment_frequency == "0"
                        ? "Monthly"
                        : "Bi-Monthly"}
                    </p>
                  </RB.Col>
                )}
                {rowData?.employee_salary?.payment_mode !== undefined && (
                  <RB.Col md={4}>
                    <span className="text-uppercase fw-bold small text-body-secondary">
                      Payment Mode
                    </span>
                    <p className="fw-medium text-sm fw-semibold">
                      {rowData?.employee_salary?.payment_mode === "0"
                        ? "Direct Deposit"
                        : "Check"}
                    </p>
                  </RB.Col>
                )}
                {rowData?.employee_salary?.payment_date && (
                  <RB.Col md={4}>
                    <span className="text-uppercase fw-bold small text-body-secondary">
                      Payment Date
                    </span>
                    <p className="fw-medium text-sm fw-semibold">
                      {rowData?.employee_salary?.payment_date}
                    </p>
                  </RB.Col>
                )}
              </RB.Row>
              <RB.Row>
                {rowData?.employee_salary?.deduction_reason && (
                  <RB.Col md={4}>
                    <span className="text-uppercase fw-bold small text-body-secondary">
                      Deduction Reason
                    </span>
                    <p className="fw-medium text-sm fw-semibold">
                      {rowData?.employee_salary?.deduction_reason}
                    </p>
                  </RB.Col>
                )}
              </RB.Row>
              <RB.Row>
                <div className="table-responsive">
                  <RB.Table striped bordered hover variant="light">
                    <thead>
                      <tr>
                        <th scope="col">Compensation Details</th>
                        <th scope="col">Monthly Salary (INR)</th>
                        {/* <th scope="col">Yearly Salary (INR)</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {renderCompensationTable(rowData?.employee_salary)}
                    </tbody>
                  </RB.Table>
                </div>
              </RB.Row>
              <RB.Row className="my-3">
                {" "}
                {rowData?.employee_salary &&
                  rowData?.employee_salary?.gross_salary && (
                    <RB.Col md={6}>
                      <div className="d-flex border rounded-2">
                        <div className="flex-shrink-0 me-3 bg-light d-flex p-3 justify-content-center align-items-center">
                          <i className="material-icons-outlined md-36">
                            account_balance_wallet
                          </i>
                        </div>
                        <div className="flex-grow-1 p-2">
                          <p className="mb-0">Total Net Salary</p>
                          {/* <p className="mb-0">
                            + {Math.round(rowData?.employee_salary.employee_pf)}{" "}
                            PF (Employer)
                          </p> */}
                          <h4 className="fw-bold">
                            {" "}
                            {rowData?.employee_salary?.total_net_salary}
                          </h4>
                        </div>
                      </div>
                    </RB.Col>
                  )}
                {rowData?.employee_salary &&
                  rowData?.employee_salary?.net_salary && (
                    <RB.Col md={6}>
                      <div className="d-flex border border-primary rounded-2">
                        <div className="flex-shrink-0 me-3 bg-primary d-flex p-3 justify-content-center align-items-center text-xl">
                          <i className="material-icons-outlined md-36">
                            payment
                          </i>
                        </div>
                        <div className="flex-grow-1 p-2">
                          <p className="mb-0">Net Salary</p>
                          <h4 className="fw-bold">
                            {" "}
                            {rowData?.employee_salary?.net_salary}
                          </h4>
                        </div>
                      </div>
                    </RB.Col>
                  )}
              </RB.Row>
            </>
          )}{" "}
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default View;
