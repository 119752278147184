import React, { useState, useEffect } from 'react';
import * as RB from 'react-bootstrap';
import * as utils from "../Utils/comman";
import { useNavigate } from 'react-router-dom';

function ResetPassword() {
    const [validated, setValidated] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const navigate = useNavigate();
    let year = new Date().getFullYear();
    const [values, setValues] = useState({
        newPassword: '',
        confirmPassword: '',
    });
    const handleTextChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true)
        }else{
            setIsSubmit(true);
        }
    };
    useEffect(() => {
        if(isSubmit){
            setIsSubmit(false);

            // const bodyFormData = new FormData();
            // bodyFormData.append("email", values.email);
            // bodyFormData.append("password", values.password);
            // utils.callAPI("post", "/authentication/login", bodyFormData).then((res) => {
            //     if (res !== undefined) {
            //         navigate("/dashboard");
            //     }else{
            //     }
            // });
        }
    }, [isSubmit]);
    return (
        <>
            <section className="section">
                <RB.Container mt={5}>
                    <RB.Row className="justify-content-md-center">
                        <RB.Col xs={12} sm={8} md={6} lg={6} xl={4}>
                            <div className="login-brand">
                                <RB.Image src="" className='shadow-light rounded-circle' width={100}></RB.Image>
                            </div>
                            <RB.Card className=''>
                                <RB.CardHeader>
                                    <h4>Reset Password</h4>
                                </RB.CardHeader>
                                <RB.CardBody>
                                    <RB.Form onSubmit={handleSubmit} noValidate validated={validated}>
                                        <RB.FormGroup className='mb-3'>
                                            <div className='d-block'>
                                                <RB.Form.Label>New password</RB.Form.Label>
                                            </div>
                                            <RB.Form.Control type="password" id="newPassword" name="newPassword" placeholder='New password'onChange={handleTextChange} required />
                                            <RB.Form.Control.Feedback type="invalid">
                                                New password is required.
                                            </RB.Form.Control.Feedback>
                                        </RB.FormGroup>
                                        <RB.FormGroup className='mb-3'>
                                            <div className='d-block'>
                                                <RB.Form.Label>Confirm password</RB.Form.Label>
                                            </div>
                                            <RB.Form.Control type="password" id="confirmPassword" name="confirmPassword" placeholder='Confirm password' onChange={handleTextChange} required />
                                            <RB.Form.Control.Feedback type="invalid">
                                                Confirm password is required.
                                            </RB.Form.Control.Feedback>
                                        </RB.FormGroup>
                                        <div className='d-grid gap-2'>
                                            <RB.Button type='submit' variant="primary">Reset Password</RB.Button>
                                        </div>
                                    </RB.Form>
                                </RB.CardBody>
                            </RB.Card>
                            <div className='simple-footer'>Copyright &copy; {process.env.REACT_APP_TITLE} {year}</div>
                        </RB.Col>
                    </RB.Row>
                </RB.Container>
            </section>
        </>
    );
}

export default ResetPassword