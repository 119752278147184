import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../Layout";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";

function Role() {
  const [roleName, setRoleName] = useState("");
  const [status, setStatus] = useState("");
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const statusList = [
    { id: "", name: "Select an option..." },
    { id: "1", name: "Active" },
    { id: "2", name: "Inactive" },
  ];

  const columns = [
    {
      name: "Role",
      selector: (row) => row.role,
    },
    {
      name: "Status",
      selector: (row) => (row.status == "1" ? "Active" : "inActive"),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <RB.Button
            variant="outline-primary"
            size="sm"
            onClick={handelEdit(row)}
          >
            <EditIcon />
          </RB.Button>{" "}
          &nbsp;
          <RB.Button
            variant="outline-danger"
            size="sm"
            onClick={showDelete(row)}
          >
            <Delete />
          </RB.Button>
        </>
      ),
    },
  ];

  const data = [
    {
      id: 1,
      role: "admin",
      status: "1",
    },
    {
      id: 2,
      role: "Editor",
      status: "0",
    },
  ];
  const handelEdit = useCallback((row) => async () => {
    setRoleName(row.role);
    setStatus(row.status === "1" ? "active" : "inactive");
  });

  const showDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  });

  const handleRoleNameChange = (event) => {
    setRoleName(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      setRoleName("");
      setStatus("");
      setIsSubmit(false);
    }
  }, [isSubmit]);

  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Role</h1>
          </div>
          <div className="section-body">
            <RB.Card className="">
              <RB.Card.Body>
                <RB.Row>
                  <RB.Col md={4}>
                    <RB.Card border="primary" className="shadow-1">
                      <RB.Card.Body>
                        <RB.Form
                          noValidate
                          validated={validated}
                          onSubmit={handleSubmit}
                        >
                          <div className="mb-3">
                            <RB.FloatingLabel
                              controlId="roleName"
                              label="Role Name"
                              className="floating-label-fancy"
                            >
                              <RB.Form.Control
                                type="text"
                                placeholder="Enter Role Name"
                                value={roleName}
                                onChange={handleRoleNameChange}
                                required
                              />
                              <RB.Form.Control.Feedback type="invalid">
                                Please enter a role name.
                              </RB.Form.Control.Feedback>
                            </RB.FloatingLabel>
                          </div>

                          <div className="mb-3">
                            <RB.FloatingLabel
                              controlId="status"
                              label="Status"
                              className="floating-label-fancy"
                            >
                              <RB.Form.Control
                                as="select"
                                value={status}
                                onChange={handleStatusChange}
                                required
                              >
                                {statusList.map((role) => (
                                  <option key={role.id} value={role.id}>
                                    {role.name}
                                  </option>
                                ))}
                              </RB.Form.Control>
                              <RB.Form.Control.Feedback type="invalid">
                                Please select a status.
                              </RB.Form.Control.Feedback>
                            </RB.FloatingLabel>
                          </div>

                          <div className="text-center mt-3">
                            <RB.Button type="submit" variant="primary">
                              Submit
                            </RB.Button>
                          </div>
                        </RB.Form>
                      </RB.Card.Body>
                    </RB.Card>
                  </RB.Col>
                  <RB.Col md={8}>
                    <RB.Card border="primary" className="shadow-1">
                      <RB.Card.Body>
                        <DataTable
                          columns={columns}
                          data={data}
                          highlightOnHover
                          responsive
                          pagination
                          striped
                          paginationServer
                          // paginationTotalRows={emp.total}
                          paginationPerPage={countPerPage}
                          paginationComponentOptions={{
                            noRowsPerPage: true,
                          }}
                          onChangePage={(page) => setPage(page)}
                          persistTableHead
                          customStyles={{
                            head: {
                              style: {
                                fontSize: "0.875rem", // Adjust the font size as needed
                                fontWeight: "bold", // Adjust the font weight as needed
                              },
                            },
                          }}
                          //   subHeader
                          // subHeaderComponent={subHeaderComponent}
                        />
                      </RB.Card.Body>
                    </RB.Card>
                  </RB.Col>
                </RB.Row>
              </RB.Card.Body>
            </RB.Card>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Role;
