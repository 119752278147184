import { configureStore } from "@reduxjs/toolkit";
import userProfile from "./reducers/userProfileSlice";
import empSalaryDataSlice from "./reducers/employeeSalarySlice";
import primaryMenuItems from "./reducers/primaryMenuSlice";
import clockStatus from "./reducers/userAttendanceSlice";
import empTDSDataSlice from "./reducers/employeeTDSSlice";

export const store = configureStore({
  reducer: {
    userProfile: userProfile,
    empSalary: empSalaryDataSlice,
    primaryMenu: primaryMenuItems,
    clockStatus: clockStatus,
    empTDS: empTDSDataSlice,
  },
  //  middleware: getDefaultMiddleware =>
  // getDefaultMiddleware({
  //   serializableCheck: false,
  // }),
});
